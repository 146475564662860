import { useState, useEffect } from 'react';
import { Layout, Row, Col, Badge, Avatar } from 'antd';
import { SideBar } from '../Shared/SideBar'
import { NavBar } from '../Shared/NavBar'
import { CloseOutlined } from '@ant-design/icons';
import { upfloor, ChangeMarkersMap, getMap, getListFloorMarkersAsset, updateCoordinatesByAssetId, setOverlayFindMap } from './MapOpenLayers'
import { useAssetDispatch } from '../../hooks/asset';
import { useAssetTypeDispatch } from '../../hooks/assetType';
import { useDeviceTypeDispatch } from '../../hooks/deviceType';
import { useLoginState } from '../../hooks/login';
import { useCustomerState } from '../../hooks/customer';
import * as datasets from "../../config/datasets";
import { SERVER } from '../../config/serverConfig';
import { getRequest, } from '../../config/httpClient';
import { useCampusDispatch } from '../../hooks/campus';
import socketIOClient from 'socket.io-client';
import { ListAssets } from './ListAssets/ListAssets';
import { MapAssets } from './MapAssets';
import { useAddressDispatch, useAddressState } from '../../hooks/address';
import { createImportSpecifier } from 'typescript';
import { AssetHistory } from './ListAssets/AssetHistory';
const { Content } = Layout;
export const MapView = () => {
  const [nfloor, setNFloor] = useState('')
  const [floorMenu, setFloorMenu] = useState<any>({ floors: [] })
  const [buildingMenu, setBuildingMenu] = useState<any>({ buildings: [] })
  const [showBuildingMenu, setShowBuildingMenu] = useState(false)
  const [showCampusMenu, setShowCampusMenu] = useState(false);
  const [showModalHistory, setShowModalHistory] = useState(false);
  const [currentAssetId, setCurrentAssetId] = useState(0);
  const address = useAddressState();

  const [markerPopup, setMarkerPopup] = useState<any>({
    values_: {
      devices: [{
        name: '',
        last_update: '',
        devices: [{ area: '', floor_name: '', building_name: '' }],
        area_name: '',
        floor_name: '',
        building_name: '',
        asset_type_name: '',
        longitude: '',
        latitude: '',
        icon: '',
        area: '',
        devicetype: []
      }]
    }
  })
  //const [devTypeSelected, setDevTypeSelected] = useState('')
  // const [intervalMarkersL, setIntervalMarkersL] = useState<any>()

  const { getAssetTypesArray } = useAssetTypeDispatch();
  const { getAssetsMapArray, updateAssetFromList } = useAssetDispatch();
  const { listDeviceTypes } = useDeviceTypeDispatch();
  const { updateAddressById } = useAddressDispatch();
  //const [imageBuilding, setImageBuilding] = useState('');
  const customers = useCustomerState();
  const { searchCampus } = useCampusDispatch();
  //const [eventData, setEventData] = useState<any>(null);

  const data = useLoginState();
  const userProfile = data.userProfile;
  useEffect(() => {
    listDeviceTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userProfile && userProfile.email !== '') {
      retrieveData();
      retrieveDataCampus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers.customerSelected]);

  useEffect(() => {
    let socket: any;
    if (userProfile) {
      if (userProfile.email !== '') {
        retrieveData();
        retrieveDataCampus();
      }
      (async () => {
        const token: string = datasets.getToken()!;
        const eventDataTemp = await getRequest(SERVER.GET_EVENTS(), token).then(res => {
          //setEventData(res);
          return res;
        });
        socket = socketIOClient(SERVER.SOCKET());
        socket.on('update-data', async (data: any) => {
          //console.log('update data socket', data);
          for (const prop in data) {
            data[prop] = data[prop].toString();
          }
          const newData = {
            ...data,
            last_update: new Date(+data.eventDate).toLocaleString("en-US", { timeZone: "America/New_York" }),
            coordinates: [+data.lat, +data.lng],
            event: [eventDataTemp ? eventDataTemp.find((event: any) => event.eventType === data.eventType) : { eventType: 0, eventName: '' }],
          };
          //console.log('update data new', newData);

          updateCoordinatesByAssetId(data.asset_id, newData);
          updateAssetFromList(newData);
          const indexAddress = address.listAddress.findIndex((element: any) => element.asset_id === data.asset_id);
          if (indexAddress !== -1) {
            updateAddressById({ asset_id: data.asset_id, device_id: data.device_id });
          }
        })

      })();
    }
    return () => {
      if (socket) {
        socket.disconnect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);


  const retrieveDataCampus = async () => {
    if (userProfile && userProfile.is_admin) {
      await searchCampus({ customer_id: customers.customerSelected._id ? customers.customerSelected._id : '' });
    } else {
      if (!customers.customerSelected._id) {
        await searchCampus({ customer_id: '123431242134' });
      } else {
        await searchCampus({ customer_id: customers.customerSelected._id });
      }
    }
  }
  const retrieveData = (name: any = []) => {
    if (userProfile && userProfile.is_admin) {
      getAssetsMapArray({
        name: name,
        customer_id: customers.customerSelected._id ? customers.customerSelected._id : ''
      })
      getAssetTypesArray({
        name: name,
        customer_id: customers.customerSelected._id ? customers.customerSelected._id : ''
      })
    } else {
      if (!customers.customerSelected._id) {
        getAssetsMapArray({
          name: name, customer_id: '123413243124321432141234'
        })
        getAssetTypesArray({
          name: name, customer_id: '123413243124321432141234'
        })
      } else {
        getAssetsMapArray({ name: name, customer_id: customers.customerSelected._id })
        getAssetTypesArray({ name: name, customer_id: customers.customerSelected._id })

      }
    }
  }

  const showLocationAsset = (asset: any) => {


    const indexAddress = address.listAddress.findIndex((item: any) => (
      item.asset_id === asset.asset_id && ((item.device_esn && item.device_esn === asset.device_esn) || (item.device_imei && item.device_imei === asset.device_imei))
    ));
    if (indexAddress !== -1) {
      return address.listAddress[indexAddress].geoAddress;
    }
    return "";
    /* NO DELETE 
    let label = '';
    if (asset.campus_name) {
      label = asset.campus_name;
    }
    if (asset.building_name) {
      if (label === '') {
        label += asset.building_name;
      } else {
        label += ', ' + asset.building_name;
      }
    }
    if (asset.floor_name) {
      if (label === '') {
        label += asset.floor_name;
      } else {
        label += ', ' + asset.floor_name;
      }
    }
    if (asset.area_name) {
      if (label === '') {
        label += asset.area_name;
      } else {
        label += ', ' + asset.area_name;
      }
    }
    return label;*/
  }

  /*const menuDevType = (
    <Menu>
      {deviceTypes.listDeviceTypes.map((type: any) => <Menu.Item key={type._id} onClick={(x: any) => {
        getAssetMap({ device_type_id: x.key })
        //setDevTypeSelected(type.name)
      }}>
        {type.name}
      </Menu.Item>)}
    </Menu>
  );*/

  const setCenterMap = (asset: any) => {
    console.log('set center map', asset)
    const mapx = getMap()
    const listFloorMarkersAsset = getListFloorMarkersAsset()
    const view = mapx.getView()

    var feature: any = listFloorMarkersAsset[0].getFeatureById(asset.id);
    if (feature) {
      var coordinates = feature.getGeometry().getCoordinates();
      const featureId = feature.id_;
      setOverlayFindMap(featureId, coordinates);
      view.centerOn(feature.getGeometry().getCoordinates(), mapx.getSize(), [mapx.getSize()[0] / 2, mapx.getSize()[1] / 2]);
    }
    onSelectMarker(feature)
  };


  const onSelectMarker = (feat: any) => {
    console.log('feat select Marker', feat);
    setMarkerPopup(feat);
  }

  const onShowHistory = (id: any) => {
    console.log('onshowhistory', id);
    setCurrentAssetId(id);
    //setAssetSelected(id);
    setShowModalHistory(true);
  };

  /*const options = campuslist.listCampus.map((campus: any) => {
    return {
      value: campus.name
    }
  });*/

  return (
    <>
      <AssetHistory
        assetId={currentAssetId}
        showModalHistory={showModalHistory}
        setShowModalHistory={setShowModalHistory}
        showLocationAsset={showLocationAsset}
      ></AssetHistory>
      <Layout style={{ height: '100vh' }}>
        <SideBar></SideBar>
        <Layout>
          <NavBar></NavBar>
          <Layout className="site-layout" >
            <Content>
              <div className="campus-sumary" hidden={!showBuildingMenu}>
                <div className="sumary">
                  <div className="sumary-header">
                    <h5>{floorMenu.campus_name}<CloseOutlined style={{ float: 'right' }} onClick={() => { setShowBuildingMenu(!showBuildingMenu) }} /></h5>
                  </div>
                  <div className="sumary-card-head pd-15">
                    <h5><img src="/Images/building-icon.svg" alt="" />{floorMenu.building_name}</h5>
                  </div>
                  <div className="sumary-body">
                    {floorMenu.floors.map((floor: any, index: number) => {
                      return (
                        <div className="sumary-card" onClick={() => { setNFloor((index + 1).toString()); upfloor(index + 1); ChangeMarkersMap(index + 1) }}>
                          <div className="sumary-card-head">
                            <h5><img src="/Images/floor-icon.svg" alt="" />{floor.floor_plan_name}</h5>
                          </div>

                          {floor.assets.map((asset: any) => {
                            return (
                              <span className="avatar-item">
                                <Badge count={asset.count}>
                                  <Avatar shape="circle" icon={<img src={"/Images/assetIcons/" + asset.icon} alt="" />} />
                                </Badge>
                              </span>)
                          })}
                        </div>)
                    })}
                  </div>
                </div>
              </div>
              <div className="campus-sumary" hidden={!showCampusMenu}>
                <div className="sumary">
                  <div className="sumary-header">
                    <h5>{buildingMenu.campus_name}<CloseOutlined style={{ float: 'right' }} onClick={() => { setShowCampusMenu(!showCampusMenu) }} /></h5>
                  </div>
                  <div className="sumary-card-head pd-15">
                    <h5><img src="/Images/building-icon.svg" alt="" />Buildings</h5>
                  </div>
                  <div className="sumary-body">
                    {buildingMenu.buildings.map((building: any) => {
                      return (
                        <div className="sumary-card">
                          <div className="sumary-card-head">
                            <h5><img src="/Images/building-icon.svg" alt="" />{building.building_name}</h5>
                          </div>

                          {building.assets.map((asset: any) => {
                            return (
                              <span className="avatar-item">
                                <Badge count={asset.count}>
                                  <Avatar shape="circle" icon={<img src={"/Images/assetIcons/" + asset.icon} alt="" />} />
                                </Badge>
                              </span>)
                          })}
                        </div>)
                    })}
                  </div>
                </div>
              </div>
              <Row gutter={[16, 0]}>
                <Col xs={24} lg={19}>
                  <MapAssets
                    markerPopup={markerPopup}
                    showLocationAsset={showLocationAsset}
                    onSelectMarker={onSelectMarker}
                    retrieveData={retrieveData}
                    setShowCampusMenu={setShowCampusMenu}
                    setBuildingMenu={setBuildingMenu}
                    setNFloor={setNFloor}
                    nfloor={nfloor}
                    setShowBuildingMenu={setShowBuildingMenu}
                    showBuildingMenu={showBuildingMenu}
                    setFloorMenu={setFloorMenu}
                  ></MapAssets>
                </Col>
                <Col xs={24} lg={5} className="filters-map">
                  <ListAssets
                    retrieveData={retrieveData}
                    showLocationAsset={showLocationAsset}
                    setCenterMap={setCenterMap}
                    onShowHistory={onShowHistory}
                  ></ListAssets>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
}
