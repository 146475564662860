import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Checkbox } from "antd";
import { useLoginDispatch, useLoginState } from '../../hooks/login';
import { getRequest, postRequest } from '../../config/httpClient';
import { Redirect } from 'react-router';
import { SERVER } from '../../config/serverConfig';
import * as datasets from "../../config/datasets"
// import jmap from 'jmap.js';

import 'antd/dist/antd.css';

export const Login = ({saveUserInformation}: {saveUserInformation: Function}) => {

  const [user, setUser] = useState(null)
  const [message, setMessage] = useState({ message: '', color: '#28C499' });
  const [redirect, setRedirect] = useState(false);
  const { UserLogged, setUserInf } = useLoginDispatch();
  const data = useLoginState();


  useEffect(() => {
    localStorage.clear()
    setRedirect(false)
    setUser(null)
  }, [])



  const onFinish = async (values: any) => {

    const userRequired = { username: values.username, password: values.password }
    await postRequest(SERVER.POST_LOGIN(), userRequired).then(async res => {
      if (res?.token) {
        console.log('resp Server', res)
        const auxMessage = { ...message };
        auxMessage.message = 'Successful Connection';
        auxMessage.color = 'green ';
        setMessage(auxMessage);
        localStorage.setItem('token', res.token);
        await datasets.getData(SERVER.ME(), res.token).then(async result => {
          //console.log('result LOGIN',result)
          setUserInf(result, res.token);
          setRedirect(true);
        });
        //saveUserInformation(res)
        
      } else {
        const auxMessage = { ...message };
        auxMessage.message = 'Invalid Login or Password';
        auxMessage.color = 'red';
        setMessage(auxMessage);
      }
    })

    console.log('Success:', values);

    // showUsers(userRequired);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (redirect) {
    return <Redirect to="/map" />
  }

  return (
    <div className="login">
      <div className="login-form">
        <img src="/Images/Logo.svg" alt="" width="180px" />
        <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item className="form-label"><label className="title-input">Email Address</label></Form.Item>
          <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
            <Input className="input-height" placeholder="johndoe@mail.com" />
          </Form.Item>

          <Form.Item className="form-label"><label className="title-input">Password</label></Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password placeholder="••••••••••" />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <div style={{ color: message.color    }}>
          { message.message }
          </div>

          <Form.Item>
            <Button className="btn-green" htmlType="submit">
              LOGIN
            </Button>
            <br />
              {/*<Link to="/test" className="test-button">
              <Button className="btn-border-gray">
                <img src="/Images/google.svg" alt="" height="15px" />
                GOOGLE
              </Button>
            </Link>*/}
          </Form.Item>
          <Link to='reset_password' ><p>Forgot your password?</p></Link>
          
          <a>SIGN UP</a>
        </Form>
        {/*<div>
        {data? 'Login Successful' : 'User Not Found'}
        </div>*/}

      </div>
    </div>
  );
}
