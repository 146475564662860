import { useState, useEffect, useRef } from 'react';
import {
  Popover, Tag, Collapse, List, message, Layout, Form, Select, Switch, Button, Row, Col, Tooltip,
  Input, Menu, Drawer, Table, Checkbox, Dropdown, Modal
} from 'antd';
import { SearchOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useAssetState, useAssetDispatch } from '../../hooks/asset';
import { useAssetTypeState, useAssetTypeDispatch } from '../../hooks/assetType';
import { useDeviceDispatch, useDeviceState } from '../../hooks/device';
import { useCampusDispatch, useCampusState } from '../../hooks/campus';
import { useBuildingDispatch, useBuildingState } from '../../hooks/building';
import { useFloorPlanDispatch, useFloorPlanState } from '../../hooks/floorPlan';
import { postRequest } from '../../config/httpClient';
import * as datasets from "../../config/datasets";
import { useCustomerState } from '../../hooks/customer';
import { SideBar } from '../Shared/SideBar';
import { NavBar } from '../Shared/NavBar';
import { Content } from 'antd/lib/layout/layout';
import { DESCEND, ASCEND, iconsListAsset } from '../../config/constants';
import { useLoginDispatch, useLoginState } from '../../hooks/login';
import { SERVER } from '../../config/serverConfig';
const { Option } = Select;
const { Panel } = Collapse;

export const Reports = () => {
  const { searchAssets, saveAsset, updateAsset, deleteAsset, searchAssetIcons } = useAssetDispatch();
  const { searchAssetTypes } = useAssetTypeDispatch();
  const { searchCampus } = useCampusDispatch();
  const { searchBuilding } = useBuildingDispatch();
  const { searchFloorPlan } = useFloorPlanDispatch();
  const { listDevices, searchDevices } = useDeviceDispatch();

  const devices = useDeviceState()
  const assetList = useAssetState();
  const assetTypeList = useAssetTypeState();
  const campusList = useCampusState();
  const buildingList = useBuildingState();
  const floorPlanList = useFloorPlanState();
  const customers = useCustomerState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [assetUpdate, setAssetUpdate] = useState({});
  const [form] = Form.useForm();
  const [params, setParams] = useState({});
  const [paramCampus, setParamCampus] = useState({});
  const [filteredInfo, setFilteredInfo] = useState([]);
  const inputSearch = useRef(null);
  const [visible, setVisible] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [dropTitle, setDropTitle] = useState([]);
  const [iconsList, setIconsList] = useState([]);
  const [buildings, setBuildings] = useState<any>([]);
  const [floorPlans, setFloorPlans] = useState([]);
  const [assetId, setAssetId] = useState('');
  const [iconSelected, setIconSelected] = useState('');
  const [assetTypeSelected, setAssetTypeSelected] = useState({ activity: false, asset_tag: false, color: false, comments: false, description_asset: false, make: false, manufacturer: false, model: false, part_number: false, sku: false, serial_number: false, usability: false, vin: false, icon: '' })

  const data = useLoginState();
  //console.log('PROFILEEEEE', data.userProfile);
  const userProfile = data.userProfile;

  // IMPORTANT TO REPORTS
  const [listHistoryAsset, setListHistoryAsset] = useState<any>([]);
  const [listColumnShowed, setListColumnShowed] = useState<any>(['asset_name', 'asset_type_name', 'timestamp', 'device_name']);
  const [showAType, setShowAType] = useState(false)


  useEffect(() => {
    const token: string = datasets.getToken()!;
    const data2 = { columns: ["device_name"] }
    datasets.postData(SERVER.GET_HISTORY_ASSET(), data2, token).then((res: any) => {
      console.log('RESP HISTORY', res)
      setListHistoryAsset([...res.elements])
    });
  }, []);

  useEffect(() => {
    console.log('change customer selected', userProfile);
    if (userProfile.email !== '') {
      retrieveData();
    }

  }, [customers.customerSelected]);

  /* useEffect(() => {
    console.log('se obtuvo me', userProfile);
    if (userProfile.email !== '') {
      retrieveData();
    }

  }, [userProfile]); */

  const fillForm = async (values: any) => {
    setVisible(true);
    setIsUpdate(true);
    console.log('fill asset', values, form.getFieldsValue());
    setShowIcons(!values.inherit_icon)
    setAssetId(values._id);
    console.log('CAMPUS', values.campus_id);
    if (values.campus_id) {

      searchBuilding({ campus_id: values.campus_id });
      console.log('BUILDINGS', buildingList.listBuilding);
      setBuildings([...buildingList.listBuilding]);
      searchFloorPlan({ building_id: values.building_id });
      console.log('FLOOR PLANS', floorPlanList.listFloorPlan);
      setFloorPlans(floorPlanList.listFloorPlan as any);
    }
    let assetFill = Object.assign({}, values)
    assetFill.devices = values.devices.map((tag: any) => tag._id)
    assetFill.asset_type = values.asset_type._id
    console.log('assetFill', assetFill)
    onSelectAssetType(values.asset_type._id)
    setIconSelected(values.icon)
    form.setFieldsValue(assetFill);
    //console.log('FORM', form);
    setAssetUpdate(assetFill);
  }

  const exportCSV = async () => {
    const token: string = datasets.getToken()!;
    const data2 = { columns: listColumnShowed }
    datasets.postDataOctet(SERVER.EXPORT_CSV_ASSET(), data2, token).then(res => {
      console.log('respuesta octet', res)
      const value = "" + res;
      let csvData = new Blob([value], { type: 'text/csv;charset=utf-8;' });
      let csvURL = window.URL.createObjectURL(csvData);
      let tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'activity.csv');
      tempLink.click();
      // dispatch({ type: 'download', res });
    });
  }
  const exportPDF = async () => {
    const token: string = datasets.getToken()!;
    const data2 = { columns: listColumnShowed }
    datasets.postDataOctetPDF(SERVER.EXPORT_PDF_ASSET(), data2, token).then(res => {
      console.log('respuesta octet PDF', res)

      // dispatch({ type: 'download', res });
    });
    /* if (loading) {
      return;
    }
    let url, map: any, fileName: string ;
    if (type === FILTER_PROBLEMS_TRIGGER) {
      url = `${process.env.REACT_APP_API_URI}/gallery/problem-by-id/${data.problemid}/pdf`;
      fileName = 'problem.pdf';
      let c: any = ciprRef.current;
      if (c) {
        map = c.getCanvasBase64()
      }
    } else {
      let params = `cartoid=${data.value}&type=${data.type}`;
      url = `${process.env.REACT_APP_API_URI}/gallery/project-by-ids/pdf?${params}`;
      fileName = 'project.pdf';
      let c: any = cipjRef.current;
      if (c) {
        map = c.getCanvasBase64();
      }
    }
    let body: any = { map };
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    setLoading(true); */
    /* fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    })
    .then( res => res.blob() )
    .then((r) => {
      var blob = new Blob([r], {type: 'application/pdf'});
      saveAs(blob, fileName)
    })
    .finally(() => {
      setLoading(false);
    }) */
  }

  const showDrawer = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }

  const retrieveData = (page: any = 1) => {
    console.log('retrieve Data', customers.customerSelected, userProfile.is_admin, page)
    if (userProfile.is_admin) {
      searchAssets({ customer_id: customers.customerSelected._id ? customers.customerSelected._id : '', page: page, limit: 10 });
    } else {
      if (!customers.customerSelected._id) {
        searchAssets({ customer_id: '123431242134', page: page, limit: 10 });
      } else {
        searchAssets({ customer_id: customers.customerSelected._id, page: page, limit: 10 });
      }
    }
  }

  const clearAll = () => {
    setFilteredInfo([]);
    setDropTitle([])
  }

  const onClose = () => {
    setVisible(false);
    //searchAssets(params);
  }

  const clearForm = () => {
    form.resetFields();
  }

  const searchFilter = (element: any) => {
    setShowAType(!showAType)
    /* clearAll();
    let textSearch = '';
    if (element.current.state.value !== undefined) {
      textSearch = element.current.state.value;
    }
    console.log('text', textSearch);
    if (userProfile.is_admin) {
      searchAssets({ customer_id: customers.customerSelected._id, name: textSearch });
    } else {
      if (!customers.customerSelected._id) {
        searchAssets({ customer_id: '123431242134' });
      } else {
        searchAssets({ customer_id: customers.customerSelected._id, name: textSearch });
      }
    }
    /* const arrayFilter: any = [];
    arrayFilter.push(textSearch); */
    //setFilteredInfo(arrayFilter);
  }

  const columns = [
    {
      title: 'Asset Name',
      dataIndex: 'asset_name',
      key: 'asset_name',
      //filteredValue: filteredInfo
      visible: listColumnShowed.includes('asset_name')
    },

    {
      title: 'Asset Type',
      dataIndex: 'asset_type_name',
      key: 'asset_type_name',
      visible: listColumnShowed.includes('asset_type_name')
      // filteredValue: filteredInfo,
      /* sorter: (a: any, b: any) => {
        // console.log('sorter', a, b);
        return a.asset_type.toUpperCase().charCodeAt(0) - b.asset_type.toUpperCase().charCodeAt(0)
      },
      onFilter: (value: any, record: any) => {
        //console.log('onFilter', value, record);
        let valorIn = value.toLowerCase()
        //console.log(record.devicetype.toString().toLowerCase().includes(valorIn), valorIn, record.devicetype.toString().toLowerCase())
        return record.asset_type.toString().toLowerCase().includes(valorIn) || record.asset_type.toString().toLowerCase().includes(valorIn)
      }, */
    },
    /*  {
       title: 'Location (Long, Lat)',
       dataIndex: 'location',
       key: 'location',
       visible: true
     }, */
    {
      title: 'Last Reported',
      dataIndex: 'timestamp',
      key: 'timestamp',
      visible: listColumnShowed.includes('timestamp')
    },
    {
      title: 'Device Name',
      dataIndex: 'device_name',
      key: 'device_name',
      visible: listColumnShowed.includes('device_name')
    }
  ];

  const selectFilter = (element: any) => {
    console.log('menu', element);
    const arrayFilter: any = [];
    arrayFilter.push(element.key);
    setFilteredInfo(arrayFilter);
    setDropTitle(arrayFilter);
    console.log(arrayFilter);
  }

  const onSelectIcon = (element: any) => {
    console.log('on select', element)
    const icon: any = assetList.listIcons.find((icon: any) => icon._id === element.target.id)
    console.log('found Icon', icon)
    // setIconSelected(icon)
    setIconSelected(element.target.id)
  }

  const menu = (
    <Menu>
      {assetTypeList.listAssetTypes.map((assettype: any) => <Menu.Item key={assettype.name} onClick={selectFilter}>
        {assettype.name}
      </Menu.Item>
      )}
      <Menu.Item key={'clear'}>
        <Button className="btn-border-blue" onClick={clearAll}>Clear filters</Button>
      </Menu.Item>
    </Menu>
  );


  const registerData = (values: any) => {
    console.log('Success', values);
    const asset = {};
    values['asset_type_id'] = values.asset_type;
    if (!isUpdate) {
      values['icon'] = iconSelected;
      if (!userProfile.is_admin) {
        if (!customers.customerSelected._id) {
          message.error('Please choose a customer');
          return;
        }
        values['customer_id'] = customers.customerSelected._id;
      }
      saveAsset(values);
      // searchAssets({})
      retrieveData();
      onClose();
      clearForm();
    } else {

      values['_id'] = assetId;
      values['icon'] = iconSelected;
      console.log(values);
      updateAsset(values);
      message.success('Asset Updated successfully', 3);
      onClose();
      clearForm();
      retrieveData();
    }
  }

  const showConfirmModal = (id: any) => {
    //setPartnerId(id);
    Modal.confirm({
      title: "Remove Asset",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to remove Asset?',
      async onOk() {
        await deleteAsset(id);
        retrieveData();
      }
    });
  }
  const onSelectAssetType = (element: any) => {
    console.log(element)
    const devtype: any = assetTypeList.listAssetTypes.find((type: any) => type._id === element)
    //console.log('devtype on', devtype)
    if (devtype) {
      setAssetTypeSelected(devtype)
      setIconSelected(devtype.icon)
    }
  }

  const onChangeCampus = async (value: any) => {
    console.log('cambio campus')
    const data = { campus_id: value };
    const token: string = datasets.getToken()!;
    searchBuilding(data)
    // await postRequest(SERVER.SEARCH_BUILDING(), data, token).then(async result => {
    //   setBuildings(result);
    //   setFloorPlans([]);
    //   form.setFieldsValue({
    //     floor_plan_id: ''
    //   });
    //   form.setFieldsValue({
    //     building_id: ''
    //   });
    // });
  }

  const onChangeBuilding = async (value: any) => {
    const token: string = datasets.getToken()!;
    const data = { building_id: value };
    searchFloorPlan(data)
    // await postRequest(SERVER.SEARCH_FLOOR_PLAN(), data, token).then(async result => {
    //   setFloorPlans(result);
    //   form.setFieldsValue({
    //     floor_plan_id: ''
    //   });
    // });
    /* searchFloorPlan({ building_id: value });
    setFloorPlans([]);
    console.log('FLOOR PLANS', floorPlanList);
    setFloorPlans(floorPlanList.listFloorPlan as any);
    form.setFieldsValue({
      floor_plan_id: ''
    }); */
  }

  const handlePageClick = (page: any) => {
    console.log('handlePageClick', page)
    retrieveData(page)
  }

  const onSelectColumn = (e: any) => {
    console.log('select Column', e)
    setListColumnShowed(e.length ? [...e]: columns.map((col: any) => { return col.key }))

  }

  return (
    <Layout style={{ height: '100vh' }}>
      <SideBar></SideBar>
      <Layout>
        <NavBar></NavBar>
        <Layout className="site-layout">
          <Content className="user table-general">
            <Row>
              <Col xs={24} lg={24}>
                <div className="card-white">
                  <div className="head-t">
                    <Row>
                      <Col xs={24} lg={18}>

                        <label>Filter Columns:</label>
                        {/* <Dropdown overlay={menu} trigger={['click']}>
                          <Button className="btn-border-light-gray">
                            {dropTitle.length === 0 ? 'Choose' : dropTitle[0]} <DownOutlined />
                          </Button>
                        </Dropdown> */}


                        <Select
                          placeholder="Please select Columns to show"
                          mode={'multiple'}
                          onChange={onSelectColumn}
                          style={{ width: 250 }}
                          optionFilterProp='label'
                          //labelInValue={true}
                          //searchValue='hola'
                        >
                          {columns.map((column: any) => <Option value={column.key} label={column.title}>{column.title}</Option>)}
                        </Select>
                        {/*  <Input allowClear onPressEnter={() => searchFilter(inputSearch)} ref={inputSearch} placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 150 }} />

                        <Button className="btn-blue" onClick={() => searchFilter(inputSearch)}>Search</Button> */}
                      </Col>
                      <Col xs={24} lg={6} style={{ textAlign: 'right' }}>
                        {/*<Input placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 150 }} />*/}
                        <Button className="btn-green" onClick={exportPDF}><i className="mdi mdi-file-pdf-box"></i>PDF</Button>
                        <Button className="btn-green" onClick={exportCSV}>CSV</Button>
                        <Drawer
                          className="drawer-form"
                          title={isUpdate ? 'Edit Asset' : 'New Asset'}
                          placement="right"
                          closable={false}
                          onClose={onClose}
                          visible={visible}
                          width={600}
                          footer={
                            <div
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <Button style={{ marginRight: 8 }} className="btn-border-red" onClick={showDrawer}>
                                Cancel
                            </Button>
                              <Button className="btn-green" onClick={() => form.submit()}>
                                Save
                            </Button>
                            </div>
                          }
                        >
                          <Form form={form} onFinish={registerData} layout="vertical" hideRequiredMark initialValues={{ 'inherit_icon': true }}>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name='name' label="*Name:"
                                  rules={[{ required: true, message: 'Please input Asset name!' }]}>
                                  <Input placeholder="Please enter Name" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name='asset_type' label="*Asset Type:"
                                  rules={[{ required: true, message: 'Please select Asset Type!' }]}>
                                  <Select
                                    placeholder="Please select Asset type"
                                    onSelect={onSelectAssetType}
                                  >
                                    {assetTypeList.listAssetTypes.map((type: any) => <Option key={type._id} value={type._id}>{type.name}</Option>)}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name='description' label="Description:" >
                                  <Input placeholder="Please enter description" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name='devices' label="Devices Associated:">
                                  {/* <Input placeholder="Please select Primary Contact" /> */}
                                  <Select
                                    placeholder="Please select Devices Associated"
                                    mode={'multiple'}
                                    //onChange={(e) => {console.log(e)}}
                                    optionFilterProp='label'

                                  //labelInValue={true}
                                  //searchValue='hola'
                                  >
                                    {devices.listDevices.map((device: any) => <Option value={device._id} label={device.model_name}>{device.model_name}</Option>)}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name="campus_id" label="Campus:">
                                  <Select
                                    placeholder="Please select Campus"
                                    onChange={(value, event) => { onChangeCampus(value); }}
                                  >
                                    {campusList.listCampus.map((campus: any) => <Option value={campus._id} label={campus.name} >{campus.name}</Option>)}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="building_id" label="Building">
                                  <Select
                                    placeholder="Please select Building"
                                    onChange={(value, event) => { onChangeBuilding(value); }}
                                  >
                                    {/* {buildings.map((building: any) => <Option value={building._id} label={building.name}>{building.name}</Option>)} */}
                                    {buildingList.listBuilding.map((building: any) => <Option value={building._id} label={building.name}>{building.name}</Option>)}

                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name="floor_plan_id" label="Floor Plan">
                                  <Select
                                    placeholder="Please select Floor Plan"
                                  >
                                    {/* {floorPlans.map((floor: any) => <Option value={floor._id} label={floor.floor_name}>{floor.floor_name}</Option>)} */}
                                    {floorPlanList.listFloorPlan.map((floor: any) => <Option value={floor._id} label={floor.floor_name}>{floor.floor_name}</Option>)}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="customer_id" label="*Customer Asociated" rules={[{ required: true, message: 'Please choose Customer!' }]} className="select-bg">
                                  <Select
                                    placeholder="Please select a Customer"
                                  >
                                    {customers.listCustomers.map((customer: any) => <Option value={customer._id} label={customer.customer_name}>{customer.customer_name}</Option>)}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name='inherit_icon' label="Inherit Category Icon?:" valuePropName="checked" >
                                  <Switch onChange={(element: any) => {
                                    setShowIcons(!element)
                                    setIconSelected(assetTypeSelected.icon)
                                  }
                                  } />
                                </Form.Item>
                              </Col>
                              <Col hidden={iconSelected.length === 0} span={12}>
                                <Form.Item name='category_icon' label="Category Icon:" valuePropName="checked" >
                                  <img src={'/Images/assetIcons/' + iconSelected} width="30px" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <div hidden={!showIcons}>
                              <Collapse defaultActiveKey={['2']} ghost expandIconPosition="right">
                                {/* <Panel className="Collapse-p-scroll" header={<>{'ICONS   '}<img hidden={iconSelected.length === 0} src={'/Images/assetIcons/' + iconSelected} width="30px" /></>} key="1"> */}
                                <Panel className="Collapse-p-scroll" header={<>{'ICONS   '}</>} key="1">
                                  <Form form={form} onFinish={registerData} layout="vertical" hideRequiredMark>
                                    <Row gutter={24}>
                                      <Col span={24}>
                                        {/* <Input placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 200 }} /> */}
                                        <Form.Item name='icon' >
                                          <List style={{ textAlign: 'center' }}
                                            grid={{ gutter: 16, column: 6 }}
                                            //dataSource={assetList.listIcons}
                                            dataSource={iconsList}
                                            renderItem={(item: any) => (
                                              <List.Item id={item} key={item._id} onClick={onSelectIcon}>
                                                <Tooltip title={item.substring(0, item.length - 4)}>
                                                  <img id={item} src={"/Images/assetIcons/" + item} alt="" width="50px" />
                                                  {/* {item} */}
                                                </Tooltip>
                                              </List.Item>)}>
                                          </List>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </Form>
                                </Panel>
                              </Collapse>
                            </div>
                            {/* <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name='latitude' label="*Latitude:">
                                  <Input placeholder="Please enter Latitude" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name='longitude' label="*Longitude:">
                                  <Input placeholder="Please enter Longitude" />
                                </Form.Item>
                              </Col>
                            </Row> */}
                          </Form>
                        </Drawer>
                      </Col>
                    </Row>
                  </div>

                  <Table dataSource={listHistoryAsset.map((asset: any) => {
                    console.log('timestamp', new Date(parseInt(asset.timestamp)))

                    return ({
                      check: <Checkbox></Checkbox>,
                      key: asset._id,
                      asset_name: asset.asset_name,
                      asset_type_name: asset.asset_type_name,
                      device_name: asset.device_name,
                      timestamp: (new Date(parseInt(asset.timestamp)).toUTCString()),
                      actions: <>
                        <Tooltip title="Edit"><Button className="btn-edit" onClick={() => fillForm(asset)}><i className="mdi mdi-pencil"></i></Button></Tooltip>
                        <Tooltip title="Delete"><Button className="btn-delete" onClick={() => showConfirmModal(asset._id)}><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
                      </>,
                    })
                  })} columns={columns.filter((column: any) =>
                    column.visible === true)
                  } pagination={{ onChange: handlePageClick }} scroll={{ y: 'calc(100vh - 267px)' }} />
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
