import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SEARCH_ASSETS, SHOW_ASSETS, AssetType, SAVE_ASSET, UPDATE_ASSET,
        DELETE_ASSET, LIST_ICONS, GET_ASSET_MAP, UPDATE_ASSET_FROM_LIST } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from '../../config/datasets';

export const searchAsset = (listAsset: any) => {
  return {
    type: SEARCH_ASSETS,
    data: listAsset.elements,
    pagination: { 
      total: listAsset.total,
      page: listAsset.page
    } 
  }
}

export const listAssets = (listAsset: any) => {
  return {
    type: SHOW_ASSETS,
    data: listAsset
  }
}

export const buildingById = (building: any) => {
  return {
    type: SHOW_ASSETS,
    data: building
  }
}

export const listIcons = (listIcons: any) => {
  return {
    type: LIST_ICONS,
    data: listIcons
  }
}

export const saveObjectAsset = (asset: any) => {
  return {
    type: SAVE_ASSET,
    data: asset
  }
}

export const updateObjectAsset = (asset: any) => {
  return {
    type: UPDATE_ASSET,
    data: asset
  }
}

export const deleteObjectAsset = () => {
  return {
    type: DELETE_ASSET
  }
}

export const getAssetMapData = (listAsset: any) => {
  return {
    type: GET_ASSET_MAP,
    data: listAsset,
  }
}
export const searchAssets = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.SEARCH_ASSETS(), data, token);
    dispatch(searchAsset(response));
  }
}

export const saveAsset = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    console.log('qwevwienweovfpwief', data);
    const response = await datasets.postData(SERVER.SAVE_ASSET(), data, token);
    dispatch(saveObjectAsset(response));
  }
}

export const loadAssets = (): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await getRequest(SERVER.LIST_ASSETS(), token);
    dispatch(listAssets(response));
  }
}

export const updateAsset = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.putData(SERVER.UPDATE_ASSET(), data, token);
    dispatch(updateObjectAsset(response));
  }
}

export const deleteAsset = (id: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.deleteData(SERVER.DELETE_ASSET(id), token);
    dispatch(deleteObjectAsset());
  }
}

export const searchAssetIcons = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await getRequest(SERVER.GET_ASSET_ICONS(), token);
    dispatch(listIcons(response));
  }
}

export const getAssetsMap = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.GET_ASSETS_MAP(), data, token);
    dispatch(getAssetMapData(response));
  }
}

export const getAssetsMapArray = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.GET_ASSETS_MAP_COLLECTION_v2(), data, token);
    dispatch(getAssetMapData(response));
  }
}

export const getFeatureCollection = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    // const response = await datasets.postData(SERVER.GET_ASSETS_MAP_ARRAY(), data, token);
    const response = await datasets.postData(SERVER.GET_ASSETS_MAP_COLLECTION_v2(), data, token);
    dispatch(getAssetMapData(response));
  }
}

export const updateAssetFromList = (assetData: any) => {
  return {
    type: UPDATE_ASSET_FROM_LIST,
    data: assetData,
  }
}
/* export const getBuildingById = (id: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.deleteData(SERVER.GET_BUILDING_BY_ID(id), token);
    dispatch(buildingById(response));
  }
} */