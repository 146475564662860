import { DeviceTypeState, SHOW_DEVICETYPES, DevicetypType, SEARCH_DEVICETYPES } from '../types'

const initialState = {
    listDeviceTypes: [],
    pagination: {}
}
export const devicetypReducer = (state: DeviceTypeState = initialState, action: DevicetypType) => {
    switch (action.type) {
        case SHOW_DEVICETYPES:
            return Object.assign({}, state, { listDeviceTypes: action.data })
        case SEARCH_DEVICETYPES:
            return Object.assign({}, state, { listDeviceTypes: action.data, pagination: action.pagination })
        default:
            return state
    }
}