import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { GET_SIDEBAR, SET_SIDEBAR } from '../types';
import { RootState } from '../reducers';
import * as datasets from '../../config/datasets'


export const setSideBar = (data: any) => {
  //console.log('login action', user)
  return {
    type: SET_SIDEBAR,
    statesideBar: data
  }
}
