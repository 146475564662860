import React, { useEffect, useState } from 'react'
import { Layout, Menu, Breadcrumb } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { ROUTERS_SIDEBAR } from '../../config/constants';
import { useLoginState } from '../../hooks/login';

import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useShared, useSharedDispatch } from '../../hooks/shared';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export const SideBar = () => {
  const { statesideBar } = useShared();
  const { setSideBar } = useSharedDispatch();
  const [collapsed, setCollapsed] = useState(statesideBar);
  const [isCustomerStaff, setIsCustomerStaff] = useState(false);
  const [showPartners, setShowPartners] = useState(false);
  const location = useLocation();
  const data = useLoginState();
  const userProfile = data.userProfile;
  const onCollapse = (collapsed: boolean) => {
    console.log(collapsed);
    setCollapsed(collapsed);
    setSideBar(collapsed);
  };
  //console.log('PROOOOOOFILE', userProfile);
  const indexOf = "" + ROUTERS_SIDEBAR.indexOf(location.pathname);

  useEffect(() => {
    if (userProfile.is_admin) {
      setIsCustomerStaff(false);
      setShowPartners(true);
    } else {
      if (userProfile.partners.length > 0) {
        setIsCustomerStaff(false);
        setShowPartners(true);
      } else {
        setShowPartners(false);
        if (userProfile.customers.length > 0) {
          const data = userProfile.customers[0] as any;
          for (let user of data.users) {
            if (user.role === 'staff') {
              setIsCustomerStaff(true);
            }
          }
        } else {
          setIsCustomerStaff(true);
        }
      }
    }
  }, [userProfile])

  return (
    <Sider className="sidebar" theme="light" collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <div className="logo" />
      <Menu defaultSelectedKeys={[indexOf]} mode="inline">
        <Menu.Item key="1" icon={<img src="/Images/sidebar/icon-01.svg" alt="" width="22px" />}>
          <Link to={'/map'}>Map</Link>
        </Menu.Item>
        {(!isCustomerStaff) ?
          <SubMenu key="2" title={<span className="title-height-00">Administration</span>} icon={<img className="anticon" src="/Images/sidebar/user.svg" alt="" width="22px" style={{ marginTop: '12px' }} />}>
            {showPartners ?
              <Menu.Item key="3" icon={<img src="/Images/sidebar/partner.svg" alt="" width="22px" />}><Link to={'/partners'}>Partners</Link></Menu.Item>
              : ''}
            <Menu.Item key="4" icon={<img src="/Images/sidebar/customer.svg" alt="" width="22px" />}><Link to={'/customer'}>Customers</Link></Menu.Item>
            <Menu.Item key="5" icon={<img src="/Images/sidebar/icon-02.svg" alt="" width="22px" />}><Link to={'/user'}>Users</Link></Menu.Item>
          </SubMenu>
          : ''}
        <SubMenu key="6" title={<span className="title-height">Devices</span>} icon={<img className="anticon" src="/Images/sidebar/group_device.svg" alt="" width="24px" style={{ marginTop: '11px' }} />}>
          {(data.userProfile.is_admin) ?
            <Menu.Item key="7" icon={<img src="/Images/sidebar/type-device.svg" alt="" width="18px" style={{ marginLeft: '2px' }} />}><Link to={'/devicetypes'}>Device Types</Link></Menu.Item> : ''}
          <Menu.Item key="8" icon={<img src="/Images/sidebar/icon-04.svg" alt="" width="20px" />}><Link to={'/devices'}>Devices</Link></Menu.Item>
        </SubMenu>
        <Menu.Item key="9" icon={<img src="/Images/sidebar/campus-icon.svg" alt="" width="24px" style={{ marginLeft: '0px' }} />}>
          <Link to={'/campus'}>Campus</Link>
        </Menu.Item>
        <SubMenu key="10" title={<span className="title-height">Assets</span>} icon={<img className="anticon" src="/Images/sidebar/group_asset.svg" alt="" width="24px" style={{ marginTop: '11px' }} />}>
          {/* <Menu.Item key="10" icon={<img src="/Images/sidebar/asset_type.svg" alt="" width="18px"  style={{ marginLeft: '2px' }}/>}><Link to={'/campus'}>Campus</Link></Menu.Item> */}
          <Menu.Item key="11" icon={<img src="/Images/sidebar/asset_type.svg" alt="" width="18px" style={{ marginLeft: '2px' }} />}><Link to={'/assettypes'}>Asset Types</Link></Menu.Item>
          <Menu.Item key="12" icon={<img src="/Images/sidebar/asset.svg" alt="" width="20px" />}><Link to={'/assets'}>Assets</Link></Menu.Item>
        </SubMenu>
        <Menu.Item key="13" icon={<img src="/Images/sidebar/icon-03.svg" alt="" width="20px" style={{ marginLeft: '2px' }} />}>
          <Link to={'/reports'}>Reports</Link>
        </Menu.Item>
        <Menu.Item key="14" icon={<img src="/Images/sidebar/Grupo 1893.svg" alt="" width="20px" style={{ marginLeft: '2px' }} />}>
          <Link to={'/rawmessages'}>Raw Messages</Link>
        </Menu.Item>
        <Menu.Item key="15" icon={<img src="/Images/sidebar/location-history.svg" alt="" width="20px" style={{ marginLeft: '2px' }} />}>
          <Link to={'/locationhistory'}>Location History</Link>
        </Menu.Item>
        <Menu.Item key="16" icon={<img src="/Images/sidebar/icon-05.svg" alt="" width="22px" />}>
          Configuration
          </Menu.Item>
        <Menu.Item className="power-by" key="17" icon={<img src="/Images/sidebar/icon-06.svg" alt="" width="28px" />}>
          <span><label>POWERED BY:</label>
            <h5><b>PROPEL</b>GPS</h5>
            {/*<h6>Propelling into the Future innovative Technology</h6>*/}
            <p>Copyright © 2021 by Propel GPS</p></span>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

// ReactDOM.render(<SiderDemo />, mountNode);
