
export const USER_LOGGED = 'USER_LOGGED';

export interface LoginState {
    list: { userResult: { username: string }},
    userProfile: { 
      username: string, 
      email: string, 
      password: string, 
      full_name: string, 
      role: string, 
      time_zone: string, 
      customers: any[], 
      partners: string[], 
      is_admin: boolean, 
      token: string 
    }
}
export interface UserProfile {
  userProfile: {}
}
interface ReplaceLoginAction {
    type: typeof USER_LOGGED,
    data: LoginState,
    userProfile: UserProfile
  }

export type LoginType = ReplaceLoginAction 