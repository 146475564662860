import { useDispatch, useSelector } from 'react-redux';

import { logUser, UserLogged, setUserInf, userMe } from '../store/actions';
import { RootState } from '../store/reducers';

export const useLoginState = () => {
  return useSelector((state: RootState) => state.login);
};


export const useLoginDispatch = () => {
  const dispatch = useDispatch();
  return {
    UserLogged: (user: object) => dispatch(logUser(user)),
    setUserInf: (user: object, token: string ) => dispatch(setUserInf(user, token)),
    userMe: () => dispatch(userMe())
  };
};
