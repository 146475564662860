import { LoginState, USER_LOGGED, LoginType } from '../types'

const initialState = {
    list: { userResult: { username: ''}},
    userProfile: { username: '', email: '', password: '', full_name: '', role: '', time_zone: '', customers: [], partners: [], is_admin: false, token: ''}
}
export const loginReducer = (state: LoginState = initialState, action: LoginType ) => {
    switch (action.type) {
        case USER_LOGGED:
            return Object.assign({}, state, {userProfile: action.userProfile})
        default:
            return state
    }

}