import React, { useEffect, useState, useRef } from 'react';
import { Switch, Popover, Modal, message, Layout, Menu, Row, Col, Input, Dropdown, Button, Table, Checkbox, Tooltip, Drawer, Form, Select, Alert, Radio, Tag } from 'antd';
import * as datasets from "../../config/datasets";
import { Link } from 'react-router-dom';
import { SideBar } from '../Shared/SideBar'
import { NavBar } from '../Shared/NavBar'
import { AudioOutlined, DownOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useUserDispatch, useUserState } from '../../hooks/user';
import { useCPartnerDispatch, useCPartnerState } from '../../hooks/channelpartner';
import { useLoginState, useLoginDispatch } from '../../hooks/login';
import { SERVER } from '../../config/serverConfig';
import { getRequest, postRequest } from '../../config/httpClient';
// import { listCPartners } from '../../store/actions';
import { DESCEND, ASCEND } from '../../config/constants';
import { useCustomerDispatch, useCustomerState } from '../../hooks/customer';
import { listCustomers } from '../../store/actions';

const { Header, Content, Sider } = Layout;
const { Search } = Input;
const { Option } = Select;

export const Profile = () => {
  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [roleSelected, setRoleSelected] = useState('propel');
  const [userUpdate, setUserUpdate] = useState({});
  const [isTouchEmail, setIsTouchEmail] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const inputSearch = useRef(null)
  //const [filteredInfo, setFilteredInfo] = useState([]);
  const [dropTitle, setDropTitle] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState([]);
  const { listUsers, searchUsers } = useUserDispatch();
  const { listCPartners, searchPartners } = useCPartnerDispatch();
  const { listCustomer, searchCustomers } = useCustomerDispatch();
  const data = useLoginState();
  const { UserLogged, setUserInf, userMe } = useLoginDispatch();
  //console.log(data.userProfile)
  const userProfile = data.userProfile
  //console.log('miuserprofile', data.userProfile)
  const customers = useCustomerState();
  const users = useUserState()
  const partners = useCPartnerState();
  const [param, setParam] = useState({});
  const [form] = Form.useForm()
  const [form2] = Form.useForm()


  useEffect(() => {
    // listCustomer();
    searchCustomers({})
    searchUsers(param);
    //listUsers();
    // listCPartners();
    searchPartners({});
  }, [])

  useEffect(() => {

    fillFormUpdate(data.userProfile)
  }, [data])
  const showDrawer = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const clearForm = () => {
    form.resetFields();
  }

  const setFormNewUser = () => {
    setVisible(true);
    setIsUpdate(false)
    const userFill = {
      username: '',
      email: '',
      full_name: '',
      password: ''
    }
    form.setFieldsValue(userFill)
  }

  const fillFormUpdate = (values: any) => {
    console.log('fillform', values)
    setVisible(true);
    setIsUpdate(true)
    console.log(values)
    const userFill = Object.assign({}, values)
    //if (userFill.email == '') {
    userFill.email = values.email
    userFill.partners = values.partners.map((tag: any) => tag._id)
    userFill.customers = values.customers.map((tag: any) => tag._id)
    //}
    setRoleSelected(values.role)
    form.setFieldsValue(userFill)
    setUserUpdate(values)
  }
  const onClose = () => {
    setVisible(false);
    clearForm();
  };

  const registerUserDatabase = (values: any) => {
    console.log('Register Values:', values);
    if (!isUpdate) {
      values.username = values.email
      datasets.postData(SERVER.REGISTER_USER(), values, datasets.getToken()).then(res => {
        searchUsers(param);
        showDrawer();
        message.success('User registered successfully');
      })
    } else {
      updateUserDatabase(values);
      setIsUpdate(false);
      showDrawer();
    }
  }

  const updateUserDatabase = (dataUser: any) => {
    console.log('Success Update:', dataUser);
    dataUser.username = dataUser.email
    console.log()
    datasets.putData(SERVER.UPDATE_USER_PROFILE(), dataUser, datasets.getToken()).then(res => {
      userMe();
      message.success('User Updated successfully');
      onClose();
    })
  }

  const deleteUserDatabase = (user: any) => {
    console.log('eliminar usuario', user._id)
    datasets.deleteData(SERVER.DELETE_USER(user._id), datasets.getToken()).then(res => {
      listUsers();
      message.success('User Removed successfully', 4);
      onClose();
    })
  }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const selectRole = (element: any) => {
    console.log(element)
    setRoleSelected(element.target.value)
  }

  const changePassword = (values: any, userUpd: any) => {
    console.log('change Pass', values, userUpd)
    const userPass = {
      id: userUpd._id,
      password: values.password,
      passwordConfirm: values.passwordconfirm
    }
    postRequest(SERVER.RESET_PASSWORD(), userPass).then(async res => {
      if (!res.error) {
        message.success('Password changed successfully', 4);
      } else {
        message.error('Password has not changed', 4);
      }
    })
  }

  const showConfirmModal = (user: any) => {
    console.log('confirm', user);
    Modal.confirm({
      title: "Remove User",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to remove Asset?',
      onOk() {
        deleteUserDatabase(user);
      }
    });
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <SideBar></SideBar>
      <Layout>
        <NavBar></NavBar>
        <Layout className="site-layout">
          <Content className="user table-general">
            <Row>
              <Col xs={24} lg={24}>
                <div className="card-white">
                  <div className="head-t">
                    <Row>
                      <Col xs={24} lg={18}>
                        <label>Profile Data :</label>


                      </Col>
                      <Col xs={24} lg={6} style={{ textAlign: 'right' }}>
                        {/* <Input placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 200 }} /> */}

                      </Col>
                    </Row>
                  </div>
                  <div className='drawer-form' style={{ justifyContent: 'center', display: 'flex' }}>
                    <Form form={form} onFinish={updateUserDatabase} layout="horizontal" hideRequiredMark initialValues={{ 'sending_sms': false, 'sending_email': false }}>
                      <Row gutter={32}>
                        <Col span={16}>
                          <Form.Item name='email' label="*Email:" hasFeedback validateTrigger={['onBlur']} rules={[{ required: true, message: 'Please enter an Email!' },
                          ({ getFieldValue, isFieldTouched }) => ({
                            validator: async (_, value) => {
                              console.log('user xd', value)
                              await datasets.postData(SERVER.VALIDATE_EMAIL(), { email: value }, datasets.getToken()).then(res => {
                                console.log('response Validation', res, !isTouchEmail, isUpdate)
                                //console.log('devi xd', !value, res.valid, isUpdate)
                                if (isUpdate && !isTouchEmail) {
                                  setIsTouchEmail(false)
                                  return Promise.resolve();
                                } else {
                                  if (!value || res.valid) {
                                    return Promise.resolve();
                                  }
                                }
                                return Promise.reject('This email already exists, please choose another!');
                              })
                            },
                          })
                          ]}>
                            <Input onChange={() => setIsTouchEmail(true)} placeholder="Please enter email" />
                          </Form.Item>
                        </Col>
                        <Col span={16}>
                          <Form.Item name='password' label="*Password:" rules={[{ required: true, message: 'Please enter your Password!' }]} >
                            <Input placeholder="Please enter password" type="password" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={32}>
                        <Col span={16}>
                          <Form.Item name='full_name' label="*Full Name:" rules={[{ required: true, message: 'Please enter your Name!' }]}>
                            <Input placeholder="Please enter name" />
                          </Form.Item>
                        </Col>
                        <Col span={16}>
                          <Form.Item name='time_zone' label="*TimeZone:" rules={[{ required: true, message: 'Please select your country!' }]} >
                            <Select >
                              <Option value="EST">EST</Option>
                              <Option value="AKST">AKST</Option>
                              <Option value="PST">PST</Option>
                              <Option value="CST">CST</Option>
                              <Option value="AST">AST</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item name='language' label="*Language:" >
                            <Select placeholder="Please choose">
                              <Option value="english">English</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name='mobile_number' label="Phone:" >
                            <Input placeholder="Please enter phone" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item name="sending_email" label={'*Send emails:'} valuePropName="checked" >
                            <Switch />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item name="sending_sms" label={'*Send sms:'} valuePropName="checked">
                            <Switch />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item name="role"  >
                            <Button className="btn-green" type="primary" htmlType="submit">
                              Update
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
