import React, { useEffect, useState, useRef } from 'react';
import { Popover, Modal, message, Layout, Menu, Row, Col, Input, Dropdown, Button, Table, Checkbox, Tooltip, Drawer, Form, Select, Switch, Radio, Tag } from 'antd';
import * as datasets from "../../config/datasets";
import { Link } from 'react-router-dom';
import { SideBar } from '../Shared/SideBar'
import { NavBar } from '../Shared/NavBar'
import { AudioOutlined, DownOutlined, SearchOutlined, ExclamationCircleOutlined, EyeFilled } from '@ant-design/icons';
import { useUserDispatch, useUserState } from '../../hooks/user';
import { useCPartnerDispatch, useCPartnerState } from '../../hooks/channelpartner';
import { useLoginState } from '../../hooks/login';
import { SERVER } from '../../config/serverConfig';
import { getRequest, postRequest } from '../../config/httpClient';
// import { listCPartners } from '../../store/actions';
import { DESCEND, ASCEND } from '../../config/constants';
import { useCustomerDispatch, useCustomerState } from '../../hooks/customer';
import { listCustomers } from '../../store/actions';

const { Header, Content, Sider } = Layout;
const { Search } = Input;
const { Option } = Select;

const dataSource = [
  {
    check: <Checkbox></Checkbox>,
    uid: '2618',
    name: 'JohnDoe@propel.gps',
    contact: 'John Doe',
    phone: 'Empty',
    type: 'Support',
    web: 'www.propelgps.com',
    key: '1',
    actions: <>
      <Tooltip title="Edit"><Button className="btn-edit"><i className="mdi mdi-pencil"></i></Button></Tooltip>
      <Tooltip title="Delete"><Button className="btn-delete"><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
      <Tooltip title="Change Password"><Button className="btn-password"><i className="mdi mdi-lock-question"></i></Button></Tooltip>
    </>,
  },
];

export const User = () => {
  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [roleSelected, setRoleSelected] = useState('propel');
  const [userUpdate, setUserUpdate] = useState({ archived: false });
  const [isTouchEmail, setIsTouchEmail] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const inputSearch = useRef(null)
  //const [filteredInfo, setFilteredInfo] = useState([]);
  const [dropTitle, setDropTitle] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState([]);
  const { listUsers, searchUsers } = useUserDispatch();
  const { listCPartners, searchPartners } = useCPartnerDispatch();
  const { listCustomer, searchCustomers } = useCustomerDispatch();
  const data = useLoginState();
  //console.log(data.userProfile)
  const userProfile = data.userProfile
  const customers = useCustomerState();
  const users = useUserState()
  const partners = useCPartnerState();
  const [param, setParam] = useState({});
  const [form] = Form.useForm()
  const [form2] = Form.useForm()


  useEffect(() => {
    // listCustomer();
    searchCustomers({})
    searchUsers(param);
    //listUsers();
    // listCPartners();
    searchPartners({});
  }, [])

  const showDrawer = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const clearForm = () => {
    form.resetFields();
  }
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: any, b: any) => {
        // console.log('sorter', a, b);
        return a.email.toUpperCase().charCodeAt(0) - b.email.toUpperCase().charCodeAt(0)
      },
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      filteredValue: filteredInfo,
      defaultSortOrder: ASCEND,
      sorter: (a: any, b: any) => {
        // console.log('sorter', a, b);
        return a.full_name.toUpperCase().charCodeAt(0) - b.full_name.toUpperCase().charCodeAt(0)
      },
      /* onFilter: (value: any, record: any) => {
        //console.log('onFilter', value, record);
        let valorIn = value.toLowerCase()
        //console.log(record.devicetype.toString().toLowerCase().includes(valorIn), valorIn, record.devicetype.toString().toLowerCase())
        return record.full_name.toString().toLowerCase().includes(valorIn) || record.full_name.toString().toLowerCase().includes(valorIn)
      },*/
    },
    /* {
      title: 'Access',
      dataIndex: 'type',
      key: 'type',
      filteredValue: filteredInfo,
      //['customer']
      onFilter: (value: any, record: any) => record.type.indexOf(value) === 0,
      //onFilter: (value: any, record: any) => record.type.includes(value),
    }, */
    {
      title: 'Partners',
      dataIndex: 'partners',
      key: 'partners',
      //filteredValue: filteredInfo,
      //['customer']
      //onFilter: (value: any, record: any) => record.type.indexOf(value) === 0,
      //onFilter: (value: any, record: any) => record.type.includes(value),
    },
    {
      title: 'Customers',
      dataIndex: 'customers',
      key: 'customers',
      //filteredValue: filteredInfo,
      //['customer']
      //onFilter: (value: any, record: any) => record.type.indexOf(value) === 0,
      //onFilter: (value: any, record: any) => record.type.includes(value),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      //sorter: true,
    },
  ];
  const clearAll = () => {
    setFilteredInfo([])
    setDropTitle([])
    console.log(filteredInfo)
  }

  const selectFilter = (element: any) => {
    clearAll()
    console.log('menu', element)
    const arrayFilter: any = []
    arrayFilter.push(element.key)
    setFilteredInfo(arrayFilter)
    setDropTitle(arrayFilter)
  }
  const searchFilter = (element: any) => {
    // clearAll()
    console.log(element)
    let textSearch = ''
    if (element.current.state.value !== undefined) {
      textSearch = element.current.state.value
    }
    const dataSearch = { name: textSearch }
    searchUsers(dataSearch)
  }
  const menu = (
    <Menu>
      <Menu.Item key='propel' onClick={selectFilter}>
        Propel
      </Menu.Item>
      <Menu.Item key="partner" onClick={selectFilter}>
        Partner
      </Menu.Item>
      <Menu.Item key='customer' onClick={selectFilter}>
        Customer
      </Menu.Item>
      <Menu.Item key={'clear'}>
        <Button className="btn-border-blue" onClick={clearAll}>Clear filters</Button>
      </Menu.Item>
    </Menu>
  );
  const setFormNewUser = () => {
    setVisible(true);
    setIsUpdate(false)
    const userFill = {
      username: '',
      email: '',
      full_name: '',
      password: ''
    }
    form.setFieldsValue(userFill)
  }

  const fillFormUpdate = (values: any) => {
    setVisible(true);
    setIsUpdate(true)
    console.log(values)
    const userFill = Object.assign({}, values)
    userFill.email = values.email
    userFill.partners = values.partners.map((tag: any) => tag._id)
    userFill.customers = values.customers.map((tag: any) => tag._id)
    //}
    setRoleSelected(values.role)
    form.setFieldsValue(userFill)
    setUserUpdate(values)
  }
  const onClose = () => {
    setVisible(false);
    clearForm();
  };

  const registerUserDatabase = (values: any) => {
    console.log('Register Values:', values);
    if (!isUpdate) {
      values.username = values.email
      datasets.postData(SERVER.REGISTER_USER(), values, datasets.getToken()).then(res => {
        searchUsers(param);
        showDrawer();
        message.success('User registered successfully');
      })
    } else {
      updateUserDatabase(values, userUpdate);
      //setIsUpdate(false);
      //showDrawer();
    }
  }

  const updateUserDatabase = (dataUser: any, userUpdate: any) => {
    console.log('Success Update:', dataUser, userUpdate);
    dataUser._id = userUpdate._id
    dataUser.username = dataUser.email
    console.log()
    datasets.putData(SERVER.UPDATE_USER(), dataUser, datasets.getToken()).then(res => {
      console.log('resUpd', res)
      searchUsers({});
      message.success('User Update successfully', 4);
      //onClose();
    })
  }

  const deleteUserDatabase = (user: any) => {
    console.log('eliminar usuario', user._id)
    datasets.deleteData(SERVER.DELETE_USER(user._id), datasets.getToken()).then(res => {
      listUsers();
      message.success('User Removed successfully', 4);
      onClose();
    })
  }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const selectRole = (element: any) => {
    console.log(element)
    setRoleSelected(element.target.value)
  }

  const changePassword = (values: any, userUpd: any) => {
    console.log('change Pass', values, userUpd)
    const userPass = {
      id: userUpd._id,
      password: values.password,
      passwordConfirm: values.passwordconfirm
    }
    postRequest(SERVER.RESET_PASSWORD(), userPass).then(async res => {
      if (!res.error) {
        message.success('Password changed successfully', 4);
      } else {
        message.error('Password has not changed', 4);
      }
    })
  }

  const showConfirmModal = (user: any) => {
    console.log('confirm', user);
    Modal.confirm({
      title: "Remove User",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to remove Asset?',
      onOk() {
        deleteUserDatabase(user);
      }
    });
  }

  const archiveUser = async (user: any) => {
    console.log('archive user ', user)
    const data = {
      id: user._id
    }
    await datasets.postData(SERVER.ARCHIVE_USER(), data, datasets.getToken()).then(res => {
      searchUsers({});
      console.log('archive user', res)
      //showDrawer();
      message.success(res.message);
    })
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <SideBar></SideBar>

      <Modal
        visible={visibleModal}
        title="Change your Password"
        okText="Save"
        cancelText="Cancel"
        onCancel={() => setVisibleModal(false)}
        onOk={() => {
          form2
            .validateFields()
            .then(values => {
              form.resetFields();
              setVisibleModal(false);
              changePassword(values, userUpdate);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form2}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input the title of collection!' }]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item name="passwordconfirm" label="Confirm Password" rules={[{ required: true, message: 'Please input the title of collection!' }]}>
            <Input type="password" />
          </Form.Item>
        </Form>
      </Modal>
      <Layout>
        <NavBar></NavBar>
        <Layout className="site-layout">
          <Content className="user table-general">
            <Row>
              <Col xs={24} lg={24}>
                <div className="card-white">
                  <div className="head-t">
                    <Row>
                      <Col xs={24} lg={18}>
                        <label>Type Access:</label>
                        <Dropdown overlay={menu} trigger={['click']}>
                          <Button className="btn-border-light-gray">
                            {dropTitle.length === 0 ? 'Choose a Type Access' : dropTitle[0]}<DownOutlined />
                          </Button>
                        </Dropdown>
                        <Input allowClear onPressEnter={() => searchFilter(inputSearch)} ref={inputSearch} placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 150 }} />
                        {/* <Button className="btn-blue" onClick={() => searchFilter(inputSearch)}>Search</Button> */}
                        <Checkbox onClick={() => { setShowArchived(!showArchived) }}>Archived</Checkbox>
                        <Button className="btn-blue" onClick={() => searchFilter(inputSearch)}>Search</Button>
                        
                      </Col>
                      <Col xs={24} lg={6} style={{ textAlign: 'right' }}>
                        {/* <Input placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 200 }} /> */}
                        <Button className="btn-green" onClick={setFormNewUser}>New User</Button>
                        <Drawer
                          className="drawer-form"
                          title={isUpdate ? <div style={{ textAlign: 'center' }}><div style={{ float: 'left' }}>{'Edit User'}</div>
                            <div style={{ float: 'right' }}>
                              {userUpdate.archived ? <Button style={{ textAlign: 'right' }} className="btn-border-red" onClick={() => archiveUser(userUpdate)}>
                                Unarchive
                      </Button> : <Button style={{ textAlign: 'right' }} className="btn-border-red" onClick={() => archiveUser(userUpdate)}>
                                Archive
                      </Button>}</div></div> : 'New User'}
                          placement="right"
                          closable={false}
                          onClose={onClose}
                          visible={visible}
                          width={500}
                          footer={
                            <div>
                              <div>
                                *Required Fields
                            </div>
                              <div
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                {isUpdate ?
                                  <Button style={{ marginRight: 8 }} className="btn-red" onClick={() => showConfirmModal(userUpdate)}>
                                    Delete User
                            </Button> : <></>
                                }
                                <Button style={{ marginRight: 8 }} className="btn-border-red" onClick={showDrawer}>
                                  Cancel
                            </Button>
                                <Button className="btn-green" onClick={() => {
                                  form.submit()
                                  setIsTouchEmail(false)
                                }}>
                                  Save
                            </Button>
                              </div>
                            </div>
                          }
                        >
                          <Form form={form} onFinish={registerUserDatabase} layout="vertical" hideRequiredMark initialValues={{
                            ['time_zone']: 'EST',
                            ['language']: 'english',
                            ['role']: 'administrator',
                            ['site_user_type']: 'Manager',
                          }}>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name='email' label="*Email:" hasFeedback validateTrigger={['onBlur']} rules={[{ required: true, message: 'Please enter an Email!' },
                                ({ getFieldValue, isFieldTouched }) => ({
                                  validator: async (_, value) => {
                                    console.log('user xd', value)
                                    await datasets.postData(SERVER.VALIDATE_EMAIL(), { email: value }, datasets.getToken()).then(res => {
                                      console.log('response Validation', res, !isTouchEmail, isUpdate)
                                      //console.log('devi xd', !value, res.valid, isUpdate)
                                      if (isUpdate && !isTouchEmail) {
                                        setIsTouchEmail(false)
                                        //message.success('Device Updated successfully');
                                        return Promise.resolve();
                                      } else {
                                        if (!value || res.valid) {
                                          return Promise.resolve();
                                        }
                                      }
                                      return Promise.reject('This email already exists, please choose another!');
                                    })
                                  },
                                })
                                ]}>
                                  <Input onChange={() => setIsTouchEmail(true)} placeholder="Please enter email" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name='password' label="*Password:" rules={[{ required: true, message: 'Please enter your Password!' }]} >
                                  <Input placeholder="Please enter password" type="password" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={8}>
                              <Col span={12}>
                                <Form.Item name='full_name' label="*Full Name:" rules={[{ required: true, message: 'Please enter your Name!' }]}>
                                  <Input placeholder="Please enter name" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name='time_zone' label="*TimeZone:" rules={[{ required: true, message: 'Please select your country!' }]} >
                                  <Select >
                                    <Option value="EST">EST</Option>
                                    <Option value="AKST">AKST</Option>
                                    <Option value="PST">PST</Option>
                                    <Option value="CST">CST</Option>
                                    <Option value="AST">AST</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name='language' label="*Language:" >
                                  <Select placeholder="Please choose">
                                    <Option value="english">English</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name='mobile_number' label="Phone:" >
                                  <Input placeholder="Please enter phone" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={24}>
                                <Form.Item name="is_admin" label={'Is Propel Administrator?'} valuePropName="checked" >
                                  <Switch />
                                </Form.Item>
                                {/* <Form.Item name="role" label={'*Pick a Role Access:'} >
                                  <Radio.Group onChange={selectRole}>
                                  {userProfile.role === 'administrator' || userProfile.role === 'propel'? <Radio value="administrator">Administrator</Radio>: <></> }
                                    <Radio value="partner">Channel Partner</Radio>
                                    <Radio value="customer">Customer</Radio>
                                  </Radio.Group>
                                </Form.Item> */}
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              {/* {roleSelected === 'propel' ?
                                <><Col span={12}>
                                  <Form.Item name='site_user_type' label="*Select Level User Propel:" rules={[{ required: true, message: 'Please pick a Level!' }]}>
                                    <Select placeholder="Please select">
                                      <Option value="Administrator">Administrator</Option>
                                      <Option value="Support">Support</Option>
                                    </Select>
                                  </Form.Item>
                                </Col></> : <></>} */}
                              {roleSelected === 'partner' ?
                                <>{/* <Col span={12}>
                                  <Form.Item name='site_user_type' label="*Select Level User Partner:" rules={[{ required: true, message: 'Please pick a Level!' }]}>
                                    <Select placeholder="Please select">
                                      <Option value="Administrator">Administrator</Option>
                                      <Option value="User">User</Option>
                                    </Select>
                                  </Form.Item>
                                </Col> */}
                                  <Col span={12}>
                                    <Form.Item name='partners' label="*Select Partners:" rules={[{ required: true, message: 'Please pick a Level!' }]}>
                                      <Select mode={'tags'} placeholder="Please select">
                                        {partners.listPartners.map((part: any) => <Option key={part._id} value={part._id}>{part.channel_partner_name}</Option>)}
                                      </Select>
                                    </Form.Item>
                                  </Col></> : <></>}
                              {roleSelected === 'customer' ?
                                <><Col span={12}>
                                  <Form.Item name='site_user_type' label="*Select Level User Customer:" rules={[{ required: true, message: 'Please pick a Level!' }]}>
                                    <Select placeholder="Please select">
                                      <Option value="Manager">Manager</Option>
                                      <Option value="Staff">Staff</Option>
                                    </Select>
                                  </Form.Item>
                                </Col><Col span={12}>
                                    <Form.Item name='customers' label="*Select Customers:" rules={[{ required: true, message: 'Please pick a Level!' }]}>
                                      <Select mode={'tags'} placeholder="Please select">
                                        {customers.listCustomers.map((customer: any) => <Option key={customer._id} value={customer._id}>{customer.customer_name}</Option>)}
                                      </Select>
                                    </Form.Item>
                                  </Col></> : <></>}
                            </Row>
                          </Form>
                          {/* <Alert message="*Required Fields" type="error" /> */}
                        </Drawer>
                      </Col>
                    </Row>
                  </div>

                  <Table dataSource={users.list.filter((user: any) => {
                    if (!user.archived || showArchived) {
                      return true;
                    }
                    return false; //skip
                  }).map((user: any) => {
                    // if (user.archived || showArchived) {
                    return ({
                      check: <Checkbox></Checkbox>,
                      key: user._id,
                      uid: user.user_id,
                      email: user.email,
                      full_name: user.full_name,
                      mobile_number: user.mobile_number,
                      type: user.role,
                      // partners: (user.role === 'propel' || user.role === 'administrator') ? 'N/A' : <>{user.partners.map((tag: any, index: any) => {
                      partners: user.is_admin ? 'N/A' : <>{user.partners.map((tag: any, index: any) => {
                        return (index < 2 ?
                          <Tag key={tag}>
                            {tag.channel_partner_name}
                          </Tag> : <></>
                        )
                      })}{user.partners.length > 2 ? <Popover content={user.partners.map((partner: any) => {
                        return (<p>{partner.channel_partner_name}</p>
                        )
                      })} title="Partners Asociated"><Button size="small">See {user.partners.length - 2}+</Button></Popover> : ''}</>,
                      //customers: (user.role === 'propel' || user.role === 'administrator') ? 'N/A' : <>{user.customers.map((tag: any, index: any) => {
                      customers: user.is_admin ? 'N/A' : <>{user.customers.map((tag: any, index: any) => {
                        return (index < 2 ?
                          <Tag key={tag}>
                            {tag.customer_name}
                          </Tag> : <></>
                        )
                      })}{user.customers.length > 2 ? <Popover content={user.customers.map((customer: any) => {
                        return (<p>{customer.customer_name}</p>
                        )
                      })} title="Customers Asociated"><Button size="small">See {user.customers.length - 2}+</Button></Popover> : ''}</>,
                      actions: <>
                        <Tooltip title="Edit"><Button className="btn-edit" onClick={() => fillFormUpdate(user)}><i className="mdi mdi-pencil"></i></Button></Tooltip>
                        <Tooltip title="Delete"><Button className="btn-delete" onClick={() => deleteUserDatabase(user)}><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
                        {/*    <Tooltip title="Change Password"><Button className="btn-password" onClick={() => {
                            setVisibleModal(true)
                            setUserUpdate(user)
                          }}><i className="mdi mdi-lock-question"></i></Button></Tooltip> */}
                      </>,
                    })
                    /* } else {
                       return 
                     } */

                  })} columns={columns} pagination={{ pageSize: 30 }} scroll={{ y: 'calc(100vh - 267px)' }} />
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
