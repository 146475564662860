import React, { useState, useEffect, useRef } from 'react';
import { Tag, Layout, Row, Col, Button, Drawer, Upload, Form, Tabs, Select, Input, Table, Space, Alert, Tooltip, Checkbox, Modal, Collapse, Dropdown, Menu, message, Image } from 'antd';
import { useCampusDispatch, useCampusState } from '../../hooks/campus';
import { useFloorPlanDispatch, useFloorPlanState } from '../../hooks/floorPlan';
import { useCPartnerDispatch, useCPartnerState } from '../../hooks/channelpartner';
import { useBuildingDispatch } from '../../hooks/building';
import { useLoginState } from '../../hooks/login';
import { useCustomerState } from '../../hooks/customer';
import { SideBar } from '../Shared/SideBar';
import { NavBar } from '../Shared/NavBar';
import { deleteRequest, getRequest } from '../../config/httpClient';
import * as datasets from "../../config/datasets";
import { ExclamationCircleOutlined, EditFilled, UploadOutlined, SearchOutlined, PlusCircleFilled, DownOutlined, EyeOutlined, DeleteFilled } from '@ant-design/icons';
import { DESCEND, ASCEND, dummyRequest } from '../../config/constants';
import { SERVER } from '../../config/serverConfig';
import { searchCustomers } from '../../store/actions';
import FloorPlanForm from './FloorPlanForm';
import BuildingForm from './BuildingForm';
const { Option } = Select;
const { Panel } = Collapse;

const { Header, Content, Sider } = Layout;
const { TabPane } = Tabs;
/* const menu = (
  <Menu>
    <Menu.Item key="0">
      <a href="https://www.antgroup.com">1st menu item</a>
    </Menu.Item>
    <Menu.Item key="1">
      <a href="https://www.aliyun.com">2nd menu item</a>
    </Menu.Item>
  </Menu>
); */

export const Campus = () => {

  const inputSearch = useRef(null);
  const [visibleFloor, setVisibleFloor] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleBuilding, setVisibleBuilding] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [isUpdateBuilding, setIsUpdateBuilding] = useState(false);
  const [isUpdateFloor, setIsUpdateFloor] = useState(false);
  const [campusUpdate, setCampusUpdate] = useState({});
  const [buildingUpdate, setBuildingUpdate] = useState({});
  //const [floorUpdate, setFloorUpdate] = useState({});
  const [isValidCampusName, setIsValidCampusName] = useState(true);
  //const [isValidBuildingName, setIsValidBuildingName] = useState(false);
  const [isValidFloorName, setIsValidFloorName] = useState(false);
  const campuslist = useCampusState();
  const floorPlanList = useFloorPlanState();
  const { searchCampus, saveCampus, updateCampus, deleteCampus, validateName, updateCampusWithFile } = useCampusDispatch();
  const { saveFloorPlan, updateFloorPlan, deleteFloorPlan, getDataFloorWirepas, getFloorPlanById } = useFloorPlanDispatch();
  const { deleteBuilding, updateBuilding } = useBuildingDispatch();
  const { listCPartners, searchPartners } = useCPartnerDispatch();
  const [buildings, setBuildings] = useState([]);
  const [campusId, setCampusId] = useState('');
  const [buildingId, setBuildingId] = useState('');
  const [floorPlanId, setFloorPlanId] = useState('');
  const [imageCampus, setImageCampus] = useState('');
  const [nfloors, setNFloors] = useState(0);
  /* 
  const [imageWirepas, setImageWirepas] = useState(''); */
  const [param, setParam] = useState({});
  const customers = useCustomerState();
  const partners = useCPartnerState();
  interface DataType {
    key: React.Key;
    floor_name: string;
    floor_number: string;
  }

  const generateButtonsCampus = (values: any) => (
    <div>
      {/* <Button
        className="btn-green-link"
        size="small"
        onClick={event => {
          // If you don't want click extra trigger collapse, you can prevent this:
          //console.log('build', building_id);
          setCampusId(values._id);
          setVisibleBuilding(true);
          event.stopPropagation();
        }}
      >Add Building
        <PlusCircleFilled />
      </Button> */}
      <EditFilled
        onClick={event => {
          fillForm(values);
          event.stopPropagation();
        }}
        style={{ color: '#1768AC' }}
      />
      <Button className="btn-red-transparent" style={{ marginLeft: '10px' }}>
        <DeleteFilled
          onClick={event => {
            //showConfirmModal(values._id);
            showConfirmModal(values);
          }}
        />
      </Button>
    </div>
  );



  const generateButtonsBuilding = (building: any, campusId: any) => (
    <div>
      {/* <Button
        className="btn-green-link"
        size="small"
        onClick={event => {
          // If you don't want click extra trigger collapse, you can prevent this:
          //console.log('build', building_id);
          setNewFloor(building._id);
          setVisibleFloor(true);
          event.stopPropagation();
        }}
      >Add Floor
        <PlusCircleFilled />
      </Button> */}

      <EditFilled
        onClick={event => {
          console.log(building);
          setCampusId(campusId);
          fillFormBuilding(building);
          event.stopPropagation();
        }}
        style={{ color: '#1768AC', marginLeft: '10px' }}
      />
      <Button className="btn-red-transparent" style={{ marginLeft: '10px' }}>
        <DeleteFilled
          onClick={event => {
            showConfirmModalBuilding(building);
          }}
        />
      </Button>
    </div>
  );

  const data = useLoginState();
  const userProfile = data.userProfile;

  const [form] = Form.useForm();
  const [formFloor] = Form.useForm();
  const [formBuilding] = Form.useForm();

  const columnsBuilding = [
    {
      title: 'Building',
      dataIndex: 'name',
      key: 'name',
      /* defaultSortOrder: ASCEND,
      sorter: (a: any, b: any) => {
        return a.user.toUpperCase().charCodeAt(0) - b.user.toUpperCase().charCodeAt(0)
      }, */
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
    }
  ];

  const columns = [
    {
      title: 'Floor Name',
      dataIndex: 'floor_name',
      key: 'floor_name',
    },
    {
      title: 'Floor Number',
      dataIndex: 'floor_number',
      key: 'floor_number',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
    }
  ];

  const onClose = () => {
    setVisible(false);
  }

  const onCloseBuilding = () => {
    setVisibleBuilding(false);
  }

  /* const onCloseFloor = () => {
    setVisibleFloor(false);
  } */

  const saveCampusDatabase = async (values: any) => {
    console.log('success', values);
    let newValues = {} as any;
    const dataForm: FormData = new FormData();
    if (!isUpdate) {
      if (!userProfile.is_admin) {
        //console.log('not admin', customers);
        if (customers.customerSelected._id) {
          newValues['customer_id'] = customers.customerSelected._id;
        }
      }
      if (isValidCampusName) {
        newValues['name'] = values.name;
        newValues['address'] = values.address;
        newValues['customer_id'] = values.customer_id;
        newValues['partner_id'] = values.partner_id;
        newValues['buildings'] = buildings;
        newValues['building_wirepas'] = values.building_wirepas;
        saveCampus(newValues);
        onClose();
        //searchCampus({});
        retrieveData();
        resetForms();
      } else {
        message.error('Campus Name is not valid for the customer');
      }

    } else {
      if (isValidCampusName) {
        values['_id'] = campusId;
        values['buildings'] = buildings;
        //values['image'] = {};
        console.log('VALUES UPDATE', values);

        dataForm.append('request', JSON.stringify(values));
        if (values.image !== undefined &&
          values.image.file !== undefined) {
          dataForm.append('file', values.image.file.originFileObj);
        }
        updateCampusWithFile(dataForm);
        onClose();
        //searchCampus({});
        retrieveData();
        resetForms();
      } else {
        message.error('Campus Name is not valid for the customer');
      }
    }
  }

  const resetForms = () => {
    form.resetFields();
    setBuildings([]);
  }

  const showDrawer = () => {
    setVisible(!visible);
  }

  const changeCampusName = async (e: any) => {
    const name = form.getFieldValue('name');
    let params = {} as any;
    params.name = name;
    if (campusId) {
      params['campus_id'] = campusId;
    }
    if (customers.customerSelected._id) {
      params['customer_id'] = customers.customerSelected._id;
    }
    const newPromise = new Promise((resolve, reject) => {
      const token: string = datasets.getToken()!;
      datasets.postData(SERVER.VALIDATE_NAME_CAMPUS(), params, token).then(result => {
        //console.log('VALIDATION', result);
        resolve(result.valid);
      });
    });
    const answer = await newPromise as boolean;
    setIsValidCampusName(answer);
    if (!answer) {
      message.error('Campus Name is not valid for the customer');
    }
  }
  const clearForm = () => {
    form.resetFields();
  }
  const setNewCampus = () => {
    clearForm();
    customers.listCustomers.length ? form.setFieldsValue({ customer_id: customers.listCustomers[0]._id }) : console.log('no customers founded')
    setIsUpdate(false);
    showDrawer();
    setImageCampus('')
  }

  const setNewFloor = (buildingId: any) => {
    setBuildingId(buildingId);
    setIsUpdateFloor(false);
    //showDrawerFloor();xc
    setVisibleFloor(true);
    resetFormsFloor();
    //setImageWirepas('');
  }

  const resetFormsFloor = () => {
    formFloor.resetFields();
  }

  const setNewBuilding = () => {
    formBuilding.resetFields();
  }

  const showConfirmModal = (values: any) => {
    console.log('confirm', values);
    Modal.confirm({
      title: 'Remove Campus',
      icon: <ExclamationCircleOutlined />,
      //content: 'Are you sure to remove Campus '+ values.name +'?',
      content: 'Delete Campus ' + values.name + '?',
      okText: 'Delete',
      onOk() {
        deleteCampusDatabase(values._id);
      }
    });
  }

  const showConfirmModalBuilding = (values: any) => {
    console.log('build id', values);
    Modal.confirm({
      title: 'Remove Building',
      icon: <ExclamationCircleOutlined />,
      //content: 'Are you sure to remove Building?',
      content: 'Delete Building ' + values.name + '?',
      okText: 'Delete',
      onOk() {
        deleteBuildingDatabase(values._id);
      }
    })
  }

  const showConfirmModalFloor = (values: any) => {
    console.log('confirm floor', values);
    Modal.confirm({
      title: 'Remove Floor Plan',
      icon: <ExclamationCircleOutlined />,
      // content: 'Are you sure to remove Floor Plan?',
      content: 'Delete Floor ' + values.floor_name + '?',
      okText: 'Delete',
      onOk() {
        deleteFloorDatabase(values._id);
      }
    })
  }

  const deleteFloorDatabase = (id: any) => {
    deleteFloorPlan(id);
    //searchCampus({});
    retrieveData();
  }

  const fillForm = (values: any) => {
    console.log('form campus values', values)
    setVisible(true);
    setIsUpdate(true);
    //console.log('asdads', values);
    const campusFill = Object.assign({}, values);
    setCampusId(values._id);
    setBuildings(values.buildings);
    console.log('BUILDINGS', values.buildings);
    form.setFieldsValue(campusFill);
    setCampusUpdate(values);
    if (campusFill.image) {
      setImageCampus('data:image/png;base64,' + campusFill.image);
    }
  }

  const fillFormBuilding = (values: any) => {
    setVisibleBuilding(true);
    setIsUpdateBuilding(true);
    const buildingFill = Object.assign({}, values);
    //formBuilding.setFieldsValue(buildingFill);
    setBuildingId(values._id);
    setBuildingUpdate(values);
  }

  const fillFormFloor = async (values: any) => {
    console.log('fill form floor', values)
    setVisibleFloor(true);
    setIsUpdateFloor(true);
    //getFloorPlanById(values._id);
    setFloorPlanId(values._id);
    setBuildingId(values.building_id);

  }

  const deleteCampusDatabase = async (id: any) => {
    const token: string = datasets.getToken()!;
    await deleteRequest(SERVER.DELETE_CAMPUS(id), token).then(data => {
      if (data.result === 0) {
        //searchCampus({});
        retrieveData();
      } else {
        message.error('Campus cannot be removed, it has associated Buildings');
      }
    });
  }

  const deleteBuildingDatabase = async (id: any) => {
    const token: string = datasets.getToken()!;
    await deleteRequest(SERVER.DELETE_BUILDING(id), token).then(data => {
      if (data.result === 0) {
        //searchCampus({});
        retrieveData();
      } else {
        message.error('Building cannot be removed, it has associated Floor Plans')
      }

    })
  }

  const deleteBuildingArray = (data: any) => {
    let arrayBuilding = [...buildings] as any;
    arrayBuilding = arrayBuilding.filter((elem: any) => elem.name !== data.name);
    setBuildings(arrayBuilding);
  }

  const addBuilding = () => {
    let arrayBuilding = [...buildings] as any;
    const name = form.getFieldValue('buildingName');
    if (name) {
      let valid = true;
      for (let build of arrayBuilding) {
        if (build.name === name) {
          valid = false;
        }
      }
      if (valid) {
        arrayBuilding.push(
          {
            name: name
          }
        )
      } else {
        message.error('Building Name is no valid');
      }
      setBuildings(arrayBuilding);
      form.setFieldsValue({
        buildingName: ''
      });
    }
  }

  const retrieveData = () => {
    console.log('retrieve Data', customers.customerSelected, userProfile.is_admin)
    if (userProfile.is_admin) {
      searchCampus({ customer_id: customers.customerSelected._id ? customers.customerSelected._id : '' });
    } else {
      if (!customers.customerSelected._id) {
        searchCampus({ customer_id: '123431242134' });
      } else {
        searchCampus({ customer_id: customers.customerSelected._id });
      }
    }
  }

  useEffect(() => {
    //retrieveData();
    console.log(campuslist);
    searchCustomers({})
    searchPartners(param)
  }, []);

  useEffect(() => {
    console.log('change customer selected', userProfile);
    if (userProfile.email !== '') {
      retrieveData();
    }

  }, [customers.customerSelected]);

  useEffect(() => {
    console.log('se obtuvo me', userProfile);
    if (userProfile.email !== '') {
      retrieveData();
    }

  }, [userProfile]);

  return (
    <Layout style={{ height: '100vh' }}>
      <SideBar></SideBar>
      <Layout>
        <NavBar></NavBar>
        <Layout className="site-layout">
          <Content className="user">
            <Row>
              <Col xs={24} lg={24}>
                <div className="card-white">
                  <div className="head-t">
                    <Row>
                      <Col xs={24} lg={16}>
                        {/* <Dropdown overlay={menu} trigger={['click']}>
                        <Button className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                          <EyeOutlined /> - Customer 01- <DownOutlined />
                        </Button>
                        </Dropdown> */}
                      </Col>
                      <Col xs={24} lg={8} style={{ textAlign: 'right' }}>
                        <Button className="btn-green" onClick={setNewCampus}>New Campus</Button>
                        <Drawer
                          className="drawer-form custumer-form"
                          title={isUpdate ? 'Edit Campus' : 'New Campus'}
                          placement="right"
                          closable={false}
                          onClose={onClose}
                          visible={visible}
                          width={315}
                          footer={
                            <div
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <div>
                              </div>
                              <Button style={{ marginRight: 8 }} className="btn-border-red" onClick={showDrawer}>
                                Cancel
                              </Button>
                              <Button className="btn-green" onClick={() => form.submit()}>
                                Save
                              </Button>
                            </div>
                          }
                        >
                          <Form form={form} onFinish={saveCampusDatabase} layout="vertical" hideRequiredMark>
                            <Row gutter={16}>
                              <Col span={24}>
                                <Form.Item name='name' label="*Campus Name:" rules={[{ required: true, message: 'Please enter Campus Name!' }]}>
                                  <Input placeholder="Please enter Campus Name" onBlur={changeCampusName} />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={24}>
                                <Form.Item name='address' label="Address:">
                                  <Input placeholder="Please enter Address" />
                                </Form.Item>
                              </Col>
                            </Row>
                            {
                              partners.listPartners.length && (userProfile.is_admin) ?
                                <Row gutter={16}>
                                  <Col span={24}>
                                    <Form.Item name='partner_id' label="*Partner:" >
                                      <Select
                                        placeholder="Please select Channel Partner"
                                        allowClear
                                      >
                                        {partners.listPartners.map((part: any) => <Option value={part._id}>{part.channel_partner_name}</Option>)}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Row> : <></>
                            }
                            {
                              customers.listCustomers.length && (userProfile.is_admin) ?
                                <Row gutter={16}>
                                  <Col span={24}>
                                    <Form.Item name="customer_id" label="*Customer Asociated" rules={[{ required: true, message: 'Please choose Customer!' }]} className="select-bg">
                                      <Select
                                        placeholder="Please select a Customer"
                                        defaultValue={!userProfile.is_admin ? customers.customerSelected._id : ''}
                                        disabled={!userProfile.is_admin}
                                      >
                                        {customers.listCustomers.map((customer: any) => <Option value={customer._id} label={customer.customer_name}>{customer.customer_name}</Option>)}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Row> :
                                <></>
                            }
                            <Row gutter={24}>
                              <Col span={12}>
                                <b>Point A</b>
                              </Col>
                            </Row>
                            <Row gutter={24}>
                              <Col span={12}>
                                <Form.Item name='latitude_a' label="Latitude:">
                                  <Input placeholder="Please enter Latitude" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name='longitude_a' label="Longitude:">
                                  <Input placeholder="Please enter Longitude" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={24}>
                              <Col span={12}>
                                <b>Point B</b>
                              </Col>
                            </Row>
                            <Row gutter={24}>
                              <Col span={12}>
                                <Form.Item name='latitude_b' label="Latitude:">
                                  <Input placeholder="Please enter Latitude" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name='longitude_b' label="Longitude:">
                                  <Input placeholder="Please enter Longitude" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <Form.Item name='image' label="Image:" >
                                  <Upload customRequest={dummyRequest} showUploadList={{ showRemoveIcon: false }} maxCount={1}>
                                    <Button className="btn-gray" icon={<UploadOutlined />}>Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                            </Row>
                            {imageCampus !== '' ? <Row>
                              <Col span={24}>
                                <div className="campus-img"><img src={imageCampus} width='250px' height='260px' />
                                </div>
                              </Col>
                            </Row> : <></>}

                          </Form>
                          <div className="fields">*Required Fields</div>
                        </Drawer>

                        <BuildingForm setVisibleBuilding={setVisibleBuilding} visibleBuilding={visibleBuilding} customers={customers} campusId={campusId}
                          retrieveData={retrieveData} buildingId={buildingId}
                          setBuildingId={setBuildingId} formBuilding={formBuilding} />

                        <FloorPlanForm setVisibleFloor={setVisibleFloor} visibleFloor={visibleFloor} customers={customers} buildingId={buildingId} retrieveData={retrieveData}
                          floorPlanId={floorPlanId} setFloorPlanId={setFloorPlanId} isUpdateFloor={isUpdateFloor} setIsUpdateFloor={setIsUpdateFloor} formFloor={formFloor} nfloors={nfloors} />
                      </Col>
                    </Row>
                  </div>

                  <div className="campus-body">
                    <Space direction="vertical" style={{ width: '100%' }} size='large'>
                      {campuslist.listCampus.map((campus: any) => {
                        return <Collapse className="collaps-head" expandIconPosition="right">
                          <Panel className="collaps-head-campus" key={campus.name} header={campus.name}
                            extra={generateButtonsCampus(campus)}>
                            {campus.buildings.map((building: any) => {
                              return <Collapse style={{ borderBottom: '1px solid #C3D8E9' }} className="collapse-campus" bordered={false} expandIconPosition="left">
                                <Panel  key={building.name} header={building.name}
                                  extra={generateButtonsBuilding(building, campus._id)}>
                                  <Table className="table-small" dataSource={building.floor_plans.map((floor: any) => {
                                    return ({
                                      check: <Checkbox></Checkbox>,
                                      key: floor._id,
                                      floor_name: floor.floor_name,
                                      floor_number: floor.floor_number,
                                      actions: <>
                                        <Tooltip title="Edit"><Button className="btn-edit" onClick={() => fillFormFloor(floor)}><i className="mdi mdi-pencil"></i></Button></Tooltip>
                                        <Tooltip title="Delete"><Button className="btn-delete" onClick={() => showConfirmModalFloor(floor)}><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
                                      </>,
                                    })
                                  })} columns={columns} pagination={{ pageSize: 30 }} scroll={{ y: 'calc(100vh - 267px)' }} />
                                  <div style={{ paddingBottom: '6px'}}><Button
                                    className="btn-green-link"
                                    size="small"
                                    onClick={event => {
                                      // If you don't want click extra trigger collapse, you can prevent this:
                                      //console.log('build', building_id);
                                      setNewFloor(building._id);
                                      setVisibleFloor(true);
                                      event.stopPropagation();
                                      setNFloors(building.floor_plans.length)
                                    }}
                                  >Add Floor
                                    <PlusCircleFilled />
                                  </Button></div>
                                </Panel>
                              </Collapse>
                            })}
                            <div style={{ padding: '6px 45px' }}><Button
                              className="btn-green-link"
                              size="small"
                              onClick={event => {
                                // If you don't want click extra trigger collapse, you can prevent this:
                                //console.log('build', building_id);
                                setNewBuilding()
                                setCampusId(campus._id);
                                setVisibleBuilding(true);
                                event.stopPropagation();
                              }}
                            >Add Building
                              <PlusCircleFilled />
                            </Button></div>
                          </Panel>
                        </Collapse>
                      })}

                    </Space>
                  </div>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout >
  );
}
