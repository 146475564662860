import { useDispatch, useSelector } from 'react-redux';

import { loadAssetTypes, searchAssetTypes, getAssetTypesArray } from '../store/actions';
import { RootState } from '../store/reducers';

export const useAssetTypeState = () => {
  return useSelector((state: RootState) => state.assetTypes);
};

export const useAssetTypeDispatch = () => {
  const dispatch = useDispatch();
  return {
    listAssetTypes: () => dispatch(loadAssetTypes()),
    searchAssetTypes: (data: any) => dispatch(searchAssetTypes(data)),
    getAssetTypesArray: (data: any) => dispatch(getAssetTypesArray(data)),
  };
};