import React, { useEffect, useState } from 'react';
import { Drawer, Button, Form, Row, Col, Input, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useBuildingDispatch, useBuildingState } from '../../hooks/building';
import * as datasets from "../../config/datasets";
import { SERVER } from '../../config/serverConfig';
import MapDraw from "../Map/MapDraw"

export default ({ setVisibleBuilding, visibleBuilding, customers,
  campusId, retrieveData, buildingId, setBuildingId, formBuilding }: any) => {

  
  
  //const [buildingId, setBuildingId] = useState('');
  const { addBuildingWithFile, updateBuildingWithFile, getBuildingById } = useBuildingDispatch();
  const [isValidBuildingName, setIsValidBuildingName] = useState(false);
  const [imageBuilding, setImageBuilding] = useState('');
  const [buildingUpdate, setBuildingUpdate] = useState({});
  const buildingData = useBuildingState();

  console.log('buidling', buildingId);
  console.log('campus', campusId);


  useEffect(() => {
    console.log('misdatos', buildingData)
    if (!buildingData.objectBuilding) {
      message.error('Sorry, your update could not be saved due to a communication issue with the server.  Please try again later.')  
    }
  }, [buildingData])
  //message.error('')

  const onCloseBuilding = () => {
    setVisibleBuilding(false);
    setBuildingId('');
    setImageBuilding('');
  }
  /* const showDrawerBuilding = () => {
    setVisibleBuilding(!visibleBuilding);
  } */

  const saveBuildingDatabase = (values: any) => {
    console.log('sfsdfsd', buildingId);
    let data = {} as any;
    const dataForm: FormData = new FormData();

    if (buildingId !== undefined && buildingId !== '') {
      data._id = buildingId;
      data.campus_id = campusId;
      data.name = values.name;
      data.address = values.address;
      data.building_wirepas = values.building_wirepas;
      console.log('updating building');
      dataForm.append('request', JSON.stringify(data));
      if (values.image !== undefined &&
        values.image.file !== undefined) {
        dataForm.append('file', values.image.file.originFileObj);
      }
      //updateBuilding(values);
      updateBuildingWithFile(dataForm);
      onCloseBuilding();
      resetFormsBuilding();
      retrieveData();
      setBuildingId('');
      setImageBuilding('');
    } else {
      console.log('Success:', values);
      data.campus_id = campusId;
      data.name = values.name;
      data.address = values.address;
      data.building_wirepas = values.building_wirepas;
      dataForm.append('request', JSON.stringify(data));
      if (values.image !== undefined &&
        values.image.file !== undefined) {
        dataForm.append('file', values.image.file.originFileObj);
      }
      addBuildingWithFile(dataForm);
      onCloseBuilding();
      resetFormsBuilding();
      retrieveData();
      setBuildingId('');
      setImageBuilding('');
    }
  }

  const resetFormsBuilding = () => {
    formBuilding.resetFields();
  }

  const changeBuildingName = async (e: any) => {
    const name = formBuilding.getFieldValue('name');
    let params = {} as any;
    params.name = name;
    if (campusId) {
      params['campus_id'] = campusId;
    }
    if (buildingId) {
      params['building_id'] = buildingId;
    }
    if (customers.customerSelected._id) {
      params['customer_id'] = customers.customerSelected._id;
    }
    //console.log('PARAMS', params);
    const newPromise = new Promise((resolve, reject) => {
      const token: string = datasets.getToken()!;
      datasets.postData(SERVER.VALIDATE_NAME_BUILDING(), params, token).then(result => {
        //console.log('VALIDATION', result);
        resolve(result.valid);
      });
    });
    const answer = await newPromise as boolean;
    setIsValidBuildingName(answer);
    if (!answer) {
      message.error('Building Name is not valid for the customer');
    }
  }

  useEffect(() => {
    console.log('loading data', buildingId);
    if (buildingId !== undefined && buildingId !== '') {
      //const token: string = datasets.getToken()!;
      datasets.getData(SERVER.GET_BUILDING_BY_ID(buildingId), datasets.getToken()).then(data => {
        const buildingFill = Object.assign({}, data);
        formBuilding.setFieldsValue(buildingFill);
        setBuildingUpdate(data);
        if (data.image) {
          setImageBuilding('data:image/png;base64,' + data.image);
        }
      })
    }
  }, [buildingId]);

  return <>
    <Drawer
      className="drawer-form custumer-form"
      title={buildingId ? 'Edit Building' : 'New Building'}
      placement='right'
      closable={false}
      onClose={onCloseBuilding}
      visible={visibleBuilding}
      width={450}
      footer={
        <div
          style={{ textAlign: 'center' }}>
          <div></div>
          <Button style={{ marginRight: 8 }} className="btn-border-red" onClick={onCloseBuilding}>
            Cancel
                              </Button>
          <Button className="btn-green" onClick={() => formBuilding.submit()}>Save</Button>
        </div>
      }>
      <Form form={formBuilding} onFinish={saveBuildingDatabase} layout="vertical" hideRequiredMark>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name='name' label='*Building Name:'>
              <Input placeholder='Please enter Building Name' onBlur={changeBuildingName} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name='address' label='Address:'>
              <Input placeholder='Please enter Address' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {/* <Form.Item name='building_wirepas' label="Building Wirepas:"> */}
            <Form.Item name='building_wirepas' label="Map Identifier:">
              <Input disabled placeholder="Map Identifier from Wirepas" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item name='image' label="Image:" >
              <Upload showUploadList={{ showRemoveIcon: false }} maxCount={1}>
                <Button className="btn-gray" icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        {imageBuilding !== ''?  <Row>
          <Col span={24}>
            <div className="campus-img"><img src={imageBuilding} width='280px' height='260px' />
            </div>
          </Col>
        </Row> : <></>}
        <Row>
        <div className="map card-white">
          {/* <MapDraw /> */}
        </div>
          
        </Row>
      </Form>
    </Drawer>
  </>
}