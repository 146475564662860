import React, { useEffect, useState } from 'react';
import { Drawer, Button, Form, Row, Col, Input, InputNumber, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useFloorPlanDispatch, useFloorPlanState } from '../../hooks/floorPlan';
import { getRequest } from '../../config/httpClient';
import * as datasets from "../../config/datasets";
import { SERVER } from '../../config/serverConfig';

export default ({ setVisibleFloor, visibleFloor, customers, buildingId,
  retrieveData, floorPlanId, setFloorPlanId, isUpdateFloor, setIsUpdateFlor, formFloor, nfloors }: any) => {
  //const [visible, setVisible] = useState(false);
  // const [isUpdateFloor, setIsUpdateFloor] = useState(false);
  const [imageWirepas, setImageWirepas] = useState('');
  // const [formFloor] = Form.useForm();
  //const [buildingId, setBuildingId] = useState('');
  //const [floorPlanId, setFloorPlanId] = useState('');
  const [floorUpdate, setFloorUpdate] = useState({});
  const [isValidFloorName, setIsValidFloorName] = useState(false);
  const { saveFloorPlan, updateFloorPlan, deleteFloorPlan, getDataFloorWirepas, getFloorPlanById } = useFloorPlanDispatch();
  const floorData = useFloorPlanState();


  const onCloseFloor = () => {
    setVisibleFloor(false);
  }
  //setVisibleFloor() {}
  useEffect(() => {
    console.log('data flooor ', floorData)
    if (floorData.objectFloorPlan) {
      if (floorData.objectFloorPlan.level) {
        message.error({ content: 'Sorry, Floor could not be saved due to a communication issue with the server. Please try again later.',  style: {
          marginTop: '20vh',
        },})  
      }
    } else {
      message.error({content: 'Sorry, Floor could not be deleted due to a communication issue with the server. Please try again later.',
        style: {
          marginTop: '20vh',
        },
      },  )
    }
  }, [floorData])

  const saveFloorDatabase = (values: any) => {
    console.log('success', values, isUpdateFloor);
    let newValues = {} as any;
    const dataForm: FormData = new FormData();
    if (!isUpdateFloor) {
      newValues['floor_name'] = values.floor_name;
      newValues['floor_number'] = values.floor_number;
      newValues['building_id'] = buildingId;
      newValues['floor_plan_wirepas'] = values.floor_plan_wirepas;
      newValues['map_identifier'] = values.map_identifier;
      newValues['latitude_a'] = values.latitude_a;
      newValues['longitude_a'] = values.longitude_a
      newValues['latitude_b'] = values.latitude_b;
      newValues['longitude_b'] = values.longitude_b;
      newValues['image_wirepas'] = imageWirepas;
      dataForm.append('request', JSON.stringify(newValues));
      if (values.image !== undefined) {
        dataForm.append('image', values.image.file.originFileObj);
      }
      saveFloorPlan(newValues);
      onCloseFloor();
      resetFormsFloor();
      retrieveData();
    } else {
      newValues['_id'] = floorPlanId;
      newValues['floor_name'] = values.floor_name;
      newValues['floor_number'] = values.floor_number;
      newValues['building_id'] = buildingId;
      newValues['floor_plan_wirepas'] = values.floor_plan_wirepas;
      newValues['map_identifier'] = values.map_identifier;
      newValues['latitude_a'] = values.latitude_a;
      newValues['longitude_a'] = values.longitude_a
      newValues['latitude_b'] = values.latitude_b;
      newValues['longitude_b'] = values.longitude_b;
      newValues['image_wirepas'] = imageWirepas;
      dataForm.append('request', JSON.stringify(newValues));
      if (values.image !== undefined && values.image !== '') {
        dataForm.append('image', values.image.file.originFileObj);
      }
      updateFloorPlan(newValues);
      onCloseFloor();
      resetFormsFloor();
      retrieveData();
      setFloorPlanId('');
    }
  }

  const resetFormsFloor = () => {
    formFloor.resetFields();
  }
  const changeFloorPlanName = async (e: any) => {
    const name = formFloor.getFieldValue('floor_name');
    let params = {} as any;
    params.name = name;
    console.log('BUILDING ID', buildingId);
    if (buildingId) {
      params['building_id'] = buildingId;
    } else {
      message.error('Building not selected');
      return;
    }
    if (floorPlanId) {
      params['floor_plan_id'] = floorPlanId;
    }
    if (customers.customerSelected._id) {
      params['customer_id'] = customers.customerSelected._id;
    }
    //console.log('FLOOR', params);
    const newPromise = new Promise((resolve, reject) => {
      const token: string = datasets.getToken()!;
      datasets.postData(SERVER.VALIDATE_NAME_FLOOR_PLAN(), params, token).then(result => {
        resolve(result.valid);
      });
    });
    const answer = await newPromise as boolean;
    setIsValidFloorName(answer);
    if (!answer) {
      message.error('Floor name is not valid for the building');
    }
  }

  const loadDataWirepas = async () => {
    let params = {} as any;
    //console.log('BUILD', buildingId);
    const mapIdentifier = formFloor.getFieldValue('map_identifier');

    if (mapIdentifier) {
      //console.log('map ide', mapIdentifier);
      const token: string = datasets.getToken()!;
      params['building_id'] = buildingId;
      params['map_identifier'] = mapIdentifier;
      const newPromise = new Promise((resolve, reject) => {
        datasets.postData(SERVER.GET_FLOOR_DATA_FROM_WIREPAS(), params, token).then(result => {
          //console.log('VALIDATION', result);
          resolve(result);
        });
      });
      const answer = await newPromise as any;
      formFloor.setFieldsValue({
        longitude_a: answer.longitude_a,
        latitude_a: answer.latitude_a,
        longitude_b: answer.longitude_b,
        latitude_b: answer.latitude_b
      });
      await getRequest(SERVER.GET_FLOORPLAN_IMAGE_PARAM(answer.image_id), token).then(async result => {
        //console.log('Image Floor Plan', result)
        if (result.message === 'OK') {
          setImageWirepas('data:image/png;base64,' + result.result.image_base64)
        }
      })
    } else {
      message.error('Please into Map Identifier value');
    }
  }

  const showDrawerFloor = () => {
    setVisibleFloor(false);
  }

  useEffect(() => {
    if (floorPlanId !== undefined && floorPlanId !== '') {
      const token: string = datasets.getToken()!;
      datasets.getData(SERVER.FLOOR_PLAN_BY_ID(floorPlanId), token).then((data: any) => {
        const floorFill = Object.assign({}, data);
        floorFill['floor_number'] = parseInt(data['floor_number'])

        console.log('data to fill floor ', floorFill)
        formFloor.setFieldsValue(floorFill);
        setFloorUpdate(data);
        if (data.image_wirepas) {
          setImageWirepas(data.image_wirepas)
        }
      });
    }

  }, [floorPlanId])

  return <>
    <Drawer
      className="drawer-form custumer-form"
      title={isUpdateFloor ? 'Edit Floor Plan' : 'New Floor Plan'}
      placement="right"
      closable={false}
      onClose={onCloseFloor}
      visible={visibleFloor}
      width={480}
      footer={
        <div
          style={{ textAlign: 'center' }}>
          <div>
          </div>
          <Button style={{ marginRight: 8 }} className="btn-border-red" onClick={showDrawerFloor}>
            Cancel
          </Button>
          <Button className="btn-green" onClick={() => formFloor.submit()}>
            Save
          </Button>
        </div>
      }
    >
      <Form form={formFloor} onFinish={saveFloorDatabase} layout="vertical" hideRequiredMark>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name='floor_name' label="*Floor Name:" rules={[{ required: true, message: 'Please enter Floor Name!' }]}>
              <Input placeholder="Please enter Floor Name" onBlur={changeFloorPlanName} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='floor_number' label="*Floor Number:" rules={[{ required: true, message: 'Please enter Floor Number!' }, { type: 'number', min: nfloors+1, max: 99, message: 'This floor number already exists' }]}>
              <InputNumber placeholder="Please enter Floor Number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          {/* <Col span={12}>
                                <Form.Item name='floor_plan_wirepas' label="Floor Plan ID Wirepas:">
                                  <Input placeholder="Please enter Floor Plan ID Wirepas" />
                                </Form.Item>
                              </Col> */}
          <Col span={12}>
            <Form.Item name='map_identifier' label="Map Identifier:">
              <Input disabled placeholder="Map Identifier from Wirepas" />
            </Form.Item>
          </Col>
          {/* <Col span={4}>
            <Form.Item name='' label="">
              <Button className="btn-blue" style={{ marginTop: '20px' }}
                onClick={() => loadDataWirepas()}>Upload</Button>
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <b>Point A</b>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name='latitude_a' label="Latitude:">
              <Input placeholder="Please enter Latitude" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='longitude_a' label="Longitude:">
              <Input placeholder="Please enter Longitude" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <b>Point B</b>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name='latitude_b' label="Latitude:">
              <Input placeholder="Please enter Latitude" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='longitude_b' label="Longitude:">
              <Input placeholder="Please enter Longitude" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item name='image' label="Image:" valuePropName="defaultFileList">
              <Upload showUploadList={{ showRemoveIcon: false }}>
                <Button className="btn-gray" icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        {imageWirepas !== ''? <Row>
          <Col span={24}>
            <div className="campus-img"><img src={imageWirepas} width='410px' height='300px' />
            </div>
          </Col>
        </Row> : <></>}
      </Form>
    </Drawer>
  </>
}