export const SHOW_CHANNELPARTNERS = 'SHOW_CPARTNERS';
export const SEARCH_PARTNERS = 'SEARCH_PARTNERS';

export interface ChannelPartnerState {
  listPartners: any[],
  pagination: any,
}

interface ReplaceCPartnerAction {
  type: typeof SHOW_CHANNELPARTNERS,
  data: ChannelPartnerState,
}

interface ReplaceSearchPartners {
  type: typeof SEARCH_PARTNERS,
  data: ChannelPartnerState,
  pagination: {
    total: any,
    page: any
  }
}

export type ChannelPartnerType = ReplaceCPartnerAction |
  ReplaceSearchPartners