import { ChannelPartnerState, SHOW_CHANNELPARTNERS, ChannelPartnerType, 
        SEARCH_PARTNERS } from '../types';

const initialState = {
    listPartners: [],
    pagination: {}
}
export const cPartnerReducer = (state: ChannelPartnerState = initialState, action: ChannelPartnerType) => {
    switch (action.type) {
        case SHOW_CHANNELPARTNERS:
            return Object.assign({}, state, {listPartners: action.data});
        case SEARCH_PARTNERS:
            return Object.assign({}, state, {listPartners: action.data, pagination: action.pagination});
        default:
            return state
    }
}