import { Action } from 'redux'
;
import { ThunkAction } from 'redux-thunk';

import { TestActionTypes, SHOW_DEVICETYPES, SEARCH_DEVICETYPES, DevicetypType } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from "../../config/datasets";

export const listDeviceTypes = (listDevTyp: any) => {
  return {
    type: SHOW_DEVICETYPES,
    data: listDevTyp,
  }
}
export const searchDevTypes = (listDeviceTypes: any) => {
  return {
    type: SEARCH_DEVICETYPES,
    data: listDeviceTypes.elements,
    pagination: { 
      total: listDeviceTypes.total,
      page: listDeviceTypes.page
    } 
  }
}

export const loadDeviceTypes = (): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    //console.log('token', token);
    const response = await getRequest(SERVER.GET_DEVICETYPES(), token);
    //console.log('device Types', response)
    dispatch(listDeviceTypes(response));
  }
}
