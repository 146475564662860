import React, { useState, useEffect, useRef } from 'react';
import { Modal, Divider, message, Layout, Menu, Row, Col, Input, Dropdown, Button, Table, Checkbox, Tooltip, Form, Select, Drawer, Switch, Upload, Alert, Tag, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { SideBar } from '../Shared/SideBar'
import { NavBar } from '../Shared/NavBar'
import { AudioOutlined, DownOutlined, SearchOutlined, UploadOutlined, ExclamationCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import { useDeviceDispatch, useDeviceState } from '../../hooks/device';
import { useAssetState, useAssetDispatch } from '../../hooks/asset';
import { useCustomerState } from '../../hooks/customer';
import * as datasets from "../../config/datasets";
import { DESCEND, ASCEND } from '../../config/constants';
import { SERVER } from '../../config/serverConfig';
import { useLoginDispatch, useLoginState } from '../../hooks/login';
import { searchDevice, searchPartners } from '../../store/actions';
const { Header, Content, Sider } = Layout;
const { Search } = Input;
const { Option } = Select;

const colIdentifiers = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Value',
    dataIndex: 'content',
  },
];


const columns01 = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 100,
  },
  {
    title: 'Latest Value',
    dataIndex: 'content',
  },
];

const columns02 = [
  {
    title: 'Name',
    dataIndex: 'name',

  },
  /*{
    title: 'Type',
    dataIndex: 'type',
  },*/
  {
    title: 'Link',
    dataIndex: 'link',
  },
];

const dataSource = [
  {
    device: <img src="/Images/icon-001.svg" alt="" width="25px" />,
    model: 'lorem',
    hw: 'lorem',
    sw: 'lorem',
    script: 'lorem',
    serial: 'lorem',
    esn: 'lorem',
    imei: 'lorem',
    mac: 'lorem',
    sms: 'lorem',
    gps: 'lorem',
    ble: 'lorem',
    hours: 'lorem',
    miles: 'lorem',
    power: 'lorem',
    document: 'lorem',
    guide: 'lorem',
    image: 'lorem',
  },
];

const onFilter2 = (value: any, record: any) => { return record.devicetype.indexOf(value) === 0 }

export const Devices = () => {
  const { searchAssets, saveAsset, updateAsset, deleteAsset, searchAssetIcons } = useAssetDispatch();
  const { searchDevices, setListDevices } = useDeviceDispatch();
  const devices = useDeviceState()
  const assets = useAssetState()
  const [isUpdate, setIsUpdate] = useState(false);
  const [userUpdate, setUserUpdate] = useState({});
  const [form] = Form.useForm();
  const [filteredInfo, setFilteredInfo] = useState({});
  const [dropTitle, setDropTitle] = useState([]);
  const [state, setstate] = useState(false)
  const [isTouchName, setIsTouchName] = useState(false)
  const [nameValid, setNameValid] = useState<any>({ message: null, valid: false, status: "" })
  const [devTypeSelected, setDevTypeSelected] = useState<any>({ serial_number: false, esn: false, imei: false, mac_address: false, sms_number: false, gps: false, ble: false, temperature: false, hours_of_use: false, miles_of_use: false, spec_document: '', installation_guide: '', image_1: '', image_2: '', image_3: '', image_4: '' })
  const [deviceTypes, setDeviceTypes] = useState([]);
  const inputSearch = useRef(null);
  const data = useLoginState();
  const userProfile = data.userProfile
  const customers = useCustomerState();

  const { Dragger } = Upload;
  console.log('test test test');
  //retrieveData();
  //searchDevices({});
  useEffect(() => {
    //retrieveData();
    console.log('CREATE CREATE @#');
    //searchDevices({})
    datasets.getData(SERVER.GET_DEVICETYPES(), datasets.getToken()).then(res => {
      console.log('types', res);
      setDeviceTypes(res);
    })

  }, []);

  const selectFilter = (element: any) => {
    console.log('menu', element);
    //Search id by name
    const name = element.key;
    const deviceType: any = deviceTypes.filter((x: any) => x.name === name)[0];
    //console.log('qwet',deviceType);
    const devId = deviceType._id;

    const newFilteredInfo = {
      ...filteredInfo,
      'device_type_id': devId
    };
    setFilteredInfo(newFilteredInfo);
    const arrayDrop: any = [name];
    setDropTitle(arrayDrop);
    //console.log(element.key);
  }

  const clearAll = () => {
    setFilteredInfo([]);
    setDropTitle([]);
    console.log(filteredInfo);
  };

  const clearDeviceType = () => {
    setFilteredInfo(
      (prev: any) => {
        const { ['device_type_id']: value, ...rest } = prev;
        return rest;
      }
    );
    setDropTitle([]);
  };

  const menu = (
    <Menu>
      {deviceTypes.map((devicetype: any) => <Menu.Item key={devicetype.name} onClick={selectFilter}>
        {devicetype.name}
      </Menu.Item>
      )}
      <Menu.Item key={'clear'}>
        <Button className="btn-border-blue" onClick={clearDeviceType}>Clear filters</Button>
      </Menu.Item>
    </Menu>
  );

  {/*Identifiers*/ }
  const dataSource00 = [
    {
      name: 'Serial Number',
      content: <Form.Item name='serial_number'><Input size="small" placeholder="000.000.000" /></Form.Item>,
    },
    {
      name: 'ESN',
      content: <Input size="small" placeholder="000.000.000" />,
    },
    {
      name: 'IMEI',
      content: <Input size="small" placeholder="000.000.000" />,
    },
    {
      name: 'MAC Address',
      content: <Input size="small" placeholder="000.000.000" />,
    },
    {
      name: 'SMS Number',
      content: <Input size="small" placeholder="000.000.000" />,
    },
  ];

  const columnsHistory = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      fixed: true,
      width: 100
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      fixed: true,
      width: 100
    },
  ]
  //const descend : any = 'descend'
  const columns = [
    {
      title: 'Image 1',
      dataIndex: 'image_1',
      key: 'image_1',
      fixed: true,
      width: 90,
    },
    {
      title: 'Device Type',
      dataIndex: 'devicetype',
      key: 'devicetype',
      fixed: true,
      width: 200,
      filtered: true,
      defaultSortOrder: ASCEND,
      // filters: deviceTypes.map((type: any) => { return { text: type.name, value: type.name } }),
      //filteredValue: filteredInfo,
      sorter: (a: any, b: any) => {
        // console.log('sorter', a, b);
        // return a.devicetype.length - b.devicetype.length },
        return a.devicetype.toUpperCase().charCodeAt(0) - b.devicetype.toUpperCase().charCodeAt(0)
      },
      onFilter: (value: any, record: any) => {
        console.log('onFilter', value, record);
        let valorIn = value.toLowerCase()
        //console.log(record.devicetype.toString().toLowerCase().includes(valorIn), valorIn, record.devicetype.toString().toLowerCase())
        return record.devicetype.toString().toLowerCase().includes(valorIn) || record.model_name.toString().toLowerCase().includes(valorIn)
      },
      //return record.devicetype.indexOf(value) === 0 },
    },
    {
      title: 'Device Name',
      dataIndex: 'model_name',
      key: 'model_name',
      width: 200,
      sorter: (a: any, b: any) => {
        //console.log('sorter', a, b);
        // return a.model.length - b.model.length },
        return a.model_name.toUpperCase().charCodeAt(0) - b.model_name.toUpperCase().charCodeAt(0)
      },
      fixed: true,

    },
    {
      title: 'HW Version',
      dataIndex: 'hw',
      key: 'hw',
      /*sorter: (a: any, b: any) => {
        //console.log('sorter', a, b);
        return a.devicetype.length - b.devicetype.length }, */
    },
    {
      title: 'SW Version',
      dataIndex: 'sw',
      key: 'sw',
      //sorter: true,
    },
    {
      title: 'Script Version',
      dataIndex: 'script_version',
      key: 'script_version',
      //sorter: true,
    },

    {
      title: 'GPS',
      dataIndex: 'gps',
      key: 'gps',
      //sorter: true,
    },
    {
      title: 'BLE',
      dataIndex: 'ble',
      key: 'ble',
      //sorter: true,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 150,
    },
    /* {
      title: 'Hours of Use',
      dataIndex: 'hours',
      key: 'hours',
      //sorter: true,
    }, */

  ];




  useEffect(() => {
    console.log('change customer selected');
    if (userProfile.email !== '') {
      retrieveDataPageNumber(1);
    }

  }, [customers.customerSelected]);


  useEffect(() => {
    console.log('Get me user', userProfile);
    if (userProfile.email !== '') {
      retrieveDataPageNumber(1);
    }

  }, [userProfile]);

  useEffect(() => {
    //console.log('filtered', filteredInfo);
    retrieveDataPageNumber(1);
  }, [filteredInfo]);



  const retrieveData = () => {
    console.log('retrieve Data Devices', customers.customerSelected, userProfile.is_admin)
    if (userProfile.is_admin) {
      searchDevices({ customer_id: customers.customerSelected._id ? customers.customerSelected._id : '' });
    } else {
      if (!customers.customerSelected._id) {
        searchDevices({ customer_id: '123431242134' });
      } else {
        searchDevices({ customer_id: customers.customerSelected._id });
      }
    }
  }

  const retrieveDataPageNumber = (pageNumber: any) => {
    //console.log('retrieve Data Devices', customers.customerSelected, userProfile.is_admin) 
    if (userProfile.is_admin) {
      searchDevices({ customer_id: customers.customerSelected._id ? customers.customerSelected._id : '', limit: 10, page: pageNumber, filters: filteredInfo });
    } else {
      if (!customers.customerSelected._id) {
        searchDevices({ customer_id: '123431242134', limit: 10, page: pageNumber, filters: filteredInfo });
      } else {
        searchDevices({ customer_id: customers.customerSelected._id, limit: 10, page: pageNumber, filters: filteredInfo });
      }
    }
  }

  const searchFilter = (element: any) => {
    //clearAll();
    let textSearch = '';
    if (element.current && element.current.state.value !== undefined) {
      textSearch = element.current.state.value
    } else if (element.target && element.target.value !== undefined) {
      textSearch = element.target.value
    }
    const arrayFilter: any = [];
    arrayFilter.push(textSearch);
    const newFilteredInfo = {
      ...filteredInfo,
      match: arrayFilter
    };
    setFilteredInfo(newFilteredInfo);
  }

  const showImage = (name: string) => {
    return SERVER.DEVICE_ATTACH() + name;
  }

  const [mainImage, setMainImage] = useState([]);

  const fillForm = (values: any) => {
    console.log('Fill Form', values)
    onSelectDevType(values.device_type_id)
    setVisible(true);
    setIsUpdate(true)
    console.log(values)
    const deviceFill = Object.assign({}, values)
    form.setFieldsValue(deviceFill)
    setUserUpdate(values)
  }

  const [visible, setVisible] = useState(false);

  const newDevice = () => {
    showDrawer()
    setIsUpdate(false);
    clearForm();
    setDevTypeSelected({ serial_number: false, esn: false, imei: false, mac_address: false, sms_number: false, gps: false, ble: false, temperature: false, hours_of_use: false, miles_of_use: false, spec_document: '', installation_guide: '', image_1: '', image_2: '', image_3: '', image_4: '' })
  }

  const showDrawer = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  const onClose = () => {
    setVisible(false);
    clearForm();
  };

  const dummyRequest = ({ onSuccess }: { onSuccess: Function }) => {
    setTimeout(() => onSuccess("ok"), 0);
  }

  const handleChangeFilter = (pagination: any, filters: any, sorter: any) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
  };

  const clearForm = () => {
    form.resetFields();
  }


  const registerDeviceDatabase = (values: any) => {
    console.log('values', values)
    const dataForm: FormData = new FormData();
    if (!isUpdate) {
      const deviceRequest = {
        model_name: values.model_name,
        hw_version: values.hw_version,
        sw_version: values.sw_version,
        script_version: values.script_version,
        serial_number: values.serial_number,
        powered_by: values.powered_by,
        gps: values.gps,
        ble: values.ble,
        esn: values.esn,
        imei: values.imei,
        mac_address: values.mac_address,
        sms_number: values.sms_number,
        hours_of_use: values.hours_of_use,
        miles_of_use: values.miles_of_use,
        device_type_id: values.device_type_id
      }
      dataForm.append('request', JSON.stringify(values));
      //console.log('testQWER',dataForm);
      //console.log('testQWERT',values);
      /* if (values.spec_document !== undefined)
        dataForm.append('specification', values.spec_document.file.originFileObj);
      if (values.installation_guide !== undefined)
        dataForm.append('guide', values.installation_guide.file.originFileObj);
      if (values.image_1 !== undefined)
        dataForm.append('image1', values.image_1.file.originFileObj);
      if (values.image_2 !== undefined)
        dataForm.append('image2', values.image_2.file.originFileObj);
      if (values.image_3 !== undefined)
        dataForm.append('image3', values.image_3.file.originFileObj);
      if (values.image_4 !== undefined)
        dataForm.append('image4', values.image_4.file.originFileObj); */

      datasets.postDataFiles(SERVER.REGISTER_DEVICE_FILE(), dataForm, datasets.getToken()).then(res => {
        console.log('Resp Register', res)
        // searchDevices([]);
        retrieveDataPageNumber(1);
        showDrawer();
        clearForm();
        message.success('Device registered successfully');
      })
    } else {
      updateDeviceDatabase(values, userUpdate)

    }
  }
  const deleteDeviceDatabase = (id: any) => {
    console.log('delete Device', id)
    datasets.deleteData(SERVER.DELETE_DEVICE(id), datasets.getToken()).then(res => {
      message.success('Device Removed successfully', 3);
      //searchDevices([]);
      retrieveDataPageNumber(1);
    })
  }
  const updateDeviceDatabase = (dataDevice: any, deviceUpdate: any) => {
    // console.log(form.getFieldsValue(), form)
    //form.submit()

    console.log('Update Data:', dataDevice, deviceUpdate);
    const dataToUpdate = dataDevice
    dataToUpdate._id = deviceUpdate._id
    console.log('Update Data:', dataDevice, deviceUpdate, dataToUpdate);
    datasets.putData(SERVER.UPDATE_DEVICE(), dataToUpdate, datasets.getToken()).then(res => {
      message.success('Device Updated successfully', 3);
      retrieveDataPageNumber(1);
      onClose();
      setIsUpdate(false)
    })
  }
  /* const loadFile = (file: any) => {
    console.log('load file', file)
    return true
  } */

  /* const validateName = (e: any) => {
    // console.log(e, e.target.value)
    const devName = e.target.value
    datasets.getData(SERVER.VALIDATE_NAME_DEV(devName), datasets.getToken()).then(res => {
      console.log('respuesta validacion', res)
      if (res.valid) {
        setNameValid({ message: res.message, valid: res.valid, status: "success" })
      } else {
        setNameValid({ message: res.message, valid: res.valid, status: "error" })
      }
    })
  } */
  const onSelectDevType = (element: any) => {
    console.log(element)
    const devtype: any = deviceTypes.find((type: any) => type._id === element)
    console.log('devtype on', devtype)
    if (devtype) {
      setDevTypeSelected(devtype)
      /* if (devtype.spec_document !== '') {
        datasets.getDataFile(SERVER.GET_FILE_DEVICE('3efcc3458cf617b5e4ee6ebd4cb1e780.png'), datasets.getToken()).then( (res: any) => {
          console.log('get file', res)
          const resp = Object.assign({}, devTypeSelected)
          resp.spec_document = res.url
          setDevTypeSelected(resp)
          console.log(devTypeSelected)
        })
      } */

    }
  }
  const showConfirmModal = (id: any) => {
    //setPartnerId(id);
    Modal.confirm({
      title: "Remove Device Type",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to remove Device Type?',
      onOk() {
        deleteDeviceDatabase(id);
        // searchDevices([]);
        retrieveDataPageNumber(1);
      }
    });
  }

  const onShowHistory = async (id: any) => {
    console.log('onshowhistory', id)
    await datasets.postData(SERVER.GET_HISTORY_DEVICE(), { device_id: id }, datasets.getToken()).then(res => {
      console.log('Resp Register', res)
      Modal.info({
        title: "History Location Device",
        icon: <HistoryOutlined />,
        content: <Table className="table-general" dataSource={res.map((his: any) => { return ({ date: (new Date(his.created_at).toUTCString()), location: his.latitude + ', ' + his.longitude }) })} columns={columnsHistory}></Table>,
        onOk() {
          //deleteDeviceDatabase(id);
          //searchDevices([]);
        }
      });
    })
  }

  const urlFile = (name: string) => {
    return SERVER.URL_DEVICE_FILE(name)
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <SideBar></SideBar>
      <Layout>
        <NavBar></NavBar>
        <Layout className="site-layout">
          <Content className="user table-general">
            <Row>
              <Col xs={24} lg={24}>
                <div className="card-white">
                  <div className="head-t">
                    <Row>
                      <Col xs={24} lg={18}>
                        <label>Device Type:</label>
                        <Dropdown overlay={menu} trigger={['click']}>
                          <Button className="btn-border-light-gray">
                            {dropTitle.length === 0 ? 'Choose' : dropTitle[0]} <DownOutlined />
                          </Button>
                        </Dropdown>
                        <Input
                          allowClear
                          onChange={(e: any) => {
                            if (e.type === 'click' && e.target.value === '') {
                              searchFilter(e);
                            }
                          }}
                          onPressEnter={() => searchFilter(inputSearch)}
                          ref={inputSearch} placeholder="Search..."
                          prefix={<SearchOutlined />}
                          style={{
                            width: 150
                          }} />

                        <Button className="btn-blue" onClick={() => searchFilter(inputSearch)}>Search</Button>
                        <Checkbox>Archive</Checkbox>
                      </Col>
                      <Col xs={24} lg={6} style={{ textAlign: 'right' }}>
                        {/*<Input placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 150 }} />*/}
                        <Button className="btn-green" onClick={newDevice}>New Device</Button>
                        <Drawer
                          className="drawer-form"
                          title={isUpdate ? 'Edit Device' : 'New Device'}
                          placement="right"
                          closable={false}
                          onClose={onClose}
                          visible={visible}
                          width={735}
                          footer={
                            <div
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <Button style={{ marginRight: 8 }} className="btn-border-red" onClick={showDrawer}>
                                Cancel
                              </Button>
                              <Button className="btn-green" onClick={() => {
                                form.submit()
                                setIsTouchName(false)
                              }}>
                                Save
                              </Button>
                            </div>
                          }
                        >
                          <h5>DETAILS</h5>
                          <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" form={form} onFinish={registerDeviceDatabase} hideRequiredMark>
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item name='device_type_id' label="*Device Type:" rules={[{ required: true, message: 'Please select Device Type!' }]}>
                                  <Select
                                    placeholder="Device Type"
                                    onChange={onSelectDevType}
                                  >
                                    {deviceTypes.map((type: any) => <Option key={type._id} value={type._id}>{type.name}</Option>)}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item name='model_name' label="*Device Name:" dependencies={['hw_version']}
                                  hasFeedback validateTrigger={['onBlur']} rules={[{ required: true, message: 'Model Name!' },
                                  ({ getFieldValue, isFieldTouched }) => ({
                                    validator: async (_, value) => {
                                      /*console.log('devi xd', !value, !devices.listDevices.find(dev => dev.model_name === value), isUpdate )
                                      if (!value || !devices.listDevices.find(dev => dev.model_name === value && !isUpdate)) {*/
                                      await datasets.getData(SERVER.VALIDATE_NAME_DEV(value), datasets.getToken()).then(res => {
                                        console.log('response Validation', res, !isTouchName)
                                        //console.log('devi xd', !value, res.valid, isUpdate)
                                        if (isUpdate && !isTouchName) {
                                          setIsTouchName(false)
                                          //message.success('Device Updated successfully');
                                          return Promise.resolve();
                                        } else {
                                          if (!value || (res.valid)) {
                                            return Promise.resolve();
                                          }
                                        }

                                        return Promise.reject('This name already exists, please choose another!');

                                      })
                                    },
                                  }),]}>
                                  <Input onChange={() => setIsTouchName(true)} placeholder="Please enter model" />
                                </Form.Item>
                              </Col>
                              {/* <Col span={6}>
                                <Form.Item name='address_device' label="*Device ID:" >
                                  <Input placeholder="Device ID" />
                                </Form.Item>
                              </Col> */}
                              <Col span={6}>
                                <Form.Item name="customer_id" label="Customer Asociated" className="select-bg">
                                  <Select
                                    className={'ant-select-selector-special'}
                                    placeholder="Please select a Customer"
                                  >
                                    {customers.listCustomers.map((customer: any) => <Option value={customer._id} label={customer.customer_name}>{customer.customer_name}</Option>)}
                                  </Select>

                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item name='powered_by' label="Powered by:">
                                  <Select mode={'tags'} placeholder="Please select">
                                    <Option value="no_power_needed">No Power Needed</Option>
                                    <Option value="internal_removable_battery">Internal Removable Battery</Option>
                                    <Option value="internal_non_removable_battery">Internal Non-Removable Battery</Option>
                                    <Option value="external_battery">External Battery</Option>
                                    <Option value="external_power">External Power</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={8}>
                              <Col span={6}>
                                <Form.Item name='hw_version' label="HW Version:" >
                                  <Input placeholder="HW version" />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item name='sw_version' label="SW Version:">
                                  <Input placeholder="SW version" />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item name='script_version' label="Script Version:" >
                                  <Input placeholder="Script verion " />
                                </Form.Item>
                              </Col>

                            </Row>
                          </Form>
                          <Form form={form} onFinish={registerDeviceDatabase} hideRequiredMark>
                            <Row gutter={8} style={{ marginTop: '10px' }}>
                              <Col span={12}>
                                <h5>IDENTIFIERS</h5>
                                <Table className="table-small" dataSource={[
                                  {
                                    name: 'Serial Number',
                                    content: <Form.Item name='serial_number'><Input size="small" placeholder="000.000.000" /></Form.Item>,
                                    visible: devTypeSelected.serial_number
                                  },
                                  {
                                    name: 'ESN',
                                    content: <Form.Item name='esn'><Input size="small" placeholder="000.000.000" /></Form.Item>,
                                    visible: devTypeSelected.esn
                                  },
                                  {
                                    name: 'IMEI',
                                    content: <Form.Item name='imei'><Input size="small" placeholder="000.000.000" /></Form.Item>,
                                    visible: devTypeSelected.imei
                                  },
                                  {
                                    name: 'MAC Address',
                                    content: <Form.Item name='mac_address'><Input size="small" placeholder="000.000.000" /></Form.Item>,
                                    visible: devTypeSelected.mac_address
                                  },
                                  {
                                    name: 'SMS Number',
                                    content: <Form.Item name='sms_number'><Input size="small" placeholder="000.000.000" /></Form.Item>,
                                    visible: devTypeSelected.sms_number
                                  },
                                ].filter((element: any) =>
                                  element.visible === true)
                                } columns={colIdentifiers} />
                              </Col>
                              <Col span={12}>
                                <h5>PROPERTIES</h5>
                                <Table className="table-small" dataSource={[
                                  {
                                    name: 'GPS',
                                    content: <Form.Item name='gps'><Input size="small" placeholder="1.03940937509 - 12.389798375983" disabled={isUpdate} /></Form.Item>,
                                    visible: devTypeSelected.gps
                                  },
                                  {
                                    name: 'BLE',
                                    content: <Form.Item name='ble'><Input size="small" placeholder="1.03940937503122" disabled={isUpdate} /></Form.Item>,
                                    visible: devTypeSelected.ble
                                  },
                                  {
                                    name: 'Temperature',
                                    content: <Form.Item name='temperature'><Input size="small" placeholder="" disabled={isUpdate} /></Form.Item>,
                                    visible: devTypeSelected.temperature
                                  },
                                  {
                                    name: 'Speed',
                                    content: <Form.Item name='temperature'><Input size="small" placeholder="" disabled={isUpdate} /></Form.Item>,
                                    visible: devTypeSelected.speed
                                  },
                                  {
                                    name: 'Heading',
                                    content: <Form.Item name='temperature'><Input size="small" placeholder="" disabled={isUpdate} /></Form.Item>,
                                    visible: devTypeSelected.heading
                                  },
                                  {
                                    name: 'Hours of Use',
                                    content: <Form.Item name='hours_of_use'><Input size="small" placeholder="12" disabled={isUpdate} value={''}/></Form.Item>,
                                    visible: devTypeSelected.hours_of_use
                                  },
                                  {
                                    name: 'Miles of Use',
                                    content: <Form.Item name='miles_of_use'><Input size="small" placeholder="30" disabled={isUpdate} /></Form.Item>,
                                    visible: devTypeSelected.miles_of_use
                                  },
                                ].filter((element: any) =>
                                  element.visible === true)
                                } columns={columns01} />
                              </Col>
                            </Row>
                          </Form>
                          <Row gutter={8}>
                            <Col span={24}>
                              <h5>DOCUMENTATION</h5>
                              <Table className="table-small" dataSource={[
                                {
                                  name: 'Specification Document',
                                  type: <label style={{ color: '#1768AC' }}>PDF</label>,
                                  link: <a href={devTypeSelected.spec_document !== '' ? urlFile(devTypeSelected.spec_document) : ''} target="_blank" ><i className="mdi mdi-link-variant" style={{ color: '#3FA9F5', fontSize: '14px' }}></i></a>,
                                  contain: devTypeSelected.spec_document
                                },
                                {
                                  name: 'Guide',
                                  type: <label style={{ color: '#1768AC' }}>PDF</label>,
                                  link: <a href={devTypeSelected.installation_guide !== '' ? urlFile(devTypeSelected.installation_guide) : ''} target="_blank" ><i className="mdi mdi-link-variant" style={{ color: '#3FA9F5', fontSize: '14px' }}></i></a>,
                                  contain: devTypeSelected.installation_guide
                                },
                                {
                                  name: 'Image 1',
                                  type: <label style={{ color: '#1768AC' }}>PDF</label>,
                                  link: <a href={devTypeSelected.image_1 !== '' ? urlFile(devTypeSelected.image_1) : ''} target="_blank"><i className="mdi mdi-link-variant" style={{ color: '#3FA9F5', fontSize: '14px' }}></i></a>,
                                  contain: devTypeSelected.image_1
                                },
                                {
                                  name: 'Image 2',
                                  type: <label style={{ color: '#1768AC' }}>PDF</label>,
                                  link: <a href={devTypeSelected.image_2 !== '' ? urlFile(devTypeSelected.image_2) : ''} target="_blank"><i className="mdi mdi-link-variant" style={{ color: '#3FA9F5', fontSize: '14px' }}></i></a>,
                                  contain: devTypeSelected.image_2
                                },
                                {
                                  name: 'Image 3',
                                  type: <label style={{ color: '#1768AC' }}>PDF</label>,
                                  link: <a href={devTypeSelected.image_3 !== '' ? urlFile(devTypeSelected.image_3) : ''} target="_blank"><i className="mdi mdi-link-variant" style={{ color: '#3FA9F5', fontSize: '14px' }}></i></a>,
                                  contain: devTypeSelected.image_3
                                },
                                {
                                  name: 'Image 4',
                                  type: <label style={{ color: '#1768AC' }}>PDF</label>,
                                  link: <a href={devTypeSelected.image_4 !== '' ? urlFile(devTypeSelected.image_4) : ''} target="_blank"><i className="mdi mdi-link-variant" style={{ color: '#3FA9F5', fontSize: '14px' }}></i></a>,
                                  contain: devTypeSelected.image_4
                                },
                              ].filter((element: any) =>
                                element.contain !== '')
                              } columns={columns02} />
                            </Col>
                          </Row>

                          <Alert message="*Required Fields" type="error" />
                        </Drawer>
                      </Col>
                    </Row>
                  </div>

                  {
                    (devices.listDevices && devices.listDevices.length <= 10) && <Table dataSource={devices.listDevices.map((device: any, indexTest: any) => {
                      //console.log('sds', device)
                      return ({
                        check: <Checkbox></Checkbox>,
                        key: device._id,
                        uid: device._id,
                        image_1: device.image_1 === '' ? <img src='/Images/assetIcons/Defibrillator.svg' width="30px" height="30px"></img> : <img src={showImage(device.image_1)} width="30px" height="30px"></img>,
                        devicetype: device.device_type_name,
                        model_name: device.model_name,
                        hw: device.hw_version,
                        sw: device.sw_version,
                        guide: device.installation_guide,
                        powered_by: device.powered_by ? device.powered_by.map((tag: any) => {
                          return (
                            <Tag color={'blue'} key={tag}>
                              {tag.toUpperCase().replaceAll('_', ' ')}
                            </Tag>
                          )
                        }) : '',
                        script_version: device.script_version,
                        ble: <Checkbox disabled checked={device.ble} ></Checkbox>,
                        gps: <Checkbox disabled checked={device.gps} ></Checkbox>,
                        actions: <>
                          <Tooltip title="Edit"><Button className="btn-edit" onClick={() => fillForm(device)}><i className="mdi mdi-pencil"></i></Button></Tooltip>
                          <Tooltip title="Delete"><Button className="btn-delete" onClick={() => showConfirmModal(device._id)} ><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
                          <Tooltip title="History"><Button className="btn-password" onClick={() => onShowHistory(device._id)}><i className="mdi mdi-history"></i></Button></Tooltip>
                        </>,
                      })
                    })} columns={columns} pagination={false} scroll={{ x: 1000, y: 'calc(100vh - 267px)' }} />
                  }
                  {
                    (devices !== undefined &&
                      devices.pagination !== undefined &&
                      devices.pagination.total !== undefined &&
                      devices.pagination.total > 0) &&
                    <Pagination
                      style={{
                        float: 'right',
                        marginTop: '10px',
                        marginBottom: '10px',
                        marginRight: '1.8%',
                      }}
                      current={devices.pagination.page}
                      total={devices.pagination.total}
                      pageSize={10}
                      showSizeChanger={false}
                      onChange={(pageNumber) => {
                        retrieveDataPageNumber(pageNumber);
                      }}
                    />
                  }
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
