import { CustomerState, SHOW_CUSTOMERS, CustomerType, SEARCH_CUSTOMERS, SELECT_CUSTOMER } from '../types'

const initialState = {
    listCustomers: [],
    customerSelected: {},
    pagination: {}
}
export const customerReducer = (state: CustomerState = initialState, action: CustomerType) => {
    switch (action.type) {
        case SHOW_CUSTOMERS:
            return Object.assign({}, state, {listCustomers: action.data});
        case SEARCH_CUSTOMERS:
            return Object.assign({}, state, {listCustomers: action.data, pagination: action.pagination});
        case SELECT_CUSTOMER:
            return Object.assign({}, state, {customerSelected: action.data});
        default:
            return state
    }
}