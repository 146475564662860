import { useDispatch, useSelector } from 'react-redux';
import { searchBuilding, saveBuilding, updateBuilding, deleteBuilding, saveBuildingWithFile, updateBuildingWithFile, getBuildingById } from '../store/actions';
import { RootState } from '../store/reducers';

export const useBuildingState = () => {
  return useSelector((state: RootState) => state.building);
}
export const useBuildingDispatch = () => {
  const dispatch = useDispatch();
  return {
    searchBuilding: (data: any) => dispatch(searchBuilding(data)),
    saveBuilding: (data: any) => dispatch(saveBuilding(data)),
    updateBuilding: (data: any) => dispatch(updateBuilding(data)),
    deleteBuilding: (id: string) => dispatch(deleteBuilding(id)),
    addBuildingWithFile: (data: any) => dispatch(saveBuildingWithFile(data)),
    updateBuildingWithFile: (data: any) => dispatch(updateBuildingWithFile(data)),
    getBuildingById: (id: string) => dispatch(getBuildingById(id))
  };
};