import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SEARCH_FLOOR_PLAN, FloorPlanType, SAVE_FLOOR_PLAN, UPDATE_FLOOR_PLAN, DELETE_FLOOR_PLAN, GET_DATA_FLOOR_WIREPAS, FLOOR_PLAN_BY_ID } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from '../../config/datasets';

export const searchFloorPlanObj = (listFloorPlan: any) => {
  return {
    type: SEARCH_FLOOR_PLAN,
    data: listFloorPlan
  }
}
export const saveObjectFloorPlan = (floorPlan: any) => {
  return {
    type: SAVE_FLOOR_PLAN,
    data: floorPlan
  }
}
export const updateObjectFloorPlan = (floorPlan: any) => {
  return {
    type: UPDATE_FLOOR_PLAN,
    data: floorPlan
  }
}
export const deleteObjectFloorPlan = (floorPlan: any) => {
  return {
    type: DELETE_FLOOR_PLAN,
    data: floorPlan
  }
}
export const getDataFromWirepas = (datafloor: any) => {
  return {
    type: GET_DATA_FLOOR_WIREPAS,
    data: datafloor
  }
}
export const getFloorPlanId = (dataFloor: any) => {
  return {
    type: FLOOR_PLAN_BY_ID,
    data: dataFloor
  }
}
export const searchFloorPlan = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.SEARCH_FLOOR_PLAN(), data, token);
    dispatch(searchFloorPlanObj(response));
  }
}
export const saveFloorPlan = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    // const response = await datasets.postData(SERVER.ADD_FLOOR_PLAN(), data, token);
    const response = await datasets.postDataFiles(SERVER.ADD_FLOOR_PLAN_FILE(), data, token);
    dispatch(saveObjectFloorPlan(response));
  }
}
export const updateFloorPlan = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.putData(SERVER.UPDATE_FLOOR_PLAN(), data, token);
  }
}
export const deleteFloorPlan = (id: string): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.deleteData(SERVER.DELETE_FLOOR_PLAN(id), token);
    console.log('reik', response)
    dispatch(deleteObjectFloorPlan(response));
  }
}
export const getFloorPlanById = (id: string): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.getData(SERVER.FLOOR_PLAN_BY_ID(id), token);
    dispatch(getFloorPlanId(response));
  }
}
export const getDataFloorWirepas = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.GET_FLOOR_DATA_FROM_WIREPAS(), data, token);
    dispatch(getDataFromWirepas(response));
  }
}