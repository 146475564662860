export const SHOW_DEVICETYPES = 'SHOW_DEVICETYPES';
export const SEARCH_DEVICETYPES = 'SEARCH_DEVICETYPES';


export interface DeviceTypeState {
  listDeviceTypes: any[],
  pagination: any
}

interface ReplaceDeviceTypeAction {
  type: typeof SHOW_DEVICETYPES,
  data: DeviceTypeState,
}

interface ReplaceSearchDeviceTypes {
  type: typeof SEARCH_DEVICETYPES,
  data: DeviceTypeState,
  pagination: {
    total: any,
    page: any
  }
}


export type DevicetypType = ReplaceDeviceTypeAction | ReplaceSearchDeviceTypes