import { useDispatch, useSelector } from 'react-redux';

import { loadCPartners, searchCustomers, searchPartners } from '../store/actions';
import { RootState } from '../store/reducers';

export const useCPartnerState = () => {
  return useSelector((state: RootState) => state.channelpartner);
  
};

export const useCPartnerDispatch = () => {
  const dispatch = useDispatch();
  return {
    listCPartners: () => dispatch(loadCPartners()),
    searchPartners: (data: any) => dispatch(searchPartners(data))
  };
};

