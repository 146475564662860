import { useDispatch, useSelector } from 'react-redux';
import { searchAssets, loadAssets, saveAsset, updateAsset, deleteAsset, searchAssetIcons, getAssetsMap, getAssetsMapArray, getBuildingById, updateAssetFromList } from '../store/actions';
import { RootState } from '../store/reducers';

export const useAssetState = () => {
  return useSelector((state: RootState) => state.asset);
};

export const useAssetDispatch = () => {
  const dispatch = useDispatch();
  return {
    searchAssets: (data: any) => dispatch(searchAssets(data)),
    listAssets: () => dispatch(loadAssets()),
    saveAsset: (data: any) => dispatch(saveAsset(data)),
    updateAsset: (data: any) => dispatch(updateAsset(data)),
    deleteAsset: (id: string) => dispatch(deleteAsset(id)),
    searchAssetIcons: (data: any) => dispatch(searchAssetIcons(data)),
    getAssetMap: (data: any) => dispatch(getAssetsMap(data)),
    getAssetsMapArray: (data: any) => dispatch(getAssetsMapArray(data)),
    updateAssetFromList: (data: any) => dispatch(updateAssetFromList(data)),
    // getBuildingById: (id: string) => dispatch(getBuildingById(id))
  };
};
