import {
  FloorPlanState, SEARCH_FLOOR_PLAN, SAVE_FLOOR_PLAN, UPDATE_FLOOR_PLAN, DELETE_FLOOR_PLAN, FloorPlanType, GET_DATA_FLOOR_WIREPAS, FLOOR_PLAN_BY_ID
} from '../types';

const initialState = {
  listFloorPlan: [],
  objectFloorPlan: {}
}

export const floorPlanReducer = (state: FloorPlanState = initialState, action: FloorPlanType) => {
  switch (action.type) {
    case SEARCH_FLOOR_PLAN:
      return Object.assign({}, state, { listFloorPlan: action.data });
    case SAVE_FLOOR_PLAN:
      return Object.assign({}, state, { objectFloorPlan: action.data });
    case UPDATE_FLOOR_PLAN:
      return Object.assign({}, state, { objectFloorPlan: action.data });
    case DELETE_FLOOR_PLAN:
      return Object.assign({}, state, { objectFloorPlan: action.data });
    case GET_DATA_FLOOR_WIREPAS:
      return Object.assign({}, state, { objectFloorPlan: action.data });
    case FLOOR_PLAN_BY_ID:
      return Object.assign({}, state, { objectFloorPlan: action.data });
    default:
      return state;
  }
}