import { UserState, SHOW_USERS, GET_USER_INFORMATION, UserType, SEARCH_USERS } from '../types'

const initialState = {
    list: [],
}
export const userReducer = (state: UserState = initialState, action: UserType) => {
    switch (action.type) {
        case SHOW_USERS:
            return Object.assign({}, state, { list: action.data })
        case SEARCH_USERS:
            return Object.assign({}, state, { list: action.data })
        default:
            return state
    }

}