import { Action } from 'redux'
;
import { ThunkAction } from 'redux-thunk';

import { TestActionTypes, SHOW_USERS, GET_USER_INFORMATION, User, SEARCH_USERS } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from "../../config/datasets";

export const listUsers = (listU: any) => {
  return {
    type: SHOW_USERS,
    data: listU,
  }
}

export const searchUser = (listUser: any) => {
  return {
    type: SEARCH_USERS,
    data: listUser
  }
}

export const loadUsers = (): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    console.log('token', token);
    const response = await getRequest(SERVER.GET_USERS(), token);
    console.log('resp', response)
    dispatch(listUsers(response));
  }
}

export const searchUsers = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.SEARCH_USERS(), data, token);
    console.log('resp', response)
    dispatch(searchUser(response));
  }
}

export const saveUserInformation = (user: User) => {
  return (dispatch: Function) => {
    dispatch({ type: GET_USER_INFORMATION, user });
  }
}