export const DELETE = 'DELETE'
export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const TOKEN = 'user_token'

export const ROUTERS_SIDEBAR = [
  '/', '/map', '/', '/partners', '/customer', '/user', '/', '/devicetypes', '/devices', '/campus', '/', '/assettypes', '/assets', '/reports', '/rawmessages', '/test', '/profile', '/campus'
]

export const DESCEND: any = 'descend'
export const ASCEND: any = 'ascend'

export const LIMIT_HISTORY = 7

function importAll(r: any) {
  return r.keys().map(r)
}
//const iconsSVG = importAll(require.context('../../../public/Images/assetIcons', false, /\.(png|jpe?g|svg)$/)) 
const iconsSVG = importAll(require.context('../../public/Images/assetIcons', false, /\.(png|jpe?g|svg)$/))


// TO LIST ICONS
export const iconsListAsset = () => {
  return iconsSVG.map((icon: any) => {
    let name = icon.default
    //console.log(name)
    //name = name.split('/')
    //name = name.lastIndexOf('/')
    name = name.substring(14)
    name = name.split('.')
    name = name[0] + '.' + name[2]
    //console.log(name)
    return name
  })
}

export const dummyRequest = (options: any) => {
  // console.log('mi option',options, typeof options);
  setTimeout(() => options.onSuccess('ok'), 0);
}

// EVENTS
export const EVENTS = [
  { eventType: 0, eventName: "Door Unlock" },
  { eventType: 1, eventName: "Heading Change" },
  { eventType: 2, eventName: "Ignition OFF" },
  { eventType: 3, eventName: "Heartbeat" },
  { eventType: 4, eventName: "Ignition ON" },
  { eventType: 5, eventName: "Move" },
  { eventType: 22, eventName: "Power ON" },
  { eventType: 23, eventName: "Power OFF" },
  { eventType: 26, eventName: "Stop" },
  { eventType: 27, eventName: "Minor" },
  { eventType: 28, eventName: "Virtual Ignition ON" },
  { eventType: 29, eventName: "Virtual Ignition OFF" },
  { eventType: 30, eventName: "GPS Acquired" },
  { eventType: 31, eventName: "Door Lock" },
  { eventType: 32, eventName: "Crash" },
  { eventType: 33, eventName: "Hard Acceleration" },
  { eventType: 34, eventName: "Hard Brake" },
  { eventType: 35, eventName: "Hard Left" },
  { eventType: 36, eventName: "Hard Right" },
  { eventType: 39, eventName: "Cold Boot" },
  { eventType: 42, eventName: "Warm Boot" },
  { eventType: 45, eventName: "Watchdog" },
  { eventType: 51, eventName: "GPS Unavailable" },
  { eventType: 52, eventName: "Low Battery" },
  { eventType: 101, eventName: "GPS Lost" },
  { eventType: 102, eventName: "Comm Acquired" },
  { eventType: 103, eventName: "Comm Lost" },
  { eventType: 104, eventName: "Vibration Wake" },
  { eventType: 105, eventName: "Voltage Wake" },
  { eventType: 107, eventName: "Enter Sleep" },
  { eventType: 108, eventName: "Periodic Boot" },
  { eventType: 109, eventName: "Update Boot" },
  { eventType: 110, eventName: "Comm Reset (Unavailable)" },
  { eventType: 111, eventName: "GPS Reset" },
  { eventType: 112, eventName: "Comm Reset (No Ack)" },
  { eventType: 113, eventName: "Comm Reset (No AT)" },
  { eventType: 114, eventName: "Comm Reset (Command)" },
  { eventType: 116, eventName: "Absolute G Wake" },
  { eventType: 117, eventName: "DTC Event" },
  { eventType: 118, eventName: "Tow" },
  { eventType: 119, eventName: "Speeding" },
  { eventType: 120, eventName: "Power Protect" },
  { eventType: 200, eventName: "Boot Checkin" },
  { eventType: 201, eventName: "Heartbeat Checkin" },
  { eventType: 202, eventName: "AGPS Start" },
  { eventType: 203, eventName: "Engineering Message" },
  { eventType: 204, eventName: "OBDII Message" },
  { eventType: 205, eventName: "Pinging" },
  { eventType: 206, eventName: "Speed Above Alert" },
  { eventType: 207, eventName: "Speed Below Alert" },
  { eventType: 208, eventName: "Turn by Turn Alert" },
  { eventType: 209, eventName: "Heavy Duty DTC Report" },
  { eventType: 210, eventName: "Panic Off" },
  { eventType: 211, eventName: "Panic On" },
  { eventType: 212, eventName: "Privacy Off" },
  { eventType: 213, eventName: "Privacy On" },
  { eventType: 214, eventName: "Driver ID Match" },
  { eventType: 215, eventName: "Driver ID Mismatch" },
  { eventType: 216, eventName: "BLE Message" },
]