export const SHOW_ASSETTYPES = 'SHOW_ASSETTYPES';
export const SEARCH_ASSETTYPES = 'SEARCH_ASSETTYPES';
export const GET_ASSET_TYPE_MAP = 'GET_ASSET_TYPE_MAP';

export interface AssetTypeState {
  listAssetTypes: any[],
  listAssetTypesMap: any[]
}

interface ReplaceAssetTypeAction {
  type: typeof SHOW_ASSETTYPES,
  data: AssetTypeState,
}

interface ReplaceSearchAssetTypeAction {
  type: typeof SEARCH_ASSETTYPES,
  data: AssetTypeState,
  pagination: {
    total: any,
    page: any
  }
}
interface ReplaceAssetTypeMap {
  type: typeof GET_ASSET_TYPE_MAP,
  data: AssetTypeState
}
export type AssettypType = ReplaceAssetTypeAction | ReplaceSearchAssetTypeAction 
          | ReplaceAssetTypeMap