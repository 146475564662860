export const SHOW_USERS = 'SHOW_USERS';
export const GET_USER_INFORMATION = 'GET_USER_INFORMATION';
export const SEARCH_USERS = 'SEARCH_USERS';

export interface UserState {
  list: any[]
}

interface ReplaceUserAction {
  type: typeof SHOW_USERS,
  data: UserState,
}

interface ReplaceSearchUsers {
  type: typeof SEARCH_USERS,
  data: UserState
}

export type User = {
  full_name: string,
  password: string,
  email: string,
  changePasswordId: string,
  changePasswordExpiration: string,
  language: string,
  time_zone: string,
  role: string,
  is_admin: boolean
}

export type UserType = ReplaceUserAction |
    ReplaceSearchUsers