import { Tabs } from "antd";

const { TabPane } = Tabs;
export const Popup = (props: any) => {
  const { markerPopup, showLocationAsset } = props;

  const getTheMostRecentDevice = (devices: any) => {
    let mostRecentDevice = devices.reduce((prev: any, curr: any) => {
      return prev.last_update > curr.last_update ? prev : curr;
    });
    return (<TabPane tab="Last device updated">
      <div className="comment-popup-body">
        <div className="content">
          <p><b>Asset Type: </b>{mostRecentDevice.asset_type_name}</p>
          <p><b>Device Name: </b>{mostRecentDevice.device_name}</p>
          <p><b>Location:</b> {showLocationAsset(mostRecentDevice)}</p>
          <p><b>Last Updated:</b> {mostRecentDevice.last_update}</p>
          {(mostRecentDevice.tempSensor0_C !== undefined && mostRecentDevice.tempSensor0_C !== null) && <p><b>Temperature:</b> {mostRecentDevice.tempSensor0_C.slice(0, -2) + "." + mostRecentDevice.tempSensor0_C.slice(2) + " C"}</p>}
          {(mostRecentDevice.devicetype[0]?.speed) && <p><b>Speed: </b>{mostRecentDevice.speed} km/h</p>}
          {(mostRecentDevice.devicetype[0]?.heading) && <p><b>Heading: </b>{mostRecentDevice.heading}</p>}
          {(mostRecentDevice.event !== undefined && mostRecentDevice.event.length > 0) && <p><b>Event: </b>{mostRecentDevice.event[0].eventName}</p>}

        </div>
        <div className="comment-img">
          <img src={'/Images/assetIcons/' + mostRecentDevice.icon} width="70px" alt="" />
        </div>
      </div>
    </TabPane>)
  }

  return (
    <div id="popup" className="ol-popup popup-map">
      <a href="#" id="popup-closer" className="ol-popup-closer"></a>
      <div id="popup_content">
        <h1><b>{markerPopup.values_.devices[0].asset_name} </b></h1>
        <Tabs defaultActiveKey="1">
          {getTheMostRecentDevice(markerPopup.values_.devices)}
          {
            markerPopup.values_.devices.map((device: any, index: any) => {
              const address = device.device_imei ? device.device_imei : device.device_esn;

              return (
                <TabPane tab={device.device_name} key={address}>
                  <div className="comment-popup-body">
                    <div className="content">
                      <p><b>Device Name: </b>{device.device_name}</p>
                      <p><b>Location:</b> {showLocationAsset(device)}</p>
                      <p><b>Last Updated:</b> {device.last_update}</p>
                      {(device.tempSensor0_C !== undefined && device.tempSensor0_C !== null) && <p><b>Temperature:</b> {device.tempSensor0_C.slice(0, -2) + "." + device.tempSensor0_C.slice(2) + " C"}</p>}
                      {(device.devicetype[0]?.speed) && <p><b>Speed: </b>{device.speed} km/h</p>}
                      {(device.devicetype[0]?.heading) && <p><b>Heading: </b>{device.heading}</p>}
                      {(device.event !== undefined && device.event.length > 0) && <p><b>Event: </b>{device.event[0].eventName}</p>}
                    </div>
                    <div className="comment-img">
                      <img src={'/Images/assetIcons/' + device.icon} width="70px" alt=""/>
                    </div>
                  </div>
                </TabPane>
              )
            })

          }
        </Tabs>
      </div>
    </div>
  )
}