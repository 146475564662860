import { LinkState, LIST_LINKS, LinkType } from '../types';

const initialState = {
   listLinks: []
}

export const linkReducer = (state: LinkState = initialState, action: LinkType) => {
   switch (action.type) {
      case LIST_LINKS:
         return Object.assign({}, state, { listLinks: action.data });
      default:
         return state;
  }
}