import { useDispatch, useSelector } from 'react-redux';

import { loadDevices, loadDeviceTypes } from '../store/actions';
import { RootState } from '../store/reducers';

export const useDeviceTypeState = () => {
  return useSelector((state: RootState) => state.deviceTypes);
};

export const useDeviceTypeDispatch = () => {
  const dispatch = useDispatch();
  return {
    listDeviceTypes: () => dispatch(loadDeviceTypes()),
  };
};

