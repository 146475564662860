import { Redirect, Route, Switch } from 'react-router-dom';
import { saveAs } from 'file-saver';

export const getToken = () => {
    return sessionStorage.getItem('token') ? sessionStorage.getItem('token') : (localStorage.getItem('token') ? localStorage.getItem('token') : '');
}

export const isAutenticated = () => {
    console.log('localsto',localStorage.getItem('token'))
    return localStorage.getItem('token') !== '' && localStorage.getItem('token') !== null  
}

export const deleteData = (url: any, token?: any) => {
    const headers = token ? JSONOptions(token) : JSONDefault();
    return fetch(url, {
        method: 'DELETE',
        headers
    })
    .then(response => response.json())
    .then(data => (data))
    .catch((err) => {
      console.log(err);
    });
}
export const JSONOptions = (token?: any) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (token) {
        headers.append('Authorization', 'Bearer ' + token);
    }
    return headers;
}

export const JSONDefault = () => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return headers;
}

export const getData = (url: any, token?: any) => {
    const headers = token ? JSONOptions(token) : JSONDefault();
    return fetch(url, {
        method: 'GET',
        headers
    })
    .then(response => response.json())
    .then(data => (data))
    .catch((err) => {
      console.log(err);
    }); 
}
export const getDataFile = (url: any, token?: any) => {
    const headers = token ? JSONOptions(token) : JSONDefault();
    return fetch(url, {
        method: 'GET',
        headers
    })
    .then(data => (data))
    .catch((err) => {
      console.log(err);
    }); 
}

export const postData = (url: any, body: any, token?: any) => {
    const headers = token ? JSONOptions(token) : JSONDefault();
    return fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
    }).then(response => response.json())
    .then(data => {return (data);})
    .catch((err) => {
      console.log('error query', err);
    });
    /* .then(response => {
        if (response.status === 401) 
        return response.json()
        else return <Redirect to="/login" />
    })
    //.then(data => {return (data);})
    .catch((err) => {
      console.log('error p' , err);
    }); */ 
    
    
}
export const postDataCSV = (url: any, body: any, token?: any) => {
    const headers = token ? JSONOptions(token) : JSONDefault();
    return fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
    }).then(response => { console.log (response.blob()); response.blob()} )
    .then(data => {return (data);})
    .catch((err) => {
      console.log('error query', err);
    });   
}
export const postDataFiles = (url: any, body: any, token?: any) => {
    //console.log('DATOS', body);
    const headers = token ? MultiPartOptions(token) : MultiPart();
    return fetch(url, {
        method: 'POST',
        headers,
        body: body
    })
    .then(response => response.json())
    .then(data => {return (data);})
    .catch((err) => {
      console.log('error pes' , err);
    });
}

export const putData = (url: any, body: any, token?: any) => {
    const headers = token ? JSONOptions(token) : JSONDefault();
    return fetch(url, {
        method: 'PUT',
        headers,
        body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => (data))
    .catch((err) => {
      console.log(err);
    });
}

export const putDataFiles = (url: any, body: any, token?: any) => {
    //console.log('DATOS', body);
    const headers = token ? MultiPartOptions(token) : MultiPart();
    return fetch(url, {
        method: 'PUT',
        headers,
        body: body
    })
    .then(response => response.json())
    .then(data => {return (data);})
    .catch((err) => {
      console.log(err);
    });
}

export const MultiPart = () => {
    let headers = new Headers();
    // headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'multipart/form-data');
    return headers;
}

export const MultiPartOptions = (token?: any) => {
    let headers = new Headers();
    // headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'multipart/form-data');
    if (token) {
        headers.append('Authorization', 'Bearer ' + token);
    }
    return headers;
}

export const logout = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
}


export const getDataOctet = (url: any, token: any) => {
    const headers = JSONOptions(token);
    return fetch(url, {
        method: 'GET',
        headers
    })
    .then(response => {
        const data = response.text().then( data1 => {
                return data1;
            }
        );
        return data;
    }).then(data => {
        return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export const postDataOctet = (url: any, body: any, token: any) => {
    const headers = JSONOptions(token);
    return fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
    })
    .then(response => {
        const data = response.text().then( data1 => {
                return data1;
            }
        );
        return data;
    }).then(data => {
        return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export const postDataOctetPDF = (url: any, body: any, token: any) => {
    const headers = JSONOptions(token);
    return fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
    })
    .then( res => res.blob() )
    .then((r) => {
        var blob = new Blob([r], {type: 'application/pdf'});
        saveAs(blob, 'history.pdf')
      })
    .catch((err) => {
      console.log(err);
    });
}
