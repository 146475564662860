import moment from 'moment';
import { useState, useEffect, useRef } from 'react';
import {
  DatePicker, Popover, Tag, Collapse, List, message, Layout, Form, Select, Switch, Button, Row, Col, Tooltip,
  Input, Menu, Drawer, Table, Checkbox, Dropdown, Modal, Radio, Pagination
} from 'antd';
import { SearchOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useAssetState, useAssetDispatch } from '../../hooks/asset';
import { useRawMessageState, useRawMessageDispatch } from '../../hooks/rawMessage';
import { useAssetTypeState, useAssetTypeDispatch } from '../../hooks/assetType';
import { useDeviceDispatch, useDeviceState } from '../../hooks/device';
import { useCampusDispatch, useCampusState } from '../../hooks/campus';
import { useBuildingDispatch, useBuildingState } from '../../hooks/building';
import { useFloorPlanDispatch, useFloorPlanState } from '../../hooks/floorPlan';
import { postRequest } from '../../config/httpClient';
import * as datasets from "../../config/datasets";
import { useCustomerState } from '../../hooks/customer';
import { SideBar } from '../Shared/SideBar';
import { NavBar } from '../Shared/NavBar';
import { Content } from 'antd/lib/layout/layout';
import { DESCEND, ASCEND, iconsListAsset, EVENTS } from '../../config/constants';
import { useLoginDispatch, useLoginState } from '../../hooks/login';
import { SERVER } from '../../config/serverConfig';
//import { Console } from 'console';
const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'Date/Time UTC',
    dataIndex: 'timestamp',
    key: 'timestamp',
    width: '8%',
    // visible: listColumnShowed.includes('timestamp')
  },
  {
    title: 'ESN',
    dataIndex: 'esn',
    key: 'esn',
    width: '6%',
  },
  {
    title: 'IMEI',
    dataIndex: 'imei',
    key: 'imei',
    width: '6%',
  },
  {
    title: 'Event type',
    dataIndex: 'eventType',
    key: 'eventType',
    width: '5.5%',
  },
  {
    title: 'Event name',
    dataIndex: 'eventName',
    key: 'eventName',
    width: '6.5%',
  },
  {
    title: 'Raw Message',
    dataIndex: 'rawMessage',
    key: 'rawMessage',
    width: '21%',
    // visible: listColumnShowed.includes('timestamp')
  },
  {
    title: 'Sending IP',
    dataIndex: 'sendingIp',
    key: 'sendingIp',
    width: '7.5%',
    // visible: listColumnShowed.includes('asset_name')
  },
  {
    title: 'Sending Port',
    dataIndex: 'sendingPort',
    key: 'sendingPort',
    width: '7.5%',
    // visible: listColumnShowed.includes('asset_name')
  },
  {
    title: 'Sending Protocol',
    dataIndex: 'sendingProtocol',
    key: 'sendingProtocol',
    width: '7.5%',
    // visible: listColumnShowed.includes('asset_name')
  },
  {
    title: 'Receiving IP',
    dataIndex: 'receivingIp',
    key: 'receivingIp',
    width: '7.5%',
    // visible: listColumnShowed.includes('asset_name')
  },
  {
    title: 'Receiving Port',
    dataIndex: 'receivingPort',
    key: 'receivingPort',
    width: '7.5%',
    // visible: listColumnShowed.includes('asset_name')
  },
  {
    title: 'Receiving Protocol',
    dataIndex: 'receivingProtocol',
    key: 'receivingProtocol',
    width: '7.5%',
    // visible: listColumnShowed.includes('asset_name')
  },
];

export const ReportsMessages = () => {

  // const { messagesDevices } = useDeviceDispatch();
  const { searchAssets, saveAsset, updateAsset, deleteAsset, searchAssetIcons } = useAssetDispatch();
  const { searchRawMessage } = useRawMessageDispatch();
  const { searchAssetTypes } = useAssetTypeDispatch();
  const { searchCampus } = useCampusDispatch();
  const { searchBuilding } = useBuildingDispatch();
  const { searchFloorPlan } = useFloorPlanDispatch();
  const { listDevices, searchDevices } = useDeviceDispatch();


  const devices = useDeviceState()
  const assetList = useAssetState();
  const assetTypeList = useAssetTypeState();
  const campusList = useCampusState();
  const buildingList = useBuildingState();
  const floorPlanList = useFloorPlanState();
  const customers = useCustomerState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [assetUpdate, setAssetUpdate] = useState({});
  const [form] = Form.useForm();
  const [params, setParams] = useState({});
  const [paramCampus, setParamCampus] = useState({});
  const [filteredInfo, setFilteredInfo] = useState([]);
  const inputSearch = useRef(null);
  const [visible, setVisible] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [dropTitle, setDropTitle] = useState([]);
  const [iconsList, setIconsList] = useState([]);
  const [buildings, setBuildings] = useState<any>([]);
  const [floorPlans, setFloorPlans] = useState([]);
  const [assetId, setAssetId] = useState('');
  const [iconSelected, setIconSelected] = useState('');
  const [filters, setFilters] = useState({});
  const [assetTypeSelected, setAssetTypeSelected] = useState({ activity: false, asset_tag: false, color: false, comments: false, description_asset: false, make: false, manufacturer: false, model: false, part_number: false, sku: false, serial_number: false, usability: false, vin: false, icon: '' })

  const [showColumns, setShowColumns] = useState<any>(columns.slice(0, 6));
  const [valueColumns, setValueColumns] = useState<any>('small');

  const data = useLoginState();
  //console.log('PROFILEEEEE', data.userProfile);
  const userProfile = data.userProfile;

  // IMPORTANT TO REPORTS
  //const [listMessagesDevice, setListMessagesDevice] = useState<any>([]);
  const rawMessages = useRawMessageState();
  const [listColumnShowed, setListColumnShowed] = useState<any>(['asset_name', 'asset_type_name', 'timestamp', 'device_name']);
  const [showAType, setShowAType] = useState(false)


  useEffect(() => {
    const token: string = datasets.getToken()!;
    const data2 = {}
    retrieveData();
    /*datasets.postData(SERVER.GET_MESSAGES_REPORT_DEVICE(), data2, token).then((res: any) => {
      console.log('Resp Messages Devices', res)
      setListMessagesDevice([...res.elements])
    });*/
    // retrieveData()
  }, []);

  useEffect(() => {
    console.log('change customer selected', userProfile);
    if (userProfile.email !== '') {
      retrieveData();
    }

  }, [customers.customerSelected]);

  useEffect(() => {
    retrieveData(1);
  }, [filters]);

  const fillForm = async (values: any) => {
    setVisible(true);
    setIsUpdate(true);
    console.log('fill asset', values, form.getFieldsValue());
    setShowIcons(!values.inherit_icon)
    setAssetId(values._id);
    console.log('CAMPUS', values.campus_id);
    if (values.campus_id) {

      searchBuilding({ campus_id: values.campus_id });
      console.log('BUILDINGS', buildingList.listBuilding);
      setBuildings([...buildingList.listBuilding]);
      searchFloorPlan({ building_id: values.building_id });
      console.log('FLOOR PLANS', floorPlanList.listFloorPlan);
      setFloorPlans(floorPlanList.listFloorPlan as any);
    }
    let assetFill = Object.assign({}, values)
    assetFill.devices = values.devices.map((tag: any) => tag._id)
    assetFill.asset_type = values.asset_type._id
    console.log('assetFill', assetFill)
    onSelectAssetType(values.asset_type._id)
    setIconSelected(values.icon)
    form.setFieldsValue(assetFill);
    //console.log('FORM', form);
    setAssetUpdate(assetFill);
  }

  const exportCSV = async () => {
    const exportColumns = showColumns.map((element: any) => {
      if (element.dataIndex === 'timestamp') {
        return "eventDate";
      }
      return element.dataIndex;
    });
    const token: string = datasets.getToken()!;
    const data2 = { columns: exportColumns, filters: filters }
    datasets.postDataOctet(SERVER.EXPORT_CSV_RAW_MESSAGES(), data2, token).then(res => {
      console.log('respuesta octet CSV RAW', res)
      const value = "" + res;
      let csvData = new Blob([value], { type: 'text/csv;charset=utf-8;' });
      let csvURL = window.URL.createObjectURL(csvData);
      let tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'activity.csv');
      tempLink.click();
      // dispatch({ type: 'download', res });
    });
  }

  const exportCSV3 = async () => {
    const exportColumns = showColumns.map((element: any) => {
      if (element.dataIndex === 'timestamp') {
        return "eventDate";
      }
      return element.dataIndex;
    });
    const token: string = datasets.getToken()!;
    const data2 = { columns: exportColumns, filters: filters }
    const dataRawMonth = await datasets.getDataOctet(SERVER.DOWNLOAD_FILE('devicerawmessage.csv'), token).then(res => {
      console.log('response download file', res)
      const value = "" + res;
      let csvData = new Blob([value], { type: 'text/csv;charset=utf-8;' });
      let csvURL = window.URL.createObjectURL(csvData);
      let tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'devicerawmessage.csv');
      tempLink.click();
      // dispatch({ type: 'download', res });
    });
    console.log('opcions', dataRawMonth)
  }
 
  const exportPDF = async () => {
    const exportColumns = showColumns.map((element: any) => {
      if (element.dataIndex === 'timestamp') {
        return "eventDate";
      }
      return element.dataIndex;
    });
    const token: string = datasets.getToken()!;
    const data2 = { filters, columns: exportColumns };
    console.log('TESTTESTTESTEXPORT', data2);
    datasets.postDataOctetPDF(SERVER.EXPORT_PDF_RAW_MESSAGES(), data2, token).then(res => {
      console.log('respuesta octet PDF RAW MESSAGES', res)
    });
  }

  const showDrawer = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }

  const retrieveData = (page: any = 1) => {
    console.log('retrieve Data', customers.customerSelected, userProfile.is_admin, page)
    if (userProfile.is_admin) {
      searchRawMessage({ customer_id: customers.customerSelected._id ? customers.customerSelected._id : '', page: page, limit: 10, filters: filters });
    } else {
      if (!customers.customerSelected._id) {
        searchRawMessage({ customer_id: '123431242134', page: page, limit: 10, filters: filters });
      } else {
        searchRawMessage({ customer_id: customers.customerSelected._id, page: page, limit: 10, filters: filters });
      }
    }
  }

  const clearAll = () => {
    setFilteredInfo([]);
    setDropTitle([])
  }

  const onClose = () => {
    setVisible(false);
  }

  const clearForm = () => {
    form.resetFields();
  }

  const searchFilter = (element: any) => {
    let textSearch = '';
    if (element.current && element.current.state.value !== undefined) {
      textSearch = element.current.state.value
    } else if (element.target && element.target.value !== undefined) {
      textSearch = element.target.value
    }
    //console.log('IMEI/ESN', textSearch);
    setFilters(
      (prev: any) => ({
        ...prev,
        // imei or esn
        imei_esn: textSearch,
      })
    )
  }

  const showConfirmModal = (id: any) => {
    //setPartnerId(id);
    Modal.confirm({
      title: "Remove Asset",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to remove Asset?',
      async onOk() {
        await deleteAsset(id);
        retrieveData();
      }
    });
  }
  const onSelectAssetType = (element: any) => {
    console.log(element)
    const devtype: any = assetTypeList.listAssetTypes.find((type: any) => type._id === element)
    //console.log('devtype on', devtype)
    if (devtype) {
      setAssetTypeSelected(devtype)
      setIconSelected(devtype.icon)
    }
  }

  const handleRangeChangePicker = (e: any) => {
    if (e) {
      const startDate = e![0]!.toDate();
      const endDate = e![1]!.toDate();

      const intlDateObj = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'America/New_York' });
      setFilters(
        (prev: any) => ({
          ...prev,
          start_date: intlDateObj.format(startDate),
          end_date: intlDateObj.format(endDate)
        })
      )
    } else {
      setFilters(
        (prev: any) => {
          const { ['start_date']: value1, ['end_date']: value2, ...rest } = prev;
          return rest;
        }
      )
    }
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    console.log("my current", current, moment().endOf('day'), moment("20220201"))
    return current && current > moment("20220201") || current < moment("20220101");
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <SideBar></SideBar>
      <Layout>
        <NavBar></NavBar>
        <Layout className="site-layout">
          <Content className="user table-general">
            <Row>
              <Col xs={24} lg={24}>
                <div className="card-white">
                  <div className="head-t">
                    <Row>
                      <Col xs={24} lg={15}>
                        <RangePicker
                          onChange={handleRangeChangePicker}
                          style={{ marginRight: 10 }}
                        />
                        <Input
                          allowClear
                          onChange={(e: any) => {
                            if (e.type === 'click' && e.target.value === '') {
                              searchFilter(e);
                            }
                          }}
                          onPressEnter={() => searchFilter(inputSearch)}
                          ref={inputSearch} placeholder="IMEI/ESN"
                          prefix={<SearchOutlined />}
                          style={{ width: 150 }} />

                        <Button className="btn-blue" onClick={() => searchFilter(inputSearch)}>Search</Button>
                        {/* Align to the the right the buttons*/}
                        {/* <label>Filter Columns:</label>
                        <Select
                          placeholder="Please select Columns to show"
                          mode={'multiple'}
                          onChange={onSelectColumn}
                          style={{ width: 250 }}
                          optionFilterProp='label'

                        >
                          {columns.map((column: any) => <Option value={column.key} label={column.title}>{column.title}</Option>)}
                        </Select> */}

                      </Col>

                      <Col xs={24} lg={9} style={{ textAlign: 'right' }}>
                        <DatePicker onChange={exportCSV3} picker="month" disabledDate={disabledDate} />
                        <Button className="btn-green" onClick={exportPDF}>PDF</Button>
                        <Button className="btn-green" onClick={exportCSV}>CSV</Button>
                        <Radio.Group
                          value={valueColumns}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (newValue === 'small') {
                              setShowColumns(columns.slice(0, 6));
                            } else {
                              setShowColumns(columns);
                            }
                            setValueColumns(newValue);
                          }}

                          style={{ marginLeft: 10 }}
                        >
                          <Radio.Button value='small'><i className="mdi mdi-table"></i></Radio.Button>
                          <Radio.Button value='large'><i className="mdi mdi-table-large"></i></Radio.Button>

                        </Radio.Group>
                      </Col>
                    </Row>
                  </div>

                  <Table

                    dataSource={rawMessages.listRawMessage.map((device: any, index: any) => {
                      //console.log('timestamp', device);
                      //const test_date = new Date(device.dateTimeUtc);
                      //console.log(device.dateTimeUtc, test_date, +device.dateTimeUtc);
                      const event = EVENTS.find((event: any) => {
                        return event.eventType === +device.eventType;
                      });
                      let eventName;
                      if (event) {
                        eventName = event.eventName;
                      } else {
                        eventName = "-";
                      }
                      return ({
                        check: <Checkbox></Checkbox>,
                        key: device._id,
                        rawMessage: device.rawMessage,
                        sendingIp: device.sendingIp,
                        sendingPort: device.sendingPort,
                        sendingProtocol: device.sendingProtocol,
                        receivingIp: device.receivingIp,
                        receivingPort: device.receivingPort,
                        receivingProtocol: device.receivingProtocol,
                        address: device.address,
                        created_at: device.created_at,
                        latlong: device.latitude + ', ' + device.longitude,
                        network: device.network,
                        esn: device.esn,
                        imei: device.imei,
                        eventType: device.eventType,
                        eventName: device.event[0]?.eventName,
                        timestamp: new Date(device.eventDate).toUTCString(),//(new Date(parseInt(device.dateTimeUtc)).toUTCString()),
                        actions: <>
                          <Tooltip title="Edit"><Button className="btn-edit" onClick={() => fillForm(device)}><i className="mdi mdi-pencil"></i></Button></Tooltip>
                          <Tooltip title="Delete"><Button className="btn-delete" onClick={() => showConfirmModal(device._id)}><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
                        </>,
                      })
                    })} columns={showColumns} pagination={false} scroll={{ y: 'calc(100vh - 267px)' }} />
                  {

                    (
                      rawMessages !== undefined &&
                      rawMessages.pagination !== undefined &&
                      rawMessages.pagination.total !== undefined &&
                      (rawMessages.pagination.total > 0)
                    ) &&
                    <Pagination
                      style={{
                        float: 'right',
                        marginTop: '10px',
                        marginBottom: '10px',
                        marginRight: '1.8%',
                      }}
                      current={rawMessages.pagination.page}
                      total={rawMessages.pagination.total}
                      pageSize={10}
                      showSizeChanger={false}
                      onChange={(pageNumber: any) => retrieveData(pageNumber)}
                    />
                  }
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout >
  );
}
