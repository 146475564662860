import { AssetTypeState, SHOW_ASSETTYPES, SEARCH_ASSETTYPES, AssettypType, GET_ASSET_TYPE_MAP } from '../types'

const initialState = {
    listAssetTypes: [],
    pagination: {},
    listAssetTypesMap: [],
}
export const assettypeReducer = (state: AssetTypeState = initialState, action: AssettypType) => {
    switch (action.type) {
        case SHOW_ASSETTYPES:
            return Object.assign({}, state, { listAssetTypes: action.data })
        case SEARCH_ASSETTYPES:
            return Object.assign({}, state, { listAssetTypes: action.data, pagination: action.pagination })
        case GET_ASSET_TYPE_MAP:
            return Object.assign({}, state, { listAssetTypesMap: action.data })
        default:
            return state
    }
}