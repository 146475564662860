import React, { useRef, useState, useEffect } from "react";
import { Button, Tooltip } from 'antd';
import { Redirect, useLocation, Link } from "react-router-dom";
import MapContext from "./MapContext";
import * as ol from "ol";

import {
  Circle as CircleStyle,
  Fill,
  Icon,
  Stroke,
  Style,
  Text
} from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import Overlay from 'ol/Overlay';
import ImageWMS from 'ol/source/ImageWMS';
//import { toSize }  from 'ol/Size';
import ImageLayer from 'ol/layer/Image';
import { boundingExtent, Extent } from 'ol/extent';
import Projection from "ol/proj/Projection";
import { transformExtent, fromLonLat, transform, toLonLat } from 'ol/proj'
import Static from 'ol/source/ImageStatic';
import VectorSource from "ol/source/Vector";
import { getCenter } from 'ol/extent';
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import OSM from 'ol/source/OSM'
import Cluster from 'ol/source/Cluster'
import XYZ from 'ol/source/XYZ'
import Point from 'ol/geom/Point'
import GeomType from 'ol/geom/GeometryType'
import Draw from 'ol/interaction/Draw'
import { createCenterConstraint } from "ol/View";
import Feature from 'ol/Feature';
import * as datasets from "../../config/datasets";
import { getRequest, postRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { useDeviceDispatch, useDeviceState } from '../../hooks/device';
import { useCustomerState } from '../../hooks/customer';
import { useAssetDispatch, useAssetState } from '../../hooks/asset';
import { useLoginDispatch, useLoginState } from '../../hooks/login';
import { toStringHDMS } from 'ol/coordinate';
import { Devices } from "../Devices/DevicesView";
import { Dropdown } from "antd";
import { useAddressDispatch, useAddressState } from "../../hooks/address";


let currentFloor = 'all'
let showAsset = -1;

let listLayersFloors: any[] = []

// TO FLOORS ASSET
let listFloorMarkersAsset: VectorSource[] = []
for (let i = 0; i < 4; i++) {
  listFloorMarkersAsset.push(new VectorSource)
}

let listLayersAsset: VectorLayer[] = []

var clusterSource = new Cluster({
  //distance: parseInt(distance.value, 10),
  source: listFloorMarkersAsset[0],
});

/* var styleFunction = function (feature: any) {
  console.log('rubick', feature, feature.getProperties().features[0].getProperties().icon)
      let newIcon = feature.getProperties().features[0].getProperties().icon
      console.log('new Icon', newIcon.substring(0, newIcon.length-4), showAsset)
      
      return (new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: newIcon ? 'Images/assetpng/'+ newIcon.substring(0, newIcon.length-4) +'.png': 'Images/map/02.svg',
          scale: 0.2
          //imgSize: [525,525]
        }),
      }))
}; */



for (let i = 0; i < 4; i++) {
  listLayersAsset.push(new VectorLayer({
    source: new Cluster({
      //distance: parseInt(distance.value, 10),
      distance: 50,
      source: listFloorMarkersAsset[i],
    }),
    // style: styleFunction
    style: function (feature: any) {
      var size = feature.get('features').length;
      //console.log('rubick', feature, feature.getProperties().features[0].getProperties().icon)
      let newIcon = feature.getProperties().features[0].getProperties().devices[0].icon
      //console.log('new Icon', newIcon.substring(0, newIcon.length-4), showAsset)
      /* return (new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'Images/map/02.svg',
        }),
      })) */
      if (size === 1) {
        return (new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            src: newIcon ? 'Images/assetpng/' + newIcon.substring(0, newIcon.length - 4) + '.png' : 'Images/map/02.svg',
            scale: 0.2
            //imgSize: [525,525]
          }),
        }))
      } else {
        return (new Style({
          image: new CircleStyle({
            radius: 15,
            stroke: new Stroke({
              color: '#fff',
            }),
            fill: new Fill({
              color: '#f8bb12',
            }),
          }),
          text: new Text({
            text: size.toString(),
            fill: new Fill({
              color: '#fff',
            }),
          }),
        }))
      }
    }
    //source: listFloorMarkersAsset[i],
    /* style: new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: 'Images/map/02.svg',
        //anchor: [1, 1],
        //anchor: [50, 50],
        //anchorOrigin: bottom_right,
        //crossOrigin: 'anonymous',
        //anchorXUnits: fraction,
        //anchorYUnits: fraction,
        // img: context.canvas,
        //offset: [0, 0],
        // scale: 0.5,
        //src: '/Images/assetpng/12000.svg',
        //src: 'data:image/svg+xml;utf8,' + svg,
        //displacement: [1,1],
        //size: [340, 480]
        //color: "rgb(255,0,0)",
        // imgSize: [525,525]
      }),
    }), */
  }))
}


// TO FLOORS DEVICE
let listFloorMarkers: VectorSource[] = []
for (let i = 0; i < 4; i++) {
  listFloorMarkers.push(new VectorSource)
}

let listLayers: VectorLayer[] = []


for (let i = 0; i < 4; i++) {
  listLayers.push(new VectorLayer({
    source: listFloorMarkers[i],
    style: new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: '/Images/assetIcons/Ambulance.svg'
        // src: 'Images/map/02.svg',
      }),
      text: new Text({
        text: 'QQQQQ',
        fill: new Fill({
          color: '#fff',
        }),
      }),
    }),
  }))
}

// TO FLOORS IMAGE LAYERS
let listFloorImageLayer: Static[] = []
for (let i = 0; i < 4; i++) {
  listFloorImageLayer.push(new Static({ url: '' }))
}

let listLayersImg: ImageLayer[] = []


for (let i = 0; i < 4; i++) {
  listLayersImg.push(new ImageLayer({
    source: listFloorImageLayer[i]
  }))
}



let centerMain = transform([-86.74879455566406, 35.97605514526367], 'EPSG:4326', 'EPSG:3857')
//let extent: any = [0, 0, 1024, 968];
// var extent = transformExtent([-79.180360, 37.351251, -79.179148, 37.349640], 'EPSG:4326', 'EPSG:2757');
//var extent = transformExtent([-122.312656, 47.619241, -122.311483, 47.618548], 'EPSG:4326', 'EPSG:2757');
var extent = transformExtent([-122.312656, 47.619241, -122.311483, 47.618548], 'EPSG:4326', 'EPSG:3857');
//console.log('MI ESTENT  ', getCenter(extent), extent)
var extent2 = transformExtent([-122.311403, 47.619066, -122.310819, 47.618790], 'EPSG:4326', 'EPSG:3857');
//var extent3 = transformExtent([-122.311403, 47.619066, -122.310819, 47.618790], 'EPSG:4326', 'EPSG:2757');
var extent3 = transformExtent([-86.748996, 35.976176, -86.748653, 35.975882], 'EPSG:4326', 'EPSG:3857');
// var extentPlane = transformExtent([-86.7488846045132, 35.9761454970117, -86.7486794153319, 35.9759278932055], 'EPSG:4326', 'EPSG:3857');
var extentPlane = transformExtent([-86.7489616045132, 35.9761444970117, -86.7486794153319, 35.9759278932055], 'EPSG:4326', 'EPSG:3857');
// var extentPlane: Extent = [-86.7489616045132, 35.9761444970117, -86.7486794153319, 35.9759278932055];
var extenttest = transformExtent([-122.311403, 47.609066, -122.310819, 47.618790], 'EPSG:4326', 'EPSG:3857');
var extent6 = transformExtent([-86.7864488, 35.9755998, -86.7864488, 35.9755898], 'EPSG:4326', 'EPSG:3857');
let extentAux: Extent = [-13615782.583492838, 6043627.811073756, -13615652.005730135, 6043742.258661613];


//console.log('latlongix', extentPlane)

var projection = new Projection({
  code: 'xkcd-image',
  units: 'pixels',
  extent: extentPlane,
});

var layer = new TileLayer({
  source: new OSM()
});

var worldImagery = new TileLayer({
  source: new XYZ({
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    maxZoom: 19
  })
});

var imageq = new ImageLayer({
  source: new Static({
    attributions: '© <a href="http://xkcd.com /license.html">xkcd</a>',
    url: 'Images/Hospital.png',
    //projection: projection,
    imageExtent: extenttest,
  }),
})

var sourceVector = new VectorSource({
  features: [
    /* new ol.Feature({
      type: 'icon',
      geometry: new Point(fromLonLat([-86.74879455566406, 35.97605514526367])),
    }),*/
    new ol.Feature({
      type: 'icon',
      geometry: new Point(fromLonLat([-122.312056, 47.619892])),
    }),
    new ol.Feature({
      type: 'icon',
      geometry: new Point(fromLonLat([-122.312870, 47.618614])),
    }),
    new ol.Feature({
      type: 'icon',
      geometry: new Point(fromLonLat([-122.311314, 47.618628])),
    }),
    new ol.Feature({
      type: 'icon',
      geometry: new Point(fromLonLat([-122.3134483, 47.6190853])),
    }),
    new ol.Feature({
      type: 'icon',
      geometry: new Point(fromLonLat([-122.312552, 47.619112])),
    })
  ]
})


var layerMarker = new VectorLayer({
  source: sourceVector,
  style: new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: 'Images/map/02.svg',
    }),
  }),
});

var image2 = new ImageLayer({
  source: new Static({
    attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
    url: 'Images/Hospital2.png',
    //projection: projection,
    imageExtent: extent2,
  }),
})

var image3 = new ImageLayer({
  source: new Static({
    attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
    url: 'Images/Hospital2.png',
    //projection: 'EPSG:4326',
    imageExtent: extentPlane,
  }),
})

var image4 = new ImageLayer({
  source: new Static({
    attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
    url: 'Images/Hospital3.jpg',
    //projection: 'EPSG:3857',
    imageExtent: extentPlane,
  }),
})

var style = new Style({
  text: new Text({
    font: 'bold 40px "Open Sans", "Arial Unicode MS", "sans-serif"',
    placement: 'line',
    fill: new Fill({
      color: 'Blue',
    }),
  }),
});


var textLayer = new VectorLayer({
  declutter: true,
  source: new VectorSource({
    format: new GeoJSON(),
    url: 'data/geojson/countries.geojson',

  }),
  style: function (feature) {
    // style.getText().setText(feature.get('name'));
    style.getText().setText('Robs Casa');

    return style;
  },
  /* style: new Style({
    text: new Text({
      font: '12px Calibri,sans-serif',
      overflow: true,
      fill: new Fill({
        color: '#000',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    }),
  }); */

})

var buildingLayer = new ImageLayer({
  source: new Static({
    // attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
    url: 'Images/HospitalLayer.png',
    //projection: 'EPSG:4326',
    imageExtent: extentPlane,
  })
})

/*var image4 = new ImageLayer({
  source: new Static({
    attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
    url: 'Images/Hospital2.png',
    //projection: projection,
    imageExtent: extent3,
  }),
}) */

var source = new VectorSource({ wrapX: false });

var vector = new VectorLayer({
  source: source,
});
var raster = new TileLayer({
  source: new OSM(),
});

let long = -86.748720
let lat = 35.975691


let imagen = ""
//console.log('render MAPA')
// PARA EL MAPA
let options = {

  view: new ol.View({
    //projection: 'EPSG:4326',
    zoom: 21,
    //center: getCenter(extent)
    center: getCenter(extentPlane)
  }),
  layers: [
    layer,
    //  ,
    imageq,
    //image2,
    //image3,
    //image4,
    //layerMarker,
  ],
  controls: [],
  //overlays: []
};
let mapObject: any = new ol.Map(options);
let count = 4


var overlay: Overlay


let auxLayer = new ImageLayer
let overlayId = 0;

const MapOpenLayers = ({ children, zoom, center, setFloor, onSelectMarker, floorImages, retrieveData }: any) => {
  const mapRef: any = useRef();
  const address = useAddressState();
  const { loadAddressesByIds } = useAddressDispatch();
  const [map, setMap]: any = useState(null);
  const [devicesCoord, setDevicesCoord] = useState([]);
  const [baseMap, setBaseMap] = useState("");
  //const [listFloors, setListFloors] = useState([])
  const customers = useCustomerState();
  const data = useLoginState();
  const userProfile = data.userProfile
  // -----------------------------------
  // on component mount

  const { listDevices, getDataMap } = useDeviceDispatch();
  const devices = useDeviceState()
  const { getAssetMap, getAssetsMapArray } = useAssetDispatch();
  const assets = useAssetState()

  const getImageDatabase = async () => {
    console.log('getImageDatabase:');
    const token: string = datasets.getToken()!;
    await getRequest(SERVER.GET_FLOORPLAN_IMAGE(), token).then(async result => {
      console.log('Image Floor Plan', result)
      if (result.message === 'OK') {

        console.log('setvalor2', baseMap, result.result.image_base64)
        // debugger
        imagen = 'data:image/png;base64,' + result.result.image_base64
        // setBaseMap(result.result.image_base64);
        let sourceImage = new Static({
          attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
          url: imagen,
          // projection: projection,
          imageExtent: extentPlane,
        })
        auxLayer = new ImageLayer({
          source: new Static({
            attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
            url: imagen,
            //projection: 'EPSG:4326',
            imageExtent: extentPlane,
          }),
        })
        //listFloorImageLayer[0].setProperties({source: sourceImage})
        mapObject.addLayer(worldImagery)
        // console.log('we dont talk', listFloorImageLayer[0].getProperties(), image3.getProperties())
        //FIRST FLOOR
        mapObject.addLayer(auxLayer)
        //mapObject.addLayer(listLayersImg[0])
        // SECOND FLOOR
        mapObject.addLayer(image3)
        // THIRD FLOOR
        mapObject.addLayer(image4)
        //mapObject.addLayer(layerMarker)
        /* listLayers.forEach(element => {
          mapObject.addLayer(element)
        }); */
        mapObject.addLayer(buildingLayer)
        listLayersAsset.forEach(element => {
          mapObject.addLayer(element)
        });


        //mapObject.addLayer(textLayer)

        // listLayersAsset[0].setVisible(false)
        worldImagery.setVisible(false)
        // image3.setVisible(false)
        // image4.setVisible(false)

      }
      // getDataMap()
      // getAssetMap({})


      //setBaseMap("se asdfr ")

      //console.log('setvalor', baseMap, imagen)
      /*getRequest(SERVER.GET_FLOORPLAN_IMAGE(), token).then(async result => {
        console.log('Image Floor Plan',result)

      });*/
    });

    /*datasets.getData(SERVER.REGISTER_CUSTOMER_FILE(), datasets.getToken()).then(res => {
      console.log('wirepass', res)
    }) */

  }

  const getCoordDev = async () => {
    console.log('getcoordev:');
    const token: string = datasets.getToken()!;
    await getRequest(SERVER.GET_DATA_MAP(), token).then(async result => {
      console.log('get data modal', result)
      setDevicesCoord(prevResult => prevResult)
    })
  }

  const getFloors = async () => {
    console.log('get floors')
    const token: string = datasets.getToken()!;
    await getRequest(SERVER.GET_FLOORPLANS(), token).then(async result => {
      console.log('get FLOOR PLANS', result)
      //setDevicesCoord(prevResult => prevResult)
    })
  }

  useEffect(() => {
    addLayersMap()

  }, [floorImages])

  useEffect(() => {
    //getFloors()
    //getDataMap()

    //listDevices();
    //console.log('mapobje', mapObject.getLayers().getArray().length, mapObject.getOverlays())


    var container: any = document.getElementById('popup');
    var content: any = document.getElementById('popup_content')
    var closer: any = document.getElementById('popup-closer');

    closer.onclick = function () {
      console.log('click close')
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };
    overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
      offset: [0, -25],
    });
    mapObject.addOverlay(overlay)
    overlay.setPosition(undefined);


    //overlay.setPosition(undefined);


    //PARA EL POPUP
    /* let intervalMarkers = setInterval(updatePoints, 1000 * 10)
    function updatePoints() {
      //getDataMap()
      console.log('cada 10 segundos', sourceVector.getFeatures())
      //if (location.pathname === '/map') {
      if (document.getElementById('mapa') !== null) {
        console.log('EXEC getdata')

        retrieveData([])
        
      } else {
        clearInterval(intervalMarkers)
      }
    } */

    mapObject.setTarget(mapRef.current);

    let draw = new Draw({
      source: source,
      type: GeomType.POLYGON,
    })
    let contador = 4

    // LAYER AUXILIAR
    //mapObject.addInteraction(draw)

    const onClickFeature = function (e: any) {

      console.log('click en el mapa', e, e.coordinate, baseMap)


      /**********
        SEARCH AND CHANGE COORDINATES 
      listFloorMarkersAsset[0].getFeatures().forEach(element => {
        //console.log('**', element.get('name'));
        if (element.get('name') === 'Asset B2p') {
          const featureGeometry: any = element.getGeometry();
          const coordinates: any = featureGeometry.getCoordinates();
          console.log('*z', coordinates);
          //console.log('*z', (<ol.geom.Point> element.getGeometry()).getCoordinates());
          const currentLatitude = coordinates[1];
          const currentLongitude = coordinates[0];
          // add to the coordinates a little bit to the north and east
          const newCoordinates = [currentLongitude + 10, currentLatitude + 10];
          featureGeometry.setCoordinates(newCoordinates);
          //console.log(currentLatitude, currentLongitude);
          //element.setGeometry(new Point([-5.988, -35.203]))
        }
      });
      **********/

      // var overlay = mapObject.getOverlays().getArray()[0]
      contador = contador + 1
      console.log('contador', contador)
      if (contador === 4) {
        draw.finishDrawing()
        contador = 0
      }

      //PARA EL POPUP 2
      var feature = mapObject.forEachFeatureAtPixel(e.pixel, function (feature: any) {
        console.log('esto retorna', feature.getProperties())
        return feature.getProperties().features[0];
      });
      console.log('feature click', feature)
      if (feature) {
        const featureId = feature.id_
        // address dispatch
        const indexAddress = address.listAddress.findIndex((element: any) => element.asset_id === featureId);
        if (indexAddress === -1) {
          loadAddressesByIds({ids: [featureId]});
        }
        //console.log('m9u21da', feature.getProperties());
        var coordinates = feature.getGeometry().getCoordinates();
        //coordinates[1] = coordinates[1]+1.7
        console.log('estandar', coordinates)

        // IF SHOW OVERLAY ONE CLICK MORE HIDE POPUP
        console.log('overlay position', overlay.getPosition())
        if (overlay.getPosition()) {
          overlay.setPosition(undefined);
        } else {
          overlay.setPosition(coordinates);
          overlayId = featureId;
          //console.log('fkpejfawe', featureId);
        }
        console.log('overlay newPosition', overlay.getPosition());

        //overlay.setPosition(coordinates);
        let devicesxd = [...devices.listDevices]
        let devi: any = devicesxd.find((x: any) => x.device_id === feature.id_)

        console.log('pare', devi, devicesxd.find((x: any) => x.device_id === feature.id_), devicesxd)
        //debugger
        var coordinate = e.coordinate;
        var hdms = toStringHDMS(toLonLat(coordinate));
        console.log('devi', devi, content)
        // var devi =
        //content.innerHTML = '<h1>' + feature.id_ + '</h1><p>Building Name: '+feature.values_.building_name+'</p><p>long, lat: '+feature.values_.longitude+','+feature.values_.latitude+' </p><code>' +  + '</code><p>' + +'</p>';
        //if (feature.values_.asset_type_name) {
        onSelectMarker(feature, overlay)
        //  content.innerHTML = '<h1><b>Asset: ' + feature.values_.name + '</b></h1><div class="comment-popup-body"><div class="content"><p><b>Updated:</b> ' + feature.values_.last_update + '</p><p><b>Area:</b> ' + feature.values_.devices[0].area + '</p><p><b>Floor:</b> ' + feature.values_.devices[0].floor_name + '</p><p><b>Asset Type: </b>' + feature.values_.asset_type_name + '</p><p><b>Latitude:</b> ' + feature.values_.latitude + '</p><p><b>Longitude:</b> ' + feature.values_.longitude + '</p></div><div class="comment-img"><img src="/Images/assetIcons/' + feature.values_.icon + '" width="50px" /></div></div>';
        //content = <Button>hola</Button>
        /* } else {
          content.innerHTML = '<h1><b>Device: ' + feature.values_.device_name + '</b></h1><p>' + feature.values_.last_update + '</p><p><b>Building:</b> ' + feature.values_.building_name + '</p><p><b>Floor:</b> ' + feature.values_.floor_name + '</p><p><b>Device ID :</b> ' + feature.id_ + '</p><p><b>Area:</b>' + feature.values_.area + '</p>';
        } */
      }
      else {
        overlay.setPosition(undefined);
      }

      console.log('el feature', feature)

      console.log('hola addlayer', mapObject.getLayers())
    }

    mapObject.on('click', onClickFeature);
    let miLayer = new ImageLayer({})
    /* draw.on('drawend', (e: any) => {
      console.log(e, extent)
      let extentDraw = e.feature.values_.geometry.extent_
      //miLayer.setOpacity(0.5)
      //miLayer.setVisible(false)
      miLayer = new ImageLayer({
        source: new Static({
          attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
          url: 'Images/Hospital2.png',
          // projection: projection,
          imageExtent: extentDraw,
        }),
      })
      mapObject.addLayer(miLayer)
      //miLayer.setVisible(false)
      console.log('actual layers', mapObject.getLayers())
    })*/
    setMap(mapObject);
    console.log("CREATED CREATED CREATED");
    return () => {
      mapObject.setTarget(undefined);
      overlay.setPosition(undefined);
      mapObject.un('click', onClickFeature);

    };
  }, []);

  // zoom change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [zoom]);
  // center change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(center)
  }, [center])

  const saveOnFloor = (feat: any) => {
    console.log('saveonFloor', feat)
    //var list: VectorSource[] =  []
    //console.log('resistir', list)
    if (feat.values_.floor_name) {
      listFloorMarkers[0].addFeature(feat)
    } else {
      listFloorMarkersAsset[0].addFeature(feat)
    }

    listFloorMarkers[0].addFeature(feat)
    switch (feat.values_.floor_name) {
      case 'First Floor':
        listFloorMarkers[1].addFeature(feat)
        break;
      case 'Second Floor':
        listFloorMarkers[2].addFeature(feat)
        break;
      default:
        // console.log('esto ejecuta siempre')
        // listFloors[0].addFeature(feat)
        break;
    }
    //console.log('list floors', listFloorMarkers[0].getFeatures(), listFloorMarkers[1].getFeatures(), listFloorMarkers[2].getFeatures())
  }

  const saveOnFloorAsset = (feat: any) => {
    //console.log('saveonFloorass', feat)
    const geoFeat: any = new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeature(feat)
    // listFloorMarkersAsset[0].addFeature(feat)


    /* switch (feat.properties.floor_name) {
      case 'First Floor':
        listFloorMarkersAsset[1].addFeature(geoFeat)
        break;
      case 'Second Floor':
        listFloorMarkersAsset[2].addFeature(geoFeat)
        break;
      default:
        break;
    } */
    if (feat.properties.floor_number) {
      listFloorMarkersAsset[feat.properties.floor_number].addFeature(geoFeat)
    }
    /* switch (feat.properties.floor_number) {
      case 1:
        listFloorMarkersAsset[1].addFeature(geoFeat)
        break;
      case 2:
        listFloorMarkersAsset[2].addFeature(geoFeat)
        break;
      default:
        break;
    } */
    //console.log('list floors', listFloorMarkersAsset[0].getFeatures(), listFloorMarkersAsset[1].getFeatures(), listFloorMarkersAsset[2].getFeatures())
  }

  const updateOnFloorAsset = (deviceId: any, feat: any) => {
    console.log('updateFloorAsset', feat)
    if (feat.floor_name) {
      listFloorMarkers[0].getFeatureById(deviceId).setProperties(feat)
    } else {
      listFloorMarkersAsset[0].getFeatureById(deviceId).setProperties(feat)
    }
    listFloorMarkers[0].getFeatureById(deviceId).setProperties(feat)
    switch (feat.floor_name) {
      case 'First Floor':
        listFloorMarkers[1].getFeatureById(deviceId).setProperties(feat)
        break;
      case 'Second Floor':
        listFloorMarkers[2].getFeatureById(deviceId).setProperties(feat)
        break;
      default:
        // console.log('esto ejecuta siempre')
        // listFloors[0].addFeature(feat)
        break;
    }
    console.log('resistir', listFloorMarkers[0].getFeatures(), listFloorMarkers[1].getFeatures(), listFloorMarkers[2].getFeatures())
  }

  const updateOnFloor = (deviceId: any, feat: any) => {
    console.log('updateFloor', feat)
    if (feat.floor_name) {
      listFloorMarkers[0].getFeatureById(deviceId).setProperties(feat)
    } else {
      listFloorMarkersAsset[0].getFeatureById(deviceId).setProperties(feat)
    }
    listFloorMarkers[0].getFeatureById(deviceId).setProperties(feat)
    switch (feat.floor_name) {
      case 'First Floor':
        listFloorMarkers[1].getFeatureById(deviceId).setProperties(feat)
        break;
      case 'Second Floor':
        listFloorMarkers[2].getFeatureById(deviceId).setProperties(feat)
        break;
      default:
        // console.log('esto ejecuta siempre')
        // listFloors[0].addFeature(feat)
        break;
    }
    console.log('resistir', listFloorMarkers[0].getFeatures(), listFloorMarkers[1].getFeatures(), listFloorMarkers[2].getFeatures())
  }

  useEffect(() => {
    /* let featAux = new ol.Feature({
      type: 'icon',
      geometry: new Point(fromLonLat([long, lat])),
    })
    featAux.setId('1234')
    sourceVector.addFeature(featAux) */
    //getCoordDev()


    //console.log('when ', mapObject.getLayers().getArray(), mapObject.getLayers())

    devices.listDeviceMap.forEach(device => {
      if (device.latitude && device.longitude) {
        console.log('Device with lat and long ', device, sourceVector.getFeatureById(device.device_id))
        if (sourceVector.getFeatureById(device.device_id) === null) {
          let featAux = new ol.Feature({
            type: 'icon',
            geometry: new Point(fromLonLat([device.longitude, device.latitude])),
            latitude: device.latitude,
            longitude: device.longitude,
            building_name: device.building_name,
            floor_name: device.floor_name,
            device_name: device.name,
            last_update: device.last_update,
            area: device.area
          })
          featAux.setId(device.device_id)
          saveOnFloor(featAux)
          sourceVector.addFeature(featAux)
        } else {
          console.log(sourceVector.getFeatureById(device.device_id).getProperties())
          let propFeatAux = {
            type: 'icon',
            geometry: new Point(fromLonLat([device.longitude, device.latitude])),
            latitude: device.latitude,
            longitude: device.longitude,
            building_name: device.building_name,
            floor_name: device.floor_name,
            device_name: device.name,
            last_update: device.last_update,
            area: device.area
          }
          updateOnFloor(device.device_id, propFeatAux)
          sourceVector.getFeatureById(device.device_id).setProperties(propFeatAux)
          //sourceVector.getFeatureById(device.device_id).setGeometry(new Point(fromLonLat([device.longitude, device.latitude])))
        }
      }
    });


    /*let intervalMarkers = setInterval(updatePoints, 1000 * 7)
    function updatePoints() {
      //getDataMap()
      console.log('cada 5 segundos', sourceVector.getFeatures())
      //if (location.pathname === '/map') {
      if (document.getElementById('mapa') !== null) {


        devices.listDevices.forEach(device => {
          //console.log(device)

          if (device.latitude && device.longitude) {
            console.log('entro', device)
            sourceVector.getFeatureById(device.device_id).setGeometry(new Point(fromLonLat([device.longitude, device.latitude])))
          }
        });
      } else {
        clearInterval(intervalMarkers)
      }
    }*/



  }, [devices])

  useEffect(() => {

    //console.log('assets change ', mapObject.getLayers().getArray(), mapObject.getLayers())
    listFloorMarkersAsset.forEach((element: any) => {
      element.clear()
    })
    /* 
    TO CHANGE ASSET WITH GET ARRAY
    assets.listAssetMap.forEach(asset => {
      if (asset.latitude && asset.longitude) {
        console.log('Asset with lat and long ', asset, sourceVector.getFeatureById(asset.asset_id))
        let featAux = new ol.Feature({
          type: 'icon',
          geometry: new Point(fromLonLat([asset.longitude, asset.latitude])),
          latitude: asset.latitude,
          longitude: asset.longitude,
          name: asset.name,
          icon: asset.icon,
          // devices: asset.devices,
          device_id: asset.device_id,
          floor_name: asset.floor_name,
          area_name: asset.area_name,
          building_name: asset.building_name,
          devices_name: asset.device_name,
          asset_type_name: asset.asset_type_name,
          last_update: asset.last_update
        })
        featAux.setId(asset.asset_id)
        // saveOnFloor(featAux)
        saveOnFloorAsset(featAux)
      }
    });*/

    /* var count = 20000;
    var features = new Array(count);
    var e = 4500000;
    for (var i = 0; i < count; ++i) {
      var coordinates = [2 * e * Math.random() - e, 2 * e * Math.random() - e];
      features[i] = new Feature(new Point(coordinates));
    }
    listFloorMarkersAsset[0].addFeatures(features) */

    const geoJsonasset = { ...assets.listAssetMap }
    //console.log('comparex', geoJsonasset, assets.listAssetMap);

    if (assets.listAssetMap.type) {
      console.log('entry zzzerqwer', geoJsonasset);
      listFloorMarkersAsset[0].addFeatures(new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(geoJsonasset))

      //search the feature with name "Test Asset PU"
      console.log('*az*', listFloorMarkersAsset[0].getFeatures().filter(feature => feature.get('name') === 'Test Asset PU'));
      // search the feature with the name 'Asset B2p' and change their coordinates
      //console.log('DATAFLOW ')
    }

    assets.listAssetMap.features.forEach(feature => {
      saveOnFloorAsset(feature)
    })

  }, [assets.listAssetMap])

  useEffect(() => {
    //console.log('cambiopiso', currentFloor)
    /*  if (currentFloor === 'all') {
      listLayers.forEach(element => {
        element.setVisible(false)
      });
      listLayers[0].setVisible(true)
    } else {
      listLayers.forEach(element => {
        element.setVisible(false)
      });
      listLayers[parseInt(currentFloor)].setVisible(true)
    }  */
    if (currentFloor === 'all') {
      listLayersAsset.forEach(element => {
        element.setVisible(false)
      });
      listLayersAsset[0].setVisible(true)
    } else {
      listLayersAsset.forEach(element => {
        element.setVisible(false)
      });
      listLayersAsset[parseInt(currentFloor)].setVisible(true)
    }

  }, [currentFloor])

  useEffect(() => {
    //console.log('change a Asset', showAsset)
    if (showAsset === -1) {
      //console.log('style layers', listLayersAsset[0].getStyle())
      /* let style: any = listLayersAsset[0].getStyle()
      style.setImage(new Icon({
        anchor: [0.5, 1],
        src: 'Images/map/02.svg',
      }),) */

      /* listLayersAsset[0].setStyle(function (feature: any) {
        console.log('rubick 1', feature, feature.getProperties().features[0].getProperties().icon)
        return (new Style({
            image: new Icon({
            anchor: [0.5, 1],
            src: 'Images/map/02.svg',
          }),
        })) 
      }) */

      listLayersAsset.forEach(element => {
        element.setVisible(false)
      });
      listLayersAsset[0].setVisible(true)

    } else {
      if (showAsset === 0) {

        /* listLayersAsset[0].setStyle(function (feature: any) {
          console.log('rubick 2', feature, feature.getProperties().features[0].getProperties().icon)
          let newIcon = feature.getProperties().features[0].getProperties().icon
          console.log('new Icon', newIcon.substring(0, newIcon.length-4), showAsset)
          return (new Style({
            image: new Icon({
              anchor: [0.5, 1],
              src: 'Images/map/02.svg',
            }),
          })) 
          
        }) */


        listLayersAsset.forEach(element => {
          element.setVisible(false)
        });
        //listLayersAsset[0].setVisible(false)
      } else {
        //VICO
        console.log('to list features with different icon')
        // const listFeatures = listFloorMarkersAsset[0].getFeatures()
        listLayersAsset[0].setStyle(function (feature: any) {
          console.log('rubick 3', feature, feature.getProperties().features[0].getProperties().devices[0].icon)
          let newIcon = feature.getProperties().features[0].getProperties().devices[0].icon
          console.log('new Icon', newIcon.substring(0, newIcon.length - 4), showAsset)
          /* return (new Style({
            image: new Icon({
              anchor: [0.5, 1],
              src: 'Images/map/02.svg',
            }),
          })) */
          return (new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: newIcon ? 'Images/assetpng/' + newIcon.substring(0, newIcon.length - 4) + '.png' : 'Images/map/02.svg',
              scale: 0.2
              //imgSize: [525,525]
            }),
          }))
        })
        /* listFloorMarkersAsset[0].getFeatures().forEach((feature: any) => {
          console.log('styles pes', feature.setStyle())
          feature.setStyle(new Style({
            image: new Icon({
              anchor: [0.5, 1],
              src: 'Images/map/02.svg',
              //anchor: [1, 1],
              //anchor: [50, 50],
              //anchorOrigin: bottom_right,
              //crossOrigin: 'anonymous',
              //anchorXUnits: fraction,
              //anchorYUnits: fraction,
              // img: context.canvas,
              //offset: [0, 0],
              // scale: 0.5,
              //src: '/Images/assetpng/12000.svg',
              //src: 'data:image/svg+xml;utf8,' + svg,
              //displacement: [1,1],
              //size: [340, 480]
              //color: "rgb(255,0,0)",
              // imgSize: [525,525]
            }),
          }))
        }) */

      }
    }
  }, [showAsset])

  const addLayersMap = () => {
    //console.log('images map tsx', floorImages, mapObject.getLayers().getArray())
    if (mapObject.getLayers().getArray().length === 2 && floorImages.length) {
      // LAYER SATELLITE
      mapObject.addLayer(worldImagery)
      // LAYER FLOORS  
      floorImages.forEach((floorLayer: any) => {
        //console.log('miflooor', floorLayer)
        let layer = new ImageLayer({
          source: new Static({
            attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
            url: floorLayer.image_wirepas,
            //projection: 'EPSG:4326',
            imageExtent: extentPlane,
          }),
        })
        listLayersFloors.push(layer)
        mapObject.addLayer(layer)
      });
      //LAYERS MARKERS
      mapObject.addLayer(buildingLayer)
      listLayersAsset.forEach(element => {
        mapObject.addLayer(element)
      });
      worldImagery.setVisible(false)
    }
  }

  return (
    <MapContext.Provider value={{ map }}>
      <div id='mapa' ref={mapRef} className="ol-map">
        {children}
      </div>
    </MapContext.Provider>
  )
}
export default MapOpenLayers;




export const upfloor = (key: any) => {
  //console.log('UPFLOOR')

  currentFloor = key
  console.log('UPFLOOR', currentFloor, mapObject.getLayers())
  let view = mapObject.getView()
  //image3.getExtent()
  view.fit(extentPlane);
  switch (key) {
    case '0':
      auxLayer.setVisible(true);
      image3.setVisible(true);
      image4.setVisible(true);
      buildingLayer.setVisible(true);
      break;
    case '1':
      console.log('my list of layers', listLayersFloors)
      listLayersFloors[0].setVisible(true)
      //(mapObject.getLayers().getArray())[3].setVisible(true)
      /* auxLayer.setVisible(true);
      image3.setVisible(false);
      image4.setVisible(false); */
      buildingLayer.setVisible(false);
      break;
    case '2':
      listLayersFloors[1].setVisible(true)
      image4.setVisible(false)
      buildingLayer.setVisible(false)
      break;
    case '3':
      listLayersFloors[2].setVisible(true)
      /* auxLayer.setVisible(false)
      image3.setVisible(false)
      image4.setVisible(true) */
      buildingLayer.setVisible(false)
      break;
    case 'all':
      auxLayer.setVisible(true)
      image3.setVisible(true)
      image4.setVisible(true)
      buildingLayer.setVisible(true)
      break;
    default:
      break;
  }
  //mapObject.getLayers().array_[5].setVisible(false)
  //image3.setVisible(!image3.getVisible())

}
export const ChangeMarkersMap = (key: any) => {
  //console.log('chjange markers', key, typeof key)
  //const devicesx = useDeviceState()
  switch (key) {
    case '0':
      currentFloor = 'all'
      console.log('holaax')
      listLayersAsset.forEach(element => {
        element.setVisible(false)
      });
      listLayersAsset[0].setVisible(true)
      break;
    case '1':
      currentFloor = '1'
      break;
    case '2':
      currentFloor = '2'
      break;
    case '3':
      break;
    default:
      break;
  }
}

export const showMarkers = (value: any) => {
  //console.log('show Markers', value)
  //const devicesx = useDeviceState()
  showAsset = value
}

export const getMap = () => {
  //console.log('getMap')
  //const devicesx = useDeviceState()
  return mapObject
}

export const getlistLayersAsset = () => {
  //console.log('get listLayersAsset')
  //const devicesx = useDeviceState()
  return listLayersAsset
}

export const getListFloorMarkersAsset = () => {
  //console.log('get ListFloorMarkersAsset')
  //const devicesx = useDeviceState()
  return listFloorMarkersAsset
}

export const getSatelliteLayer = () => {
  //console.log('getSatelliteLayer')
  //const devicesx = useDeviceState()
  return worldImagery
}
export const getBuildingLayer = () => {
  //console.log('getSatelliteLayer')
  //const devicesx = useDeviceState()
  return buildingLayer
}

export const showFloorsImages = (value: any) => {
  //console.log('showFloorsImages', value)
  if (value) {
    upfloor(currentFloor)
  } else {
    //console.log('entry else show floors', value)
    //auxLayer.setVisible(false)
    // listLayersFloors[0].setVisible(false)
    // listLayersFloors[1].setVisible(false)
    // listLayersFloors[2].setVisible(false)
    listLayersFloors.forEach(element => {
      element.setVisible(false)
    });
    //image3.setVisible(false)
    //image4.setVisible(false)
    buildingLayer.setVisible(false)
  }
}

export const updateCoordinatesByAssetId = (id: any, data: any) => {
  if (listFloorMarkersAsset && listFloorMarkersAsset[0]) {
    listFloorMarkersAsset[0].getFeatures().forEach(element => {
      // get devices array on properties
      let devices = element.getProperties().devices
      //console.log('TESTTESTTESTUPDQ',devices);
      // for device in devices
      devices.forEach((device: any) => {
        //console.log('TESTTESTTESTUPDQ',devices);
        if (device['asset_id'] === id) {
          const featureGeometry: any = element.getGeometry();
          const currentLatitude = data.coordinates[0];
          const currentLongitude = data.coordinates[1];
          const newCoordinates = transform([currentLongitude, currentLatitude], 'EPSG:4326', 'EPSG:3857');
          featureGeometry!.setCoordinates(newCoordinates);
          element.setProperties({
            ...element.getProperties(),
            ...data
          });
          if (overlay.getPosition() && overlayId === id) {
            //overlay.setPosition(undefined);
            overlay.setPosition(newCoordinates);
          }
        }
      });
    });
  }
};

export const setOverlayFindMap = (id: any, coordinates: any) => {
  overlay.setPosition(coordinates);
  overlayId = id;
}
