import React, { useState, useEffect, useRef } from 'react';
import { Collapse, List, message, Modal, Layout, Menu, Row, Col, Input, Dropdown, Button, Table, Checkbox, Tooltip, Form, Select, Drawer, Switch, Upload } from 'antd';
import { Link } from 'react-router-dom';
import { SideBar } from '../Shared/SideBar'
import { NavBar } from '../Shared/NavBar'
import { ExclamationCircleOutlined, AudioOutlined, DownOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { useAssetTypeDispatch, useAssetTypeState } from '../../hooks/assetType';
import { useAssetState, useAssetDispatch } from '../../hooks/asset';
import { useCustomerState } from '../../hooks/customer';
import * as datasets from "../../config/datasets";
import { SERVER } from '../../config/serverConfig';
import { listDevices } from '../../store/actions';
//import { ReactComponent as Logo} from './logo192.png';
// import * as FriendCard from module('list-react-files');

import { DESCEND, ASCEND, iconsListAsset } from '../../config/constants';
import { useLoginDispatch, useLoginState } from '../../hooks/login';
import { Assets } from '../Asset/AssetView';
import { ImageStatic } from 'ol/source';
const { Header, Content, Sider } = Layout;
const { Search } = Input;
const { Panel } = Collapse;
const { Option } = Select;


// imag.default(__dirname).then((files:any) => console.log(files))

export const AssetTypes = () => {
  const { searchAssetIcons } = useAssetDispatch();
  const { searchAssetTypes } = useAssetTypeDispatch();
  const assetTypes = useAssetTypeState()
  const assetList = useAssetState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [userUpdate, setUserUpdate] = useState({});
  const [form] = Form.useForm()
  const [filteredInfo, setFilteredInfo] = useState([]);
  const [dropTitle, setDropTitle] = useState([]);
  const inputSearch = useRef(null)
  const [param, setParam] = useState({});
  const [iconSelected, setIconSelected] = useState('');
  const [iconsList, setIconsList]  = useState([])
  const [hideimage, sethideImage]  = useState(false)
  const customers = useCustomerState();
  const data = useLoginState();
  //console.log(data.userProfile)
  const userProfile = data.userProfile

  useEffect(() => {
    setIconsList(iconsListAsset())
    //listDeviceTypes()
    searchAssetIcons({})
    searchAssetTypes(param)
    console.log('valor select' ,iconSelected)
  }, [])

  const menu = (
    <div className="menu-select-icon">
      <div className="head"><Input placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 522 }} /></div>
      <div className="body">
        <Row gutter={16}>
          <Col span={8} className="line-right">
            <ul>
              <li key="1"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="2"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="3"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="4"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="5"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="6"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
            </ul>
          </Col>
          <Col span={8} style={{ padding: '0px 20px' }}>
            <ul>
              <li key="7"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="8"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="9"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="10"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="11"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="12"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
            </ul>
          </Col>
          <Col span={8} className="line-left">
            <ul>
              <li key="13"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="14"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="15"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="16"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="17"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="18"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
  const onSelectIcon = (element: any) => {
    console.log('on select', element)
    const icon: any = assetList.listIcons.find((icon: any) => icon._id === element.target.id)
    console.log('found Icon', icon)
    // setIconSelected(icon)
    setIconSelected(element.target.id)
  }

  const menuIcons2 = (

    <div className="menu-select-icon">
      <div className="head"><Input placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 522 }} /></div>
      <div className="body">
        <Row gutter={16}>
          <Col span={8} className="line-right">
            <ul>
            {assetList.listIcons.map((icon: any) => {
              return (<li><img src={"/Images/assetIcons/" + icon.filename} alt="" width="20px" />{icon.name}</li>);
            })}
            </ul>
          </Col>
          {/* <Col span={8} style={{ padding: '0px 20px' }}>
            <ul>
              <li key="7"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="8"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="9"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="10"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="11"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="12"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
            </ul>
          </Col>
          <Col span={8} className="line-left">
            <ul>
              <li key="13"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="14"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="15"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="16"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="17"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="18"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
            </ul>
          </Col> */}
        </Row>
      </div>
    </div>

  );

  const menuIconGrid = (
    <Menu>
      <div className="menu-select-icon">
        <div className="head"><Input placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 522 }} /></div>
        <div className="body">
          <Row gutter={16}>
            <Col span={8} className="line-right">
              {assetList.listIcons.map((icon: any) => {
                return (<Menu.Item><img src={"/Images/assetIcons/" + icon.filename} alt="" width="20px" />{icon.name}</Menu.Item>);
              })}
            </Col>
            {/* <Col span={8} style={{ padding: '0px 20px' }}>
            <ul>
              <li key="7"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="8"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="9"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="10"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="11"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="12"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
            </ul>
          </Col>
          <Col span={8} className="line-left">
            <ul>
              <li key="13"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="14"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="15"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="16"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="17"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
              <li key="18"><img src="/Images/icon-002.svg" alt="" width="20px" />Ambulance</li>
            </ul>
          </Col> */}
          </Row>
        </div>
      </div>
    </Menu>
  );

  const menuCustomers = (
    <Menu>
      {userProfile.customers.map((customer: any) => <Menu.Item key={customer._id} >
        {customer.customer_name}
      </Menu.Item>
      )}
      <Menu.Item key={'clear'}>
        <Button className="btn-border-blue" onClick={() => { }}>Clear filters</Button>
      </Menu.Item>
    </Menu>
  );




  const columns = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      width: 80
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filteredValue: filteredInfo,
      defaultSortOrder: ASCEND,
      sorter: (a: any, b: any) => {
        // console.log('sorter', a, b);
        return a.name.toUpperCase().charCodeAt(0) - b.name.toUpperCase().charCodeAt(0)
      },
      onFilter: (value: any, record: any) => {
        console.log('onFilter', value, record);
        let valorIn = value.toLowerCase()
        //console.log(record.devicetype.toString().toLowerCase().includes(valorIn), valorIn, record.devicetype.toString().toLowerCase())
        return record.name.toString().toLowerCase().includes(valorIn) || record.name.toString().toLowerCase().includes(valorIn)
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
    }/* ,
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    } */
  ];

  useEffect(() => {
    console.log('change customer selected');
    if (userProfile.email !== '') {
      retrieveData();
    }
  }, [customers.customerSelected]);
  
  const retrieveData = () => {
    console.log('retrieve Data AssetType', customers.customerSelected, userProfile.is_admin) 
    if (userProfile.is_admin) {
      searchAssetTypes({ customer_id: customers.customerSelected._id ? customers.customerSelected._id : '' });
    } else {
      if (!customers.customerSelected._id) {
        searchAssetTypes({ customer_id: '123431242134' });
      } else {
        searchAssetTypes({ customer_id: customers.customerSelected._id });
      }
    }
  }

  const fillForm = (values: any) => {
    setVisible(true);
    setIsUpdate(true)
    console.log(values)
    const deviceFill = Object.assign({}, values)
    setIconSelected(deviceFill.icon)
    form.setFieldsValue(deviceFill)
    setUserUpdate(values)
  }
  const clearAll = () => {
    setFilteredInfo([])
    // setDropTitle([])
    console.log(filteredInfo)
  }
  const searchFilter = (element: any) => {
    clearAll()
    console.log(element)
    let textSearch = ''
    if (element.current.state.value !== undefined) {
      textSearch = element.current.state.value
    }
    const arrayFilter: any = []
    arrayFilter.push(textSearch)
    setFilteredInfo(arrayFilter)
  }

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  const onClose = () => {
    setVisible(false);
  };

  const newAssetTypeForm = () => {
    showDrawer()
    setIsUpdate(false);
    clearForm();
    setIconSelected('')
  }

  const registerAssetTypeDatabase = (values: any) => {
    console.log('Success:', values);
    const asType = values
    asType.icon = iconSelected
    if (!isUpdate) {
      datasets.postData(SERVER.REGISTER_ASSETTYPE(), asType, datasets.getToken()).then(res => {
        searchAssetTypes(param);
        onClose();
        clearForm();
      })
    } else {
      updateAssetTypeDatabase(values, userUpdate)
      setIsUpdate(false)
    }
  }
  const updateAssetTypeDatabase = (dataDevice: any, deviceUpdate: any) => {
    console.log('Update Data:', dataDevice, deviceUpdate);
    const dataToUpdate = dataDevice
    dataToUpdate._id = deviceUpdate._id
    dataToUpdate.icon = iconSelected
    console.log('Update Data:', dataDevice, deviceUpdate, dataToUpdate);
    datasets.putData(SERVER.UPDATE_ASSETTYPE(), dataToUpdate, datasets.getToken()).then(res => {
      message.success('AssetType Updated successfully', 3);
      searchAssetTypes({});
      onClose();
      setIsUpdate(false)
    })
  }

  const clearForm = () => {
    form.resetFields();
  }
  const deleteAssetTypeDatabase = (id: any) => {
    console.log('delete AssetType', id)
    datasets.deleteData(SERVER.DELETE_ASSETTYPE(id), datasets.getToken()).then(res => {
      message.success('Asset Type removed successfully', 3);
      searchAssetTypes({});
    })
  }
  const showConfirmModal = (id: any) => {
    //setPartnerId(id);
    Modal.confirm({
      title: "Remove Asset Type",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to remove Asset Type?',
      onOk() {
        deleteAssetTypeDatabase(id);
        searchAssetTypes({});
      }
    });
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <SideBar></SideBar>
      <Layout>
        <NavBar></NavBar>
        <Layout className="site-layout">
          <Content className="user table-general">
            <Row>
              <Col xs={24} lg={24}>
                <div className="card-white">
                  <div className="head-t">
                    <Row>
                      <Col xs={24} lg={16}>
                        {/* <label>Customers:</label>
                        <Dropdown overlay={menuCustomers} trigger={['click']}>
                          <Button className="btn-border-light-gray">
                            {dropTitle.length === 0 ? 'Choose a Customer' : dropTitle[0]} <DownOutlined />
                          </Button>
                        </Dropdown> */}
                        <Input allowClear onPressEnter={() => searchFilter(inputSearch)} ref={inputSearch} placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 200 }} />
                        <Button className="btn-blue" onClick={() => searchFilter(inputSearch)}>Search</Button>
                      </Col>
                      <Col xs={24} lg={8} style={{ textAlign: 'right' }}>
                        <Button className="btn-green" onClick={newAssetTypeForm}>New Asset Type</Button>
                        <Drawer
                          className="drawer-form"
                          title="New Asset Type"
                          placement="right"
                          closable={false}
                          onClose={onClose}
                          visible={visible}
                          width={600}
                          footer={
                            <div
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <Button style={{ marginRight: 8 }} className="btn-border-red" onClick={showDrawer}>
                                Cancel
                            </Button>
                              <Button className="btn-green" onClick={() => form.submit()}>
                                Save
                            </Button>
                            </div>
                          }
                        >
                          <Form form={form} onFinish={registerAssetTypeDatabase} layout="vertical" hideRequiredMark>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name='name' label="*Name:">
                                  <Input placeholder="Please enter Name" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name='description' label="Description:" >
                                  <Input placeholder="Please enter description" />
                                </Form.Item>
                              </Col>
                              {/*<Col span={8}>
                                <Form.Item name='icon' label="Icon Asset:" >
                                  <Dropdown overlay={menuIcons2} trigger={['click']} placement="bottomRight" >
                                    <Button className="select-icon btn-border-light-gray" onClick={e => e.preventDefault()}>
                                      {iconSelected === {} ? 'Select' : <><img src={"/Images/assetIcons/" + iconSelected.filename} alt="" width="20px" />{iconSelected.name}</>} <DownOutlined />
                                    </Button>
                                  </Dropdown>
                                </Form.Item>
                              </Col>*/}
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name="customer_id" label="Customer Asociated" className="select-bg">
                                  <Select
                                    placeholder="Please select a Customer"
                                  >
                                    {customers.listCustomers.map((customer: any) => <Option value={customer._id} label={customer.customer_name}>{customer.customer_name}</Option>)}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                          <Collapse defaultActiveKey={['2']} ghost expandIconPosition="right">
                            <Panel header="ATRIBUTES" key="2">
                            {/*<h5>ATRIBUTES</h5>*/}
                              <Form form={form} onFinish={registerAssetTypeDatabase} className="form-second" labelCol={{ span: 16 }} wrapperCol={{ span: 8 }} style={{marginBottom:'0px'}}>
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Form.Item name='activity' label="Activity:" valuePropName="checked" >
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='asset_tag' label="Asset Tag:" valuePropName="checked"  >
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='color' label="Color:" valuePropName="checked">
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Form.Item name='comments' label="Comments:" valuePropName="checked">
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='description_asset' label="Description:" valuePropName="checked"  >
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='make' label="Make:" valuePropName="checked"  >
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Form.Item name='manufacturer' label="Manufacturer:" valuePropName="checked" >
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='model' label="Model:" valuePropName="checked"  >
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='part_number' label="Part Number:" valuePropName="checked">
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Form.Item name='sku' label="SKU:" valuePropName="checked">
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='serial_number' label="Serial Number:" valuePropName="checked"  >
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='usability' label="Usability:" valuePropName="checked"  >
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Form.Item name='vin' label="VIN:" valuePropName="checked">
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form>
                            </Panel>

                            <Panel header="DEVICE IDENTIFIERS" key="3">
                              {/*<h5>DEVICE IDENTIFIERS</h5>*/}
                              <Form form={form} onFinish={registerAssetTypeDatabase} className="form-second" labelCol={{ span: 16 }} wrapperCol={{ span: 8 }} style={{marginBottom:'0px'}}>
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Form.Item name='sn' label="Serial Number:" valuePropName="checked" >
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='esn' label="ESN:" valuePropName="checked"  >
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item name='imei' label="IMEI:" valuePropName="checked">
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Form.Item name='mac_address' label="MAC Address:" valuePropName="checked">
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='sms_number' label="SMS Number:" valuePropName="checked"  >
                                      <Switch size="small" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form>
                            </Panel>

                            <Panel className="Collapse-p-scroll" header={<>{'ICONS   '}<img hidden={iconSelected.length === 0} src={'/Images/assetIcons/'+iconSelected} width="30px"/></>} key="1">
                              <Form form={form} onFinish={registerAssetTypeDatabase} layout="vertical" hideRequiredMark>
                                <Row gutter={24}>
                                  <Col span={24}>
                                  {/* <Input placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 200 }} /> */}
                                    <Form.Item name='icon' >
                                      <List style={{textAlign:'center'}}
                                        grid={{ gutter: 16, column: 6 }}
                                        //dataSource={assetList.listIcons}
                                        dataSource={iconsList}
                                        renderItem={(item: any) => (
                                          <List.Item id={item} key={item._id} onClick={onSelectIcon}>
                                            <Tooltip title={item.substring(0, item.length-4)}>
                                            <img id={item} src={"/Images/assetIcons/" + item} alt="" width="50px" />
                                            {/* {item} */}
                                            </Tooltip>

                                          </List.Item>)}>
                                      </List>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form>
                            </Panel>
                          </Collapse>
                        </Drawer>
                      </Col>
                    </Row>
                  </div>

                  <Table dataSource={assetTypes.listAssetTypes.map((assetType: any) => {
                    return ({
                      check: <Checkbox></Checkbox>,
                      key: assetType._id,
                      uid: assetType._id,
                      icon: assetType.icon ? <img src={"/Images/assetIcons/"+ assetType.icon} alt="" width="30px" height="30px" /> : <img></img>,
                      name: assetType.name,
                      description: assetType.description,
                      n_assets: assetType.state,
                      actions: <>
                        <Tooltip title="Edit"><Button className="btn-edit" onClick={() => fillForm(assetType)}><i className="mdi mdi-pencil"></i></Button></Tooltip>
                        <Tooltip title="Delete"><Button className="btn-delete" onClick={() => showConfirmModal(assetType._id)}><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
                        {/* <Tooltip title="Change Password"><Button className="btn-password"><i className="mdi mdi-lock-question"></i></Button></Tooltip> */}
                      </>,
                    })
                  })} columns={columns} pagination={{ pageSize: 30 }} scroll={{ y: 'calc(100vh - 267px)' }} />
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
