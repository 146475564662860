import {
   AssetState, SEARCH_ASSETS, SHOW_ASSETS, AssetType, SAVE_ASSET,
   UPDATE_ASSET, DELETE_ASSET, LIST_ICONS, GET_ASSET_MAP, UPDATE_ASSET_FROM_LIST
} from '../types';

const initialState = {
   listAssets: [],
   listIcons: [],
   listAssetMap: { type: '', features: [] },
   pagination: { total: 0, page: 0 }
}
export const assetReducer = (state: AssetState = initialState, action: AssetType) => {
   switch (action.type) {
      case SEARCH_ASSETS:
         return Object.assign({}, state, { listAssets: action.data, pagination: action.pagination });
      case SHOW_ASSETS:
         return Object.assign({}, state, { listAssets: action.data });
      case SAVE_ASSET:
         return Object.assign({}, state, { objectAsset: action.data });
      case UPDATE_ASSET:
         return Object.assign({}, state, { objectAsset: action.data });
      case LIST_ICONS:
         return Object.assign({}, state, { listIcons: action.data });
      case GET_ASSET_MAP:
         return Object.assign({}, state, { listAssetMap: action.data });
      case DELETE_ASSET:
         return Object.assign({}, state); // , { objectAsset: action.data }
      case UPDATE_ASSET_FROM_LIST:
         const id = action.data.asset_id;
         const asset = state.listAssetMap.features.find(feature => feature.id === id);
         if (asset) {
            //console.log('action.data ', action.data);
            const device = asset.properties.devices.find((device: any) => {
               return (action.data.address === device.device_esn) || (action.data.address === device.device_imei);
            });
            asset.geometry.coordinates[0] = +action.data.lng;
            asset.geometry.coordinates[1] = +action.data.lat;

            device.last_update = action.data.last_update;
            //asset.properties.tempSensor0_C = action.data.tempSensor0_C;
            // iterate in keys of asset.properties
            for (const key in device) {
               if (action.data.hasOwnProperty(key)) {
                  device[key] = action.data[key];
               }
            }
            const indexAsset = state.listAssetMap.features.indexOf(asset);
            const indexDevice = asset.properties.devices.indexOf(device);
            asset['properties']['devices'][indexDevice] = device;
            state.listAssetMap.features[indexAsset] = asset;
         }
         return Object.assign({}, state);
      default:
         return state;
   }
}