import { useState, useEffect } from 'react';
import { Layout, Row, Col, Badge, Avatar, Select, DatePicker, Space, Button, Collapse, Table, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SideBar } from '../Shared/SideBar'
import { NavBar } from '../Shared/NavBar'
import MapOpenLayers, { getListFloorMarkersAsset, setOverlayFindMap, ChangeMarkersMap, getBuildingLayer, getlistLayersAsset, getMap, getSatelliteLayer, showFloorsImages, upfloor, setMarkerRoute } from "./MapOpenLayers";
import { CloseOutlined } from '@ant-design/icons';
import * as datasets from "../../config/datasets";
import { SERVER } from "../../config/serverConfig";
import { LIMIT_HISTORY } from "../../config/constants";
import { MapLocations } from './MapLocations';
import { useDeviceDispatch, useDeviceState } from '../../hooks/device';
import { useAddressDispatch, useAddressState } from '../../hooks/address';
import { useAssetState, useAssetDispatch } from '../../hooks/asset';
import { useCustomerState } from '../../hooks/customer';
import { useLoginDispatch, useLoginState } from '../../hooks/login';
import { assetReducer } from '../../store/reducers/asset';
import { useAssetTypeDispatch } from '../../hooks/assetType';
import moment from 'moment';
const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const dataSource = [
  {
    service: 'Radiography',
    speed: '31 km/h',
    geofence: '53',
    temperature: '29.38 C',
    event: 'Heartbeat',
    motion: 'Moving',
  },
  {
    service: 'Radiography',
    speed: '31 km/h',
    geofence: '53',
    temperature: '29.38 C',
    event: 'Heartbeat',
    motion: 'Moving',
  },
  {
    service: 'Radiography',
    speed: '31 km/h',
    geofence: '53',
    temperature: '29.38 C',
    event: 'Heartbeat',
    motion: 'Moving',
  },
];

const columns = [
  {
    title: 'Service',
    dataIndex: 'service',
    key: 'service',
  },
  {
    title: 'Speed',
    dataIndex: 'speed',
    key: 'speed',
  },
  {
    title: 'Geofence',
    dataIndex: 'geofence',
    key: 'geofence',
  },
  {
    title: 'Temperature',
    dataIndex: 'temperature',
    key: 'temperature',
  },
  {
    title: 'Event',
    dataIndex: 'event',
    key: 'event',
  },
  {
    title: 'Motion state',
    dataIndex: 'motion',
    key: 'motion',
  },
];

export const LocationHistoryView = () => {
  const { searchDevices, setListDevices } = useDeviceDispatch();
  const [nElementMenu, setNElementMenu] = useState(10);
  const { getAssetTypesArray } = useAssetTypeDispatch();
  const { searchAssets, getAssetsMapArray, updateAssetFromList } = useAssetDispatch();
  const devices = useDeviceState();
  const assets = useAssetState();
  const [filteredInfo, setFilteredInfo] = useState({});
  const [listHistory, setListHistory] = useState<any>({});
  const data = useLoginState();
  const customers = useCustomerState();
  const userProfile = data.userProfile
  const address = useAddressState();
  const [nfloor, setNFloor] = useState('')
  const [floorMenu, setFloorMenu] = useState<any>({ floors: [] })
  const [devicesAssociated, setDevicesAssociated] = useState<any>({deviceAssociated: []})
  const [assetSelected, setAssetSelected] = useState<any>('')
  const [deviceSelected, setDeviceSelected] = useState<any>('')
  const [datesSelected, setDatesSelected] = useState<any>({})
  const [showBuildingMenu, setShowBuildingMenu] = useState(false)
  const [enableBtnCSV, setEnableBtnCSV] = useState(false)
  const [buildingMenu, setBuildingMenu] = useState<any>({ buildings: [] })
  const [showCampusMenu, setShowCampusMenu] = useState(false);
  const [markerPopup, setMarkerPopup] = useState<any>({
    values_: {
      devices: [{
        name: '',
        last_update: '',
        devices: [{ area: '', floor_name: '', building_name: '' }],
        area_name: '',
        floor_name: '',
        building_name: '',
        asset_type_name: '',
        longitude: '',
        latitude: '',
        icon: '',
        area: '',
        devicetype: []
      }]
    }
  })
  useEffect(() => {
    console.log('change customer selected');
    if (userProfile.email !== '') {
      retrieveDataPageNumber(1);
    }

  }, [customers.customerSelected]);

  useEffect(() => {
    console.log('Get me user', userProfile);
    if (userProfile.email !== '') {
      retrieveDataPageNumber(1);
    }

  }, [userProfile]);
  const retrieveDataPageNumber = (pageNumber: any) => {
    //console.log('retrieve Data Devices', customers.customerSelected, userProfile.is_admin) 
    if (userProfile.is_admin) {
      searchDevices({ customer_id: customers.customerSelected._id ? customers.customerSelected._id : '', limit: 2000, page: pageNumber, filters: filteredInfo });
      searchAssets({ customer_id: customers.customerSelected._id ? customers.customerSelected._id : '', page: pageNumber, limit: 2000 });
    } else {
      if (!customers.customerSelected._id) {
        searchDevices({ customer_id: '123431242134', limit: 2000, page: pageNumber, filters: filteredInfo });
        searchAssets({ customer_id: '123431242134', page: pageNumber, limit: 2000 });
      } else {
        searchDevices({ customer_id: customers.customerSelected._id, limit: 2000, page: pageNumber, filters: filteredInfo });
        searchAssets({ customer_id: customers.customerSelected._id, page: pageNumber, limit: 2000 });
      }
    }
  }

  const getHistoryAsset = async (assetid: any, deviceid: any, dates: any, page: any) => {
    const myPromise = new Promise((resolve, reject) => {
      console.log('Resp History prev', assetid, deviceid);
      datasets.postData(SERVER.GET_HISTORY_ASSET(), { asset_id: assetid, device_id: deviceid, start_date: dates.length ? dates[0] : '', end_date: dates.length ? dates[1] : '', page: page, limit: 10 }, datasets.getToken()).then(async res => {
        console.log('Resp History', res)
        resolve(res)
      })
    })
    return await myPromise
  };

  const showLocationAsset = (hist: any) => {
    console.log('test test test hist q',hist);
    const current_esn = hist.device?.esn;
    const current_imei = hist.device?.imei;
    const indexAddress = address.listAddress.findIndex((item: any) => (
      item.asset_id === hist.asset_id && ((item.device_esn && item.device_esn === current_esn) || (item.device_imei && item.device_imei === current_imei))
    ));
    if (indexAddress !== -1) {
      return address.listAddress[indexAddress].geoAddress;
    }
    return "";
  }
  const onSelectMarker = (feat: any) => {
    console.log('feat select Marker', feat);
    setMarkerPopup(feat);
  }
  const retrieveData = (name: any = []) => {
    if (userProfile && userProfile.is_admin) {
      getAssetsMapArray({
        name: name,
        customer_id: customers.customerSelected._id ? customers.customerSelected._id : ''
      })
      getAssetTypesArray({
        name: name,
        customer_id: customers.customerSelected._id ? customers.customerSelected._id : ''
      })
    } else {
      if (!customers.customerSelected._id) {
        getAssetsMapArray({
          name: name, customer_id: '123413243124321432141234'
        })
        getAssetTypesArray({
          name: name, customer_id: '123413243124321432141234'
        })
      } else {
        getAssetsMapArray({ name: name, customer_id: customers.customerSelected._id })
        getAssetTypesArray({ name: name, customer_id: customers.customerSelected._id })

      }
    }
  }

  const exportCSV = async (id: string) => {
    console.log('export csv', id);

    const token: string = datasets.getToken()!;
    datasets.postDataOctet(SERVER.EXPORT_CSV_ASSET_MAP(), { asset_id: id }, token).then(res => {
      console.log('respuesta octet', res)
      setEnableBtnCSV(true)
      const value = "" + res;
      let csvData = new Blob([value], { type: 'text/csv;charset=utf-8;' });
      let csvURL = window.URL.createObjectURL(csvData);
      let tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'activity.csv');
      tempLink.click();
    });
  }

  const nextPosition = () => {

  }

  const setCenterMap = (asset: any) => {
    console.log('set center maphis', asset)
    const mapx = getMap()
    const listFloorMarkersAsset = getListFloorMarkersAsset()
    const view = mapx.getView()

    var feature: any = listFloorMarkersAsset[0].getFeatureById(asset._id);
    console.log('este es el feat', feature, listFloorMarkersAsset[0].getFeatures())
    if (feature) {
      var coordinates = feature.getGeometry().getCoordinates();
      const featureId = feature.id_;
      setMarkerRoute(feature)
      //setOverlayFindMap(featureId, coordinates);
      view.centerOn(feature.getGeometry().getCoordinates(), mapx.getSize(), [mapx.getSize()[0] / 2, mapx.getSize()[1] / 2]);
    }
    //onSelectMarker(feature)
  };

  const applyFilters = async () => {
    const res: any = await getHistoryAsset(assetSelected, deviceSelected, datesSelected, 1);
    setListHistory(res);
    if (res.elements.length) {
      setEnableBtnCSV(true)
    }
    console.log('my list history', listHistory, res)
    //console.log(id, c)
  }

  const handleLoadAssets = () => {
    if (listHistory.elements.length) {
        console.log("handlehistoryasse")
    }
  };

  return (
    <>
      <Layout style={{ height: '100vh' }}>
        <SideBar></SideBar>
        <Layout>
          <NavBar></NavBar>
          <Layout className="site-layout" >
            <Content>
              <Row gutter={[16, 0]}>
                <Col xs={24} lg={16}>

                  <MapLocations
                    markerPopup={markerPopup}
                    showLocationAsset={showLocationAsset}
                    onSelectMarker={onSelectMarker}
                    retrieveData={retrieveData}
                    setShowCampusMenu={setShowCampusMenu}
                    setBuildingMenu={setBuildingMenu}
                    setNFloor={setNFloor}
                    nfloor={nfloor}
                    setShowBuildingMenu={setShowBuildingMenu}
                    showBuildingMenu={showBuildingMenu}
                    setFloorMenu={setFloorMenu}
                    listHistory={listHistory}
                  ></MapLocations>

                </Col>
                <Col xs={24} lg={8}>
                  <div className="card-white location-history">
                    <div className="card-body">
                      <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                          <Select defaultValue="Assets" style={{ width: '100%' }} onChange={(id, c) => {
                            setAssetSelected(id)
                            const a = assets.listAssets.find(x => x._id === id)
                            setDevicesAssociated(a)
                            console.log('dev assoc', a, devicesAssociated )
                            /*const res: any = await getHistoryAsset(id, 1);
                            setListHistory(res);
                            console.log('my list history', listHistory, res)
                            console.log(id, c) */
                          }}>
                            {assets.listAssets.map((asset) => {
                              return (<Option value={asset._id} >{asset.name}</Option>)
                            })}

                          </Select>
                        </Col>
                        <Col xs={24} lg={12}>
                          <Select defaultValue="Device" style={{ width: '100%' }} onChange={(id, c) => {
                            setDeviceSelected(id)
                           }}>
                            {devicesAssociated.deviceAssociated.map((dev:any) => {
                              return (
                                <Option value={dev.device_id}>{dev.device_name}</Option>
                              )
                            })}
                            <Option value={''}>{'All'}</Option>
                          </Select>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} lg={24}>
                          <RangePicker
                            ranges={{
                              Today: [moment().startOf('day'), moment().endOf('day')],
                              'Yesterday': [moment().startOf('day').subtract(1,'d'), moment().endOf('day').subtract(1,'d')],
                              'Last 7 days': [moment().startOf('day').subtract(7,'d'), moment().endOf('day')],
                              'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            showTime={{
                              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                            }}
                            format="YYYY/MM/DD HH:mm:ss"
                            onChange={(date: any, dateString) => { setDatesSelected(dateString) }}
                          />
                          {/* <RangePicker style={{ width: '100%' }}
                            format="YYYY-MM-DD HH:mm:ss"
                            onChange={(date: any, dateString) => { setDatesSelected(dateString) }}
                          /> */}
                        </Col>
                      </Row>
                      <div className="card-footer" style={{ textAlign: 'right' }}>
                        <Button className="btn-border-red" style={{ marginRight: '10px' }} onClick={() => nextPosition()}>CANCEL</Button>
                        <Button className="btn-green" onClick={() => applyFilters()}>APPLY</Button>
                      </div>
                      <hr style={{ marginTop: '0px' }} />

                      {/*TABLE*/}
                      <div className="head">
                        <div className="h-01">Date</div>
                        <div>Location</div>
                        <Button disabled={!enableBtnCSV} className="btn-green"><img src="/Images/map/csv.svg" alt="" onClick={() => {
                          console.log('assete selected ', assetSelected)
                          setEnableBtnCSV(false)
                          exportCSV(assetSelected)
                        }} /></Button>
                      </div>
                      <div className="body">
                      <InfiniteScroll
                        dataLength={nElementMenu}
                        next={handleLoadAssets}
                        hasMore={listHistory?.elements?.length > 8}
                        loader={assets.listAssetMap.features.length > 8 ? <h4>Loading...</h4> : 'No More data'}
                        style={{ overflowX: 'hidden' }}
                        scrollableTarget='scrollableDiv'
                        endMessage={''}>

                        <Collapse
                        // expandIconPosition="right"
                        >
                          {(listHistory && listHistory.elements) ? listHistory.elements.map((his: any) => {

                            return (<Panel showArrow={false} header=
                              {<div className="coll-history">
                                <div className="h-01">{new Date(his.eventDate).toLocaleString("en-US", { timeZone: "America/New_York" })}</div>
                                <div>{his.geocode_address}</div>
                                <Tooltip placement="top" title={'Find on Map'}>
                                  <Button className="btn-blue" style={{ margin: '0px 2px' }} onClick={() => setCenterMap(his)}><i className="mdi mdi-map-marker-radius" ></i></Button>
                                </Tooltip>
                              </div>}
                              key="1"
                            >
                              {/* <div className="table-history">
                                <Table dataSource={dataSource} columns={columns} />
                              </div> */}
                            </Panel>)

                          })
                            : []
                          }
                        </Collapse>
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
}
