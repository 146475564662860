import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { USER_LOGGED } from '../types';
import { getRequest, postRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from '../../config/datasets'



export const UserLogged = (response: any) => {
  /* const users = [
     { id: 1, username: 'carlos'},
    { id: 2, username: 'juan'}
  ] */ 
  //console.log('login action', response)
  localStorage.setItem('login', JSON.stringify(
  {
    login: true,
    token: response.token ? response.token : '',
    user: response.userResult.username ? response.userResult.username : ''  
  } 
  ))
  return {
    type: USER_LOGGED,
    data: response
  }
}

export const logUser = (user: object): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const response = await postRequest(SERVER.POST_LOGIN(), user);
    dispatch(UserLogged(response));
  }
}

export const setUserInf = (user: any, token: string): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    //const response = await postRequest(SERVER.POST_LOGIN(), user);
    user.token = token
    //console.log('action vico',user)
    dispatch(setUser(user));
  }
}

export const setUser = (user: any) => {
  //console.log('login action', user)
  return {
    type: USER_LOGGED,
    userProfile: user
  }
}

export const userMe = (): ThunkAction<void, RootState, unknown, Action<string>> => {
  console.log('userMe action')
  const token:string = datasets.getToken()!;
  console.log('token', token);
  return async (dispatch) => {
    const response = await getRequest(SERVER.ME(), token);
    dispatch(setUserInf(response, token));
  }
}
