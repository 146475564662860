export const SEARCH_CAMPUS = 'SEARCH_CAMPUS';
export const SAVE_CAMPUS = 'SAVE_CAMPUS';
export const UPDATE_CAMPUS = 'UPDATE_CAMPUS';
export const DELETE_CAMPUS = 'DELETE_CAMPUS';
export const VALIDATE_NAME = 'VALIDATE_NAME';

export interface CampusState {
  listCampus: any[]
};
export interface CampusObjectState {
  campus: {},
  validation: {}
};

interface ReplaceSearchCampus {
  type: typeof SEARCH_CAMPUS,
  data: CampusState
}

interface ReplaceSaveCampus {
  type: typeof SAVE_CAMPUS,
  data: CampusObjectState
}

interface ReplaceUpdateCampus {
  type: typeof UPDATE_CAMPUS,
  data: CampusObjectState
}

interface ReplaceDeleteCampus {
  type: typeof DELETE_CAMPUS,
  data: CampusObjectState
}

interface ReplaceValidateName {
  type: typeof VALIDATE_NAME,
  data: CampusObjectState
}

export type CampusType = ReplaceSearchCampus |
        ReplaceSaveCampus |
        ReplaceUpdateCampus | 
        ReplaceDeleteCampus |
        ReplaceValidateName