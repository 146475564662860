export const LIST_LINKS = 'LIST_LINKS';

export interface LinkState {
  listLinks: any[]
}

interface ReplaceLinkAction {
  type: typeof LIST_LINKS,
  data: LinkState
}

export type LinkType = ReplaceLinkAction