import React, { useEffect, useState, useRef } from 'react';
import { message, Layout, Row, Col, Input, Dropdown, Button, Table, Checkbox, Tooltip, Drawer, Form, Select, Alert, Upload, Modal, Tabs } from 'antd';
import * as datasets from "../../config/datasets";
import { SideBar } from '../Shared/SideBar'
import { NavBar } from '../Shared/NavBar'
import { DESCEND, ASCEND } from '../../config/constants';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { useUserDispatch, useUserState } from '../../hooks/user';
import { useCustomerDispatch, useCustomerState } from '../../hooks/customer';
import { useCPartnerDispatch, useCPartnerState } from '../../hooks/channelpartner';
import { useLinkDispath, useLinkState } from '../../hooks/link';
import { SERVER } from '../../config/serverConfig';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Header, Content, Sider } = Layout;
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;


const columnsImage = [
  
  {
    title: 'Map Image',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: 300,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    sorter: true,
  },
];

export const Customer = () => {
  const [visible, setVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [userUpdate, setUserUpdate] = useState({});
  const [filteredInfo, setFilteredInfo] = useState([]);
  const [arrayFloors, setArrayFloors]: any = useState([]);
  const [dataUser, setDataUser]: any = useState([]);
  const inputSearch = useRef(null);
  const [nImages, setNImages] = useState(0);

  const setNewCustomer = () => {
    const p = partners.listPartners.find(partner => partner.channel_partner_name === 'Propel Administrator');
    console.log('partner por defecto', p);
    form.setFieldsValue({ partner_id: p._id });
    setArrayFloors([]);
    setIsUpdate(false);
    showDrawer();
  }

  const showDrawer = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const fillForm = (values: any) => {
    setVisible(true);
    setIsUpdate(true)
    console.log(values)
    const userFill = Object.assign({}, values)
    //if (userFill.email == '') {
    userFill.email = values.username
    setDataUser(values.users.map((user: any) => { return { user: user.user._id, role: user.role, full_name: user.user.full_name } }))
    //setArrayFloors(values.users.map((user: any) => { return { user: user.user._id, role: user.role, full_name: user.user.full_name } }))
    console.log(getLogo(values.customer_logo))
    //}
    form.setFieldsValue(userFill)
    setUserUpdate(values)
  }

  const getLogo = (name: any) => {
    datasets.getData(SERVER.GET_LOGO_CUSTOMER(name), datasets.getToken()).then(res => {
      return res
    })
    return undefined
  }

  const clearAll = () => {
    setFilteredInfo([])
    setDataUser([])// setDropTitle([])
    console.log(filteredInfo)
  }
  /* const searchFilter = (element: any) => {
    clearAll()
    console.log(element)
    let textSearch = ''
    if (element.current.state.value !== undefined) {
      textSearch = element.current.state.value
    }
    const arrayFilter: any = []
    arrayFilter.push(textSearch)
    setFilteredInfo(arrayFilter)
  }*/

  const onClose = () => {
    setVisible(false);
    setNImages(0)
    clearForm()
  };
  const { listCustomer, searchCustomers } = useCustomerDispatch();
  const { listCPartners, searchPartners } = useCPartnerDispatch();
  const { listUsers, searchUsers } = useUserDispatch();
  const { listLink } = useLinkDispath();
  const customers = useCustomerState();
  const partners = useCPartnerState();
  const users = useUserState();
  const links = useLinkState();
  //const [data, setData] = useState(dataSource);
  const [param, setParam] = useState({});

  useEffect(() => {
    searchUsers({});
    listLink();
    //listCPartners();
    // listUsers();
    searchCustomers({})
    console.log('customers', customers)
    searchPartners({})
  }, [])

  const [form] = Form.useForm()

  const columns = [
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      defaultSortOrder: ASCEND,
      filteredValue: filteredInfo,
      sorter: (a: any, b: any) => {
        //console.log('sorter', a, b);
        // return a.model.length - b.model.length },
        return a.customer_name.props.children.toUpperCase().charCodeAt(0) - b.customer_name.props.children.toUpperCase().charCodeAt(0)
      },
      onFilter: (value: any, record: any) => {
        console.log('onFilter', value, record);
        let valorIn = value.toLowerCase()
        //console.log(record.devicetype.toString().toLowerCase().includes(valorIn), valorIn, record.devicetype.toString().toLowerCase())
        return record.customer_name.props.children.toString().toLowerCase().includes(valorIn) || record.customer_name.props.children.toString().toLowerCase().includes(valorIn)
      },
    },
    {
      title: 'Page Url',
      dataIndex: 'page_url',
      key: 'page_url',
      sorter: (a: any, b: any) => {
        //console.log('sorter', a, b);
        // return a.model.length - b.model.length },
        return a.page_url.toUpperCase().charCodeAt(0) - b.page_url.toUpperCase().charCodeAt(0)
      },
    },
    {
      title: 'Channel Partner',
      dataIndex: 'partner_id',
      key: 'partner_id',
      // sorter: true,
    },
    {
      title: '# Assets',
      dataIndex: 'n_assets',
      key: 'n_assets',
      // sorter: true,
    },
    {
      title: 'Last Update',
      dataIndex: 'last_update',
      key: 'last_update',
      // sorter: true,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      // sorter: true,
    },
  ];

  const columnsUser = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      defaultSortOrder: ASCEND,
      sorter: (a: any, b: any) => {
        return a.user.toUpperCase().charCodeAt(0) - b.user.toUpperCase().charCodeAt(0)
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      // sorter: true,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      // sorter: true,
    }
  ]


  const formPageURL = (e: any) => {
    //console.log(form.getFieldsValue().customer_name)
    const newPageURL = form.getFieldsValue().customer_name.toLowerCase().trim().replaceAll(' ', '')
    //console.log(newPageURL)
    form.setFieldsValue({ page_url: newPageURL.replace(/[.*"+\-?^${}()|[\]\\]/g, '') })
  }

  const registerCustomerDatabase = (values: any) => {
    console.log('Success:', values);
    const dataForm: FormData = new FormData();
    if (!isUpdate) {
      const customer = {
        customer_name: values.customer_name,
        page_url: values.page_url,
        primary_contact_id: values.primary_contact_id,
        partner_id: values.partner_id,
        user_guide_id: values.user_guide_id,
        users: dataUser,
      }
      dataForm.append('request', JSON.stringify(customer));
      if (values.customer_logo !== undefined)
        dataForm.append('logo', values.customer_logo.file.originFileObj)
      /* if (arrayFloors.length > 0) {
        console.log('arrayflor', arrayFloors)
        for (const iterator of arrayFloors) {
          dataForm.append('images', iterator);
        }
      }*/ 
      //console.log('datafloor', dataForm)
      datasets.postDataFiles(SERVER.REGISTER_CUSTOMER_FILE(), dataForm, datasets.getToken()).then(res => {
        searchCustomers({});
        onClose();
      })
    } else {
      updateCustomerDatabase(values, userUpdate);
      setIsUpdate(false);
    }
  }

  const clearForm = () => {
    setDataUser([])
    form.resetFields();
  }

  const searchFilter = (element: any) => {
    console.log(element)
    let textSearch = '';
    if (element.current.state.value !== undefined) {
      textSearch = element.current.state.value
    }
    const data = {
      name: textSearch
    }
    setParam(data);
    searchCustomers(data);
  }

  const updateCustomerDatabase = (dataUpdate: any, dataCustomer: any) => {
    console.log('Success Update:', dataUpdate, dataCustomer);
    const dataForm: FormData = new FormData();
    dataUpdate._id = dataCustomer._id;
    dataUpdate.users = dataUser;
    dataForm.append('request', JSON.stringify(dataUpdate));
    //console.log('LOGO', dataUpdate.customer_logo);
    if (dataUpdate.customer_logo !== undefined && 
        dataUpdate.customer_logo.file !== undefined) {
      dataForm.append('logo', dataUpdate.customer_logo.file.originFileObj)
    }
    /* if (arrayFloors.length > 0)
      dataForm.append('images', arrayFloors) */
    datasets.putDataFiles(SERVER.UPDATE_CUSTOMER(), dataForm, datasets.getToken()).then(res => {
      //listCustomer();
      searchCustomers(param);
      onClose();
    })
  }

  const deleteCustomerDatabase = (id: any) => {
    datasets.deleteData(SERVER.DELETE_CUSTOMER(id), datasets.getToken()).then(res => {
      //listCustomer();
      message.success('Customer Removed successfully', 3);
      searchCustomers(param);
    })
  }

  const showConfirmModal = (id: any) => {
    console.log('confirm', id);
    Modal.confirm({
      title: "Remove channel partner",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to remove Customer?',
      onOk() {
        deleteCustomerDatabase(id);
      }
    });
  }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const addUserTable = () => {
    const datU = [...dataUser]
    const user = form.getFieldValue('user')
    const role = form.getFieldValue('user_role')
    console.log('addUserTable', user, role)
    if (user && role) {
      datU.push({
        user: user.value,
        full_name: user.label,
        role: role
      })
      console.log(datU, dataUser)
      setDataUser(datU)
    }
  }

  const deleteUserAssociated = (id: any) => {
    console.log(id, dataUser)
    const datU = [...dataUser]
    const pos = datU.findIndex(user => user.user == id)
    datU.splice(pos, 1)
    //console.log(datU.splice(pos, 1))
    setDataUser(datU)
  }

  const addImageTable = () => {
    const floorImage = form.getFieldValue('floor_image')
    console.log('imagen del piso', floorImage, arrayFloors)
    if (floorImage && floorImage.fileList.length !== 0) {
      console.log('entra', floorImage.file.uri, floorImage.file.originFileObj.uri)
      const datImg = [...arrayFloors]
      datImg.push(floorImage.file.originFileObj)
      setArrayFloors(datImg)
    }//floorImage.file.originFileObj
    else {
      console.log('no entra')
    }
    form.resetFields(['floor_image'])
  }

  const deleteImageFloor = (id: any) => {
    console.log(id, arrayFloors)
    const datImg = [...arrayFloors]
    const pos = datImg.findIndex(floor => floor.uid == id)
    datImg.splice(pos, 1)
    // console.log(datU.splice(pos, 1))
    setArrayFloors(datImg)
  }

  const dummyRequest = (options: any) => {
    // console.log('mi option',options, typeof options);
    setTimeout(() => options.onSuccess('ok'), 0);
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <SideBar></SideBar>
      <Layout>
        <NavBar></NavBar>
        <Layout className="site-layout">
          <Content className="user table-general">
            <Row>
              <Col xs={24} lg={24}>
                <div className="card-white">
                  <div className="head-t">
                    <Row>
                      <Col xs={24} lg={16}>
                        <Input allowClear onPressEnter={() => searchFilter(inputSearch)} ref={inputSearch} placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 150 }} />
                        {/* <Checkbox>Archive</Checkbox> */}
                        <Button className="btn-blue" onClick={() => searchFilter(inputSearch)}>Search</Button>
                      </Col>
                      <Col xs={24} lg={8} style={{ textAlign: 'right' }}>
                        {/*<Input placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 150 }} />*/}
                        <Button className="btn-green" onClick={setNewCustomer}>New Customer</Button>
                        <Drawer
                          className="drawer-form custumer-form"
                          title={isUpdate ? 'Edit Customer' : 'New Customer'}
                          placement="right"
                          closable={false}
                          onClose={onClose}
                          visible={visible}
                          width={680}
                          footer={
                            <div
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <div>
                              </div>
                              <Button style={{ marginRight: 8 }} className="btn-border-red" onClick={showDrawer}>
                                Cancel
                            </Button>
                              <Button className="btn-green" onClick={() => form.submit()}>
                                Save
                            </Button>
                            </div>
                          }
                        >
                          <Form form={form} onFinish={registerCustomerDatabase} layout="vertical" hideRequiredMark>
                            <Tabs defaultActiveKey="1">
                              <TabPane tab="Details" key="1">

                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Form.Item name='customer_name' label="*Customer Name:">
                                      <Input placeholder="Please enter Customer Name" onChange={formPageURL} />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='partner_id' label="*Partner:" >
                                      <Select
                                        placeholder="Please select Channel Partner"
                                        allowClear
                                      >
                                        {partners.listPartners.map((part: any) => <Option value={part._id}>{part.channel_partner_name}</Option>)}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='page_url' label="*Page URL:" >
                                      <Input placeholder="Please enter Page URL" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Form.Item name='user_guide_id' label="User Guide:" >
                                      <Select
                                        placeholder="Select a option"
                                        allowClear
                                      >
                                        {links.listLinks.map((link: any) => <Option value={link._id}>{link.user_guide_name}</Option>)}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='customer_logo' label="Customer Logo:">
                                      <Upload customRequest={dummyRequest}>
                                        <Button className="btn-gray" icon={<UploadOutlined />}>Click to Upload</Button>
                                      </Upload>
                                      {/*  <Upload listType="picture-card" onChange={e => { setNImages(e.fileList.length) }}>
                                      {nImages == 1 ? null : <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                      </div>}
                                    </Upload> */}
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Form.Item name='user' label="User:">
                                      <Select
                                        showSearch
                                        placeholder="Please select User"
                                        onChange={(e) => console.log(e)}
                                        optionFilterProp='label'
                                        labelInValue={true}
                                      >
                                        {users.list.map((user: any) => <Option value={user._id} label={user.full_name}>{user.full_name}</Option>)}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name='user_role' label="User Rol:" >
                                      <Select
                                        placeholder="Select a Role"
                                        allowClear
                                      >
                                        <Option value={'Manager'}>Manager</Option>
                                        <Option value={'Staff'}>Staff</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <br />
                                    <Button className="btn-blue" onClick={() => addUserTable()}>Add User</Button>
                                  </Col>
                                </Row>
                                <Row gutter={24}>
                                  <Col span={24}>
                                    <Form.Item name='users' label="Users Associated:">
                                      {dataUser.length !== 0 ? <Table className="table-general" scroll={{ y: 'calc(100vh - 510px)' }} dataSource={dataUser.map((user: any) => {
                                        //console.log('de la tabla', dataUser)
                                        return ({
                                          key: user.user,
                                          user: user.full_name,
                                          role: user.role,
                                          actions: <Tooltip title="Delete"><Button className="btn-delete" onClick={() => deleteUserAssociated(user.user)}><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
                                        })
                                      })} columns={columnsUser}>
                                      </Table> : <Alert message="*Select Users and Role to Asociated at Customer" type="error" />}
                                    </Form.Item>
                                  </Col>
                                </Row>
                                {/* </Form> */}
                              </TabPane>


                              {/* <TabPane tab="Site" key="2">
                                
                                <h6>MAP SITE</h6>
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Form.Item name={"site_name"} label="Site Name:" >
                                      <Input placeholder="Name" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name={'latitude_init'} label="Coordinates Lat 1" >
                                      <Input placeholder="00.000.0.0.0" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name={'longitude_init'} label="Coordinates Long 1" >
                                      <Input placeholder="00.000.0.0.0" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  
                                  <Col span={8}>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name={'latitude_end'} label="Coordinates Lat 2" >
                                      <Input placeholder="00.000.0.0.0" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item name={'longitude_end'} label="Coordinates Long 2" >
                                      <Input placeholder="00.000.0.0.0" />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <h6>FLOOR DATA</h6>
                                <Row gutter={16}>
                                  
                                  <Col span={6}>
                                    <Form.Item name={'floor_image'} label="Floor Image:">
                                      <Upload customRequest={dummyRequest} onChange={e => { setNImages(e.fileList.length) }} maxCount={5} multiple>
                                      
                                        <Button className="btn-gray" icon={<UploadOutlined />}>Click to Upload</Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                  <Col span={8} offset={6}>
                                    <br />
                                    <Button className="btn-blue" onClick={() => addImageTable()}>Add Image</Button>
                                  </Col>
                                </Row>

                                <Row gutter={24}>
                                  <Col span={24}>
                                    <Form.Item name='floors' label="Building floors:">
                                      {arrayFloors.length !== 0 ? <Table className="table-general" scroll={{ y: 'calc(100vh - 600px)' }} dataSource={arrayFloors.map((floor: any) => {
                                        //console.log('de la tabla', dataUser)
                                        return ({
                                          key: floor.uid,
                                          name: floor.name,
                                          actions: <Tooltip title="Delete"><Button className="btn-delete" onClick={() => deleteImageFloor(floor.uid)}><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
                                        })
                                      })} columns={columnsImage}>
                                      </Table> : <Alert message="*Select an image to add at building" type="error" />}
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </TabPane> */}
                            </Tabs>
                          </Form>
                          <div className="fields">*Required Fields</div>
                          {/* <Alert message="*Required Fields" type="error" /> */}
                        </Drawer>
                      </Col>
                    </Row>
                  </div>

                  <Table dataSource={customers.listCustomers.map((customer: any) => {
                    return ({
                      check: <Checkbox></Checkbox>,
                      key: customer._id,
                      customer_id: customer.customer_id,
                      customer_name: <a target={customer.page_url} rel="noopener noreferrer" href={'http://' + customer.page_url}>
                        {customer.customer_name}
                      </a>,
                      page_url: customer.page_url,
                      primary_contact_id: customer.primary_contact,
                      partner_id: customer.channel_partner_name,
                      email: customer.email,
                      n_assets: customer.count_assets,
                      last_update: customer.last_update,
                      actions: <>
                        <Tooltip title="Edit"><Button className="btn-edit" onClick={() => fillForm(customer)}><i className="mdi mdi-pencil"></i></Button></Tooltip>
                        <Tooltip title="Delete"><Button className="btn-delete" onClick={() => showConfirmModal(customer._id)}><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
                        {/* <Tooltip title="Change Password"><Button className="btn-password"><i className="mdi mdi-lock-question"></i></Button></Tooltip> */}
                      </>,
                    })
                  })} columns={columns} pagination={{ pageSize: 30 }} scroll={{ y: 'calc(100vh - 267px)' }} />
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout >
  );
}
