export const SEARCH_RAW_MESSAGE = 'SEARCH_RAW_MESSAGE';

export interface RawMessageState {
  listRawMessage: any[],
  listIcons: any[],
  listAssetMap: {
    type: String,
    features: any[]
  },
  pagination: {
    total: any,
    page: any
  }
};

interface ReplaceSearchRawMessage {
  type: typeof SEARCH_RAW_MESSAGE,
  data: RawMessageState,
  pagination: {
    total: any,
    page: any
  }
}


export type RawMessageType = ReplaceSearchRawMessage 
   