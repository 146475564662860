import { Button, Modal, Pagination, Table, Tooltip } from "antd"
import { Link } from "react-router-dom";
import { HistoryOutlined, DownloadOutlined, ArrowsAltOutlined} from "@ant-design/icons"
import { useEffect, useState } from "react";
import * as datasets from "../../../config/datasets";
import { SERVER } from "../../../config/serverConfig";
import { LIMIT_HISTORY } from "../../../config/constants";
export const AssetHistory = ({ assetId, showModalHistory, setShowModalHistory, showLocationAsset }: any) => {
    const [listHistory, setListHistory] = useState<any>({});

    const exportCSV = async (id: string) => {
        const token: string = datasets.getToken()!;
        datasets.postDataOctet(SERVER.EXPORT_CSV_ASSET_MAP(), { asset_id: id }, token).then(res => {
            console.log('respuesta octet', res)
            const value = "" + res;
            let csvData = new Blob([value], { type: 'text/csv;charset=utf-8;' });
            let csvURL = window.URL.createObjectURL(csvData);
            let tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'activity.csv');
            tempLink.click();
        });
    }

    const columnsHistory = (id: any) => {
        return [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                fixed: true,
                width: 20
            },
            {
                title: 'Location',
                dataIndex: 'location',
                key: 'location',
                fixed: true,
                width: 250
            },
            {
                title: <Tooltip title={'Full Screen'}><Link to={'/locationhistory'}><Button className="btn-green" style={{ float: 'right' }} type="primary" shape="circle" icon={<ArrowsAltOutlined />} size={'middle'} ></Button></Link></Tooltip>,
                dataIndex: 'download',
                key: 'download',
                fixed: true,
                width: 10
            },
            {
                title: <Tooltip title={'Export to CSV'}><Button className="btn-green" style={{ float: 'right' }} type="primary" shape="circle" icon={<DownloadOutlined />} size={'middle'} onClick={() => exportCSV(id)}></Button></Tooltip>,
                dataIndex: 'download',
                key: 'download',
                fixed: true,
                width: 10
            },
        ]
    }
    const getHistoryAsset = async (id: any, page: any) => {
        const myPromise = new Promise((resolve, reject) => {
            datasets.postData(SERVER.GET_HISTORY_ASSET(), { asset_id: id, page: page, limit: LIMIT_HISTORY }, datasets.getToken()).then(async res => {
                console.log('Resp History', res)
                resolve(res)
            })
        })
        return await myPromise
    };
    const handlePageClick = async (id: any, page: any) => {
        const res: any = await getHistoryAsset(id, page);
        setListHistory(res);
    };

    useEffect(() => {
        (async () => {
            if (showModalHistory) {
                var res: any = await getHistoryAsset(assetId, 1);
                setListHistory(res);
            }
        })();
    }, [showModalHistory, assetId]);

    return (
        <Modal
            visible={showModalHistory}
            cancelButtonProps={{ hidden: true }}
            okButtonProps={{ hidden: true }}
            closable={true}
            width="800px"
            onCancel={() => setShowModalHistory(false)}
            title={<span><HistoryOutlined /> History Location Device</span>}
            className='modal-history'
            footer={null}
        >
            <div className="ant-modal-confirm-content">

                <Table
                    className="table-small"
                    style={{ marginBottom: '0px' }}
                    dataSource={(listHistory && listHistory.elements) ? listHistory.elements.map((his: any) => {
                        return ({
                            date: new Date(his.eventDate).toLocaleString("en-US", { timeZone: "America/New_York" }),
                            location: showLocationAsset(his)
                        })
                    }) : []}
                    columns={columnsHistory(assetId)}
                    pagination={false}
                ></Table>
                {

                    (listHistory !== undefined && listHistory.total !== undefined && listHistory.total > 0) &&
                    <Pagination
                        style={{
                            float: 'right',
                            marginTop: '10px',
                            marginBottom: '10px',
                            marginRight: '1%',
                        }}
                        current={listHistory ? listHistory.page : 1}
                        total={listHistory.total}
                        pageSize={LIMIT_HISTORY}
                        showSizeChanger={false}
                        size="small"
                        simple={true}
                        onChange={(page: any) => handlePageClick(assetId, page)}
                    />
                }
            </div>
        </Modal>
    )
}