import { Button, Form, Select } from "antd";
import { useRef } from "react";
import { useAssetDispatch } from "../../../hooks/asset";
import { useCustomerState } from "../../../hooks/customer";


export const SearchAssetPanel = ({ retrieveData, setFilters }: any) => {
    const inputSearch = useRef(null);
    const customers = useCustomerState();
    const [form] = Form.useForm();
    const { getAssetsMapArray } = useAssetDispatch();
    const searchFilter = (values: any) => {
        console.log('searchFilter', values);
        if (values.filters) {
            retrieveData(values.filters);
            setFilters(values.filters);
        }
    };

    const clearFilters = () => {
        form.resetFields();
        if (customers.customerSelected._id !== undefined) {
            getAssetsMapArray({ name: [], customer_id: customers.customerSelected._id });
        }
        else {
            getAssetsMapArray({ name: [] });
        }
    };

    const onChangeFilter = () => {
        retrieveData(form.getFieldValue('filters'));
    };
    return (
        <>
            <div className="card-white" style={{ height: '38%', marginBottom: '10px' }}>
                <div className="card-body scroll-body">
                    <Form form={form} onFinish={searchFilter} hideRequiredMark>
                        <Form.Item name='filters' style={{ marginBottom: '5px' }}>
                            <Select
                                ref={inputSearch}
                                placeholder="Please Enter Filters"
                                mode={'tags'}
                                style={{ width: '100%' }}
                                open={false}
                                showSearch
                                onChange={() => onChangeFilter()}
                            >
                            </Select>
                        </Form.Item>
                    </Form>

                </div>

                <div className="card-footer">
                    <Button className="btn-border-red" style={{ marginRight: '10px' }} onClick={() => clearFilters()}>CANCEL</Button>
                    <Button className="btn-green" onClick={() => form.submit()} >SEARCH</Button>
                </div>
            </div>
        </>
    )
}