import React from 'react';
import { Layout, Menu, Breadcrumb, Row, Col, Input, Dropdown, Button } from 'antd';
import { Link } from 'react-router-dom';
import { SideBar }  from '../Shared/SideBar'
import { NavBar }  from '../Shared/NavBar'
import { AudioOutlined, DownOutlined } from '@ant-design/icons';

const { Header, Content, Sider } = Layout;

export const Flat = () => {
  return (
    <Layout style={{height: '100vh' }}>
      <SideBar></SideBar>
      <Layout>
       <NavBar></NavBar>
        <Layout className="site-layout">
          <Content>
            <Row gutter={[16, 0]}>
              <Col xs={24} lg={19}>
                <div className="map card-white">
                  {/*Aqui va el mapitash*/}
                </div>
              </Col>
              <Col xs={24} lg={5} className="information-map">
                <div className="card-white" style={{marginBottom:'10px', height:'calc(25% - 10px)'}}>
                  <div className="card-body scroll-body-01" style={{borderRadius:'10px'}}>
                    <h5>Hospital Pediátrico Dr. A. Castelán -M.S.P.</h5>
                    <h5>First Floor</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ut enim ad
                    </p>
                  </div>
                </div>
                <div className="card-white" style={{marginBottom:'10px', height:'calc(45% - 10px)'}}>
                  <div className="card-head">Device</div>
                  <div className="card-body scroll-body-02">
                    <h6>No. 1234543</h6>
                    <p><label>Cite:</label> Nebraska</p>
                    <p><label>Place:</label> Ambulancia-A124</p>
                    <p><label>Hospital:</label> Nebraska Hospital</p>
                    <p><label>Description:</label></p>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                   </p>
                  </div>
                </div>
                <div className="card-white" style={{height:'30%'}}>
                  <div className="card-head">Areas</div>
                  <div className="card-body scroll-body-02">
                    <h4>
                      <div style={{height:'15px', width:'15px', borderRadius:'50%', background:'#FBB476'}}></div>
                      LABORATORY
                    </h4>
                    <h4>
                      <div style={{height:'15px', width:'15px', borderRadius:'50%', background:'#CC5DFC'}}></div>
                      ICU
                    </h4>
                    <h4>
                      <div style={{height:'15px', width:'15px', borderRadius:'50%', background:'#E7BFFB'}}></div>
                      SURGERY
                    </h4>
                    <h4>
                      <div style={{height:'15px', width:'15px', borderRadius:'50%', background:'#AFFEEB'}}></div>
                      OFFICE
                    </h4>
                    <h4>
                      <div style={{height:'15px', width:'15px', borderRadius:'50%', background:'#AEFE2D'}}></div>
                      PHARMA
                    </h4>
                    <h4>
                      <div style={{height:'15px', width:'15px', borderRadius:'50%', background:'#F7FD98'}}></div>
                      RECOVERY
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
