import { DeviceState, SHOW_DEVICES, DeviceType, GET_DATA_MAP, SEARCH_DEVICES, SET_LIST_DEVICES } from '../types'

const initialState = {
    listDevices: [],
    listDeviceMap: [],
    pagination: {}
}
export const deviceReducer = (state: DeviceState = initialState, action: DeviceType) => {
    switch (action.type) {
        case SEARCH_DEVICES:
         return Object.assign({}, state, { listDevices: action.data, pagination: action.pagination });
        case SHOW_DEVICES:
            return Object.assign({}, state, { listDevices: action.data});
        case GET_DATA_MAP:
            return Object.assign({}, state, { listDeviceMap: action.data});
        case SET_LIST_DEVICES:
            return Object.assign({}, state, { listDevices: action.data});
        default:
            return state
    }
}