import React, { useState, useEffect, useRef } from 'react';
import { Divider, message, Alert, Layout, Menu, Row, Col, Input, Dropdown, Button, Table, Checkbox, Tooltip, Form, Select, Drawer, Switch, Upload, Modal, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { SideBar } from '../Shared/SideBar'
import { NavBar } from '../Shared/NavBar'
import { AudioOutlined, DownOutlined, SearchOutlined, UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDeviceTypeDispatch, useDeviceTypeState } from '../../hooks/deviceType';
import * as datasets from "../../config/datasets";
import { SERVER } from '../../config/serverConfig';
import { listDevices } from '../../store/actions';
import { DESCEND, ASCEND } from '../../config/constants';
const { Header, Content, Sider } = Layout;
const { Search } = Input;
const { Option } = Select;
/* const dataSource = [
  {
    device: <img src="/Images/icon-001.svg" alt="" width="25px" />,
    model: 'lorem',
    hw: 'lorem',
    sw: 'lorem',
    script: 'lorem',
    serial: 'lorem',
    esn: 'lorem',
    imei: 'lorem',
    mac: 'lorem',
    sms: 'lorem',
    gps: 'lorem',
    ble: 'lorem',
    hours: 'lorem',
    miles: 'lorem',
    power: 'lorem',
    document: 'lorem',
    guide: 'lorem',
    image: 'lorem',
  },
];
*/


export const DeviceTypes = () => {

  const { listDeviceTypes } = useDeviceTypeDispatch();
  const deviceTypes = useDeviceTypeState()
  const [isUpdate, setIsUpdate] = useState(false);
  const [userUpdate, setUserUpdate] = useState({});
  const [form] = Form.useForm()
  const [filteredInfo, setFilteredInfo] = useState([]);
  const inputSearch = useRef(null)


  useEffect(() => {
    listDeviceTypes()
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filteredValue: filteredInfo,
      defaultSortOrder: ASCEND,
      sorter: (a: any, b: any) => {
        // console.log('sorter', a, b);
        return a.name.toUpperCase().charCodeAt(0) - b.name.toUpperCase().charCodeAt(0)
      },
      onFilter: (value: any, record: any) => {
        console.log('onFilter', value, record);
        let valorIn = value.toLowerCase()
        //console.log(record.devicetype.toString().toLowerCase().includes(valorIn), valorIn, record.devicetype.toString().toLowerCase())
        return record.name.toString().toLowerCase().includes(valorIn) || record.name.toString().toLowerCase().includes(valorIn)
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: true
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
    }
  ];
  const fillForm = (values: any) => {
    setVisible(true);
    setIsUpdate(true)
    console.log(values)
    const deviceFill = Object.assign({}, values)
    if (values.spec_document !== '')
      deviceFill.spec_document = [{ name: values.spec_document, status: 'done' }]
    if (values.installation_guide !== '')
      deviceFill.installation_guide = [{ name: values.installation_guide, status: 'done' }]
    if (values.image_1 !== '')
      deviceFill.image_1 = [{ name: values.image_1, status: 'done' }]
    if (values.image_2 !== '')
      deviceFill.image_2 = [{ name: values.image_2, status: 'done' }]
    if (values.image_3 !== '')
      deviceFill.image_3 = [{ name: values.image_3, status: 'done' }]
    if (values.image_4 !== '')
      deviceFill.image_4 = [{ name: values.image_4, status: 'done' }]
    form.setFieldsValue(deviceFill)
    console.log(deviceFill)
    setUserUpdate(values)
  }
  const clearAll = () => {
    setFilteredInfo([])
    // setDropTitle([])
    console.log(filteredInfo)
  }
  const newDevTypeForm = () => {
    showDrawer()
    setIsUpdate(false);
    clearForm();
  }
  const searchFilter = (element: any) => {
    clearAll()
    console.log(element)
    let textSearch = ''
    if (element.current.state.value !== undefined) {
      textSearch = element.current.state.value
    }
    const arrayFilter: any = []
    arrayFilter.push(textSearch)
    setFilteredInfo(arrayFilter)
  }

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  const onClose = () => {
    setVisible(false);
  };

  const registerDevTypeDatabase = (values: any) => {
    console.log('register', form.getFieldsValue(), form)
    const dataForm: FormData = new FormData();
    console.log('Success:', values);
    if (!isUpdate) {
      const device_type = {
        name: values.name,
        description: values.description,
        state: values.state,
        serial_number: values.serial_number,
        esn: values.esn,
        imei: values.imei,
        mac_address: values.mac_address,
        sms_number: values.sms_number,
        gps: values.gps,
        ble: values.ble,
        hours_of_use: values.hours_of_use,
        miles_of_use: values.miles_of_use
      }
      dataForm.append('request', JSON.stringify(device_type));
      if (values.spec_document !== undefined)
        dataForm.append('specification', values.spec_document.file.originFileObj);
      if (values.installation_guide !== undefined)
        dataForm.append('guide', values.installation_guide.file.originFileObj);
      if (values.image_1 !== undefined)
        dataForm.append('image1', values.image_1.file.originFileObj);
      if (values.image_2 !== undefined)
        dataForm.append('image2', values.image_2.file.originFileObj);
      if (values.image_3 !== undefined)
        dataForm.append('image3', values.image_3.file.originFileObj);
      if (values.image_4 !== undefined)
        dataForm.append('image4', values.image_4.file.originFileObj);

      datasets.postDataFiles(SERVER.REGISTER_DEVTYPE_FILE(), dataForm, datasets.getToken()).then(res => {
        listDeviceTypes();
        onClose();
        clearForm();
      })
    } else {
      updateDeviceTypeDatabase(values, userUpdate)
      setIsUpdate(false)
    }
  }
  const updateDeviceTypeDatabase = (dataDevice: any, deviceUpdate: any) => {
    console.log('Update Data:', dataDevice, deviceUpdate);
    const dataToUpdate = dataDevice
    dataToUpdate._id = deviceUpdate._id
    console.log('Update Data:', dataDevice, deviceUpdate, dataToUpdate);
    datasets.putData(SERVER.UPDATE_DEVICETYPE(), dataToUpdate, datasets.getToken()).then(res => {
      listDeviceTypes();
      onClose();
      setIsUpdate(false)
    })
  }

  const clearForm = () => {
    form.resetFields();
  }

  const deleteDeviceTypeDatabase = (id: any) => {
    console.log('delete DeviceType', id)
    datasets.deleteData(SERVER.DELETE_DEVICETYPE(id), datasets.getToken()).then(res => {
      message.success('DeviceType Removed successfully', 3);
      listDeviceTypes();
    })
  }
  const showConfirmModal = (id: any) => {
    //setPartnerId(id);
    Modal.confirm({
      title: "Remove Device Type",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to remove Device Type?',
      onOk() {
        deleteDeviceTypeDatabase(id);
        listDeviceTypes();
      }
    });
  };

  /*const retrieveDataPageNumber = (pageNumber: any) => {
    //console.log('retrieve Data Devices', customers.customerSelected, userProfile.is_admin) 
    if (userProfile.is_admin) {
      searchDevices({ customer_id: customers.customerSelected._id ? customers.customerSelected._id : '', limit: 10, page: pageNumber });
    } else {
      if (!customers.customerSelected._id) {
        searchDevices({ customer_id: '123431242134', limit: 10, page: pageNumber });
      } else {
        searchDevices({ customer_id: customers.customerSelected._id, limit: 10, page: pageNumber });
      }
    }
  }*/

  return (
    <Layout style={{ height: '100vh' }}>
      <SideBar></SideBar>
      <Layout>
        <NavBar></NavBar>
        <Layout className="site-layout">
          <Content className="user table-general">
            <Row>
              <Col xs={24} lg={24}>
                <div className="card-white">
                  <div className="head-t">
                    <Row>
                      <Col xs={24} lg={16}>
                        <Input allowClear onPressEnter={() => searchFilter(inputSearch)} ref={inputSearch} placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 200 }} />
                        <Button className="btn-blue" onClick={() => searchFilter(inputSearch)}>Search</Button>
                      </Col>
                      <Col xs={24} lg={8} style={{ textAlign: 'right' }}>
                        <Button className="btn-green" onClick={newDevTypeForm}>New Device Type</Button>
                        <Drawer
                          className="drawer-form"
                          title="New Device Type"
                          placement="right"
                          closable={false}
                          onClose={onClose}
                          visible={visible}
                          width={500}
                          footer={
                            <div
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <Button style={{ marginRight: 8 }} className="btn-border-red" onClick={showDrawer}>
                                Cancel
                              </Button>
                              <Button className="btn-green" onClick={() => form.submit()}>
                                Save
                              </Button>
                            </div>
                          }
                        >
                          <h5>DETAILS</h5>
                          <Form form={form} onFinish={registerDevTypeDatabase} style={{ marginBottom: '10px' }} layout="vertical" hideRequiredMark>
                            <Row gutter={16}>
                              <Col span={10}>
                                <Form.Item name='name' label="*Name:">
                                  <Input placeholder="Please enter Name" />
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <Form.Item name='description' label="Description:" >
                                  <Input placeholder="Please enter description" />
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Form.Item name='state' label="State:" valuePropName="checked">
                                  <Switch />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>


                          <h5>IDENTIFIERS</h5>
                          <Form form={form} onFinish={registerDevTypeDatabase} className="form-second" labelCol={{ span: 16 }} wrapperCol={{ span: 8 }} >
                            <Row gutter={16}>
                              <Col span={8}>
                                <Form.Item name='serial_number' label="Serial Number:" valuePropName="checked" >
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='esn' label="ESN:" valuePropName="checked"  >
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item name='imei' label="IMEI:" valuePropName="checked">
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={8}>
                                <Form.Item name='mac_address' label="MAC Address:" valuePropName="checked">
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='sms_number' label="SMS Number:" valuePropName="checked"  >
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>

                          <h5>PROPERTIES</h5>
                          <Form form={form} onFinish={registerDevTypeDatabase} className="form-second" labelCol={{ span: 15 }} wrapperCol={{ span: 9 }} >
                            <Row gutter={16}>
                              <Col span={8}>
                                <Form.Item name='gps' label="GPS:" valuePropName="checked" >
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='ble' label="BLE:" valuePropName="checked" >
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='hours_of_use' label="Hours of Use:" valuePropName="checked" >
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>

                            </Row>
                            <Row gutter={16}>
                              <Col span={8}>
                                <Form.Item name='temperature' label="Temperature:" valuePropName="checked">
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='speed' label="Speed:" valuePropName="checked">
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='heading' label="Heading:" valuePropName="checked">
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='eventType' label="Event Type:" valuePropName="checked">
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='miles_of_use' label="Miles of Use:" valuePropName="checked" >
                                  <Switch size="small" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>

                          <h5>DOCUMENTATION</h5>
                          <Form form={form} onFinish={registerDevTypeDatabase} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" hideRequiredMark>
                            <Row gutter={16}>
                              <Col span={8}>
                                <Form.Item name='spec_document' label="Spec. Document:" valuePropName="defaultFileList">
                                  <Upload showUploadList={{ showRemoveIcon: false }} >
                                    <Button className="btn-gray" icon={<UploadOutlined />}>Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='installation_guide' label="Guide:" valuePropName="defaultFileList">
                                  <Upload showUploadList={{ showRemoveIcon: false }}>
                                    <Button className="btn-gray" icon={<UploadOutlined />}>Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='image_1' label="Image 1:" valuePropName="defaultFileList">
                                  <Upload showUploadList={{ showRemoveIcon: false }}>
                                    <Button className="btn-gray" icon={<UploadOutlined />}>Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={8}>
                                <Form.Item name='image_2' label="Image 2:" valuePropName="defaultFileList">
                                  <Upload showUploadList={{ showRemoveIcon: false }}>
                                    <Button className="btn-gray" icon={<UploadOutlined />}>Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='image_3' label="Image 3:" valuePropName="defaultFileList">
                                  <Upload showUploadList={{ showRemoveIcon: false }}>
                                    <Button className="btn-gray" icon={<UploadOutlined />}>Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name='image_4' label="Image 4:" valuePropName="defaultFileList">
                                  <Upload showUploadList={{ showRemoveIcon: false }}>
                                    <Button className="btn-gray" icon={<UploadOutlined />}>Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>


                          <Alert message="*Required Fields" type="error" />
                        </Drawer>
                      </Col>
                    </Row>
                  </div>

                  <Table dataSource={deviceTypes.listDeviceTypes.map((deviceType: any) => {
                    return ({
                      check: <Checkbox></Checkbox>,
                      key: deviceType._id,
                      uid: deviceType._id,
                      name: deviceType.name,
                      description: deviceType.description,
                      state: deviceType.state,
                      actions: <>
                        <Tooltip title="Edit"><Button className="btn-edit" onClick={() => fillForm(deviceType)}><i className="mdi mdi-pencil"></i></Button></Tooltip>
                        <Tooltip title="Delete"><Button className="btn-delete" onClick={() => showConfirmModal(deviceType._id)}><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
                        {/* <Tooltip title="Change Password"><Button className="btn-password"><i className="mdi mdi-lock-question"></i></Button></Tooltip> */}
                      </>,
                    })
                  })} columns={columns} pagination={{ pageSize: 30 }} scroll={{ y: 'calc(100vh - 267px)' }} />
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
