import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Tooltip } from "antd";
import { useEffect, useState } from 'react';
import { useAssetState } from '../../../hooks/asset';
import { useAddressDispatch } from '../../../hooks/address';
export const AssetsPanel = ({ showLocationAsset, setCenterMap, filters, onShowHistory }: any) => {
    const [nElementMenu, setNElementMenu] = useState(10);
    const [assetsMenu, setAssetsMenu] = useState<any>([]);
    const assets = useAssetState();
    const { loadAddressesByIds } = useAddressDispatch();
    const [firstLoad, setFirstLoad] = useState(true);
    const handleLoadAssets = () => {
        if (assets.listAssetMap.features.length) {
            loadAddresses(nElementMenu, nElementMenu + 10);
            const newData = assets.listAssetMap.features.slice(0, nElementMenu + 10);
            setNElementMenu(newData.length)
            setAssetsMenu([...newData])
            console.log(assetsMenu)
        }
    };

    const showDetailsAsset = async (asset: any, index: number) => {
        let newArr = [...assetsMenu]
        newArr[index].show_details = !newArr[index].show_details
        setAssetsMenu(newArr)
    };

    const loadAddresses = async (leftIndex: any, quantity: any) => {
        const ids = assets.listAssetMap.features.slice(leftIndex, leftIndex + quantity).map((item: any) => item.id);
        loadAddressesByIds({ ids });
    }

    useEffect(() => {
        if (!firstLoad) {
            loadAddresses(0, nElementMenu);
        }
    }, [firstLoad]);

    useEffect(() => {
        if (firstLoad && assets.listAssetMap.features.length) {
            setFirstLoad(false)
        }

        const newData = assets.listAssetMap.features.slice(0, nElementMenu);
        console.log(newData);
        setAssetsMenu([...newData]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assets]);

    useEffect(() => {
        setNElementMenu(10);
        setFirstLoad(true);
    }, [filters]);

    return (
        <>
            <div className="card-white" style={{ height: 'calc(62% - 10px)' }}>
                <div id={'scrollableDiv'} className="card-body scroll-body-info">

                    <InfiniteScroll
                        dataLength={nElementMenu}
                        next={handleLoadAssets}
                        hasMore={assets.listAssetMap.features.length > assetsMenu.length}
                        loader={assets.listAssetMap.features.length > assetsMenu.length ? <h4>Loading...</h4> : 'No More data'}
                        style={{ overflowX: 'hidden' }}
                        scrollableTarget='scrollableDiv'
                        endMessage={''}>


                        {assetsMenu.map((asset: any, index: any) => {
                            const lastPingDevice = asset.properties.devices.reduce((a: any, b: any) => {
                                return a.last_update > b.last_update ? a : b
                            });
                            const secondaryData: any = [];
                            if (lastPingDevice.event.length > 0) {
                                secondaryData.push(<Tooltip placement="top" title={'Event'}><p><img src="/Images/map/bell.svg" height="14px" alt='' />{lastPingDevice.event[0].eventName}</p></Tooltip>);
                            }
                            if (lastPingDevice.tempSensor0_C && lastPingDevice.devicetype[0]?.temperature) {
                                secondaryData.push(<Tooltip placement="top" title={'Temperature'}><p><img src="/Images/map/temperature.svg" height="14px" alt='' />{(lastPingDevice.tempSensor0_C).slice(0, -2) + "." + (lastPingDevice.tempSensor0_C).slice(2) + " °C"}</p></Tooltip>);
                            }
                            if (lastPingDevice.devicetype[0]?.speed) {
                                secondaryData.push(<Tooltip placement="top" title={'Speed'}><p><img src="/Images/map/speedometer.svg" height="14px" alt='' />{lastPingDevice.speed} km/h</p></Tooltip>);
                            }
                            if (lastPingDevice.devicetype[0]?.heading) {
                                secondaryData.push(<Tooltip placement="top" title={'Heading'}><p><img src="/Images/map/compass.svg" height="14px" alt='' />{lastPingDevice.heading} </p></Tooltip>);
                            }

                            return (
                                <>
                                    <div className="map-info">
                                        <div className="map-info-head">
                                            <h3>{lastPingDevice.asset_name}</h3>
                                            {/* <span>{asset.properties.asset_type_name}</span> */}
                                            <p>{lastPingDevice.asset_type_name}</p>
                                        </div>
                                        <div className="map-info-body">
                                            <div style={{ width: '70%' }}>
                                                <p>{lastPingDevice.last_update}</p>
                                                <p>{showLocationAsset(lastPingDevice)}</p>

                                                {
                                                    secondaryData.map((data: any, index: any) => {
                                                        if (index % 2 === 1) {
                                                            return <></>;
                                                        }
                                                        return (
                                                            <div className="map-info-icons">
                                                                {secondaryData[index]}
                                                                {(secondaryData.length > index + 1) && secondaryData[index + 1]}
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                            <div style={{ width: '30%', textAlign: 'right' }}>
                                                <img src={"/Images/assetIcons/" + lastPingDevice.icon} alt="" width="50px" />
                                            </div>
                                        </div>
                                        {
                                            asset.show_details && asset.properties.devices ? <>
                                                {asset.properties.devices.map((device: any) => {
                                                    const address = device.device_imei ? device.device_imei : device.device_esn;

                                                    const secondaryData: any = [];
                                                    if (device.event.length > 0) {
                                                        secondaryData.push(<Tooltip placement="top" title={'Event'}><p><img src="/Images/map/bell.svg" height="14px" alt='' />{device.event[0].eventName}</p></Tooltip>);
                                                    }
                                                    if (device.tempSensor0_C && device.devicetype[0]?.temperature) {
                                                        secondaryData.push(<Tooltip placement="top" title={'Temperature'}><p><img src="/Images/map/temperature.svg" height="14px" alt='' />{(device.tempSensor0_C).slice(0, -2) + "." + (device.tempSensor0_C).slice(2) + " C"}</p></Tooltip>);
                                                    }
                                                    if (device.devicetype[0]?.speed) {
                                                        secondaryData.push(<Tooltip placement="top" title={'Speed'}><p><img src="/Images/map/speedometer.svg" height="14px" alt='' />{device.speed} km/h</p></Tooltip>);
                                                    }
                                                    if (device.devicetype[0]?.heading) {
                                                        secondaryData.push(<Tooltip placement="top" title={'Heading'}><p><img src="/Images/map/compass.svg" height="14px" alt='' />{device.heading} </p></Tooltip>);
                                                    }
                                                    return (
                                                        <div key={address}>
                                                            <hr style={{ width: '70%' }} />

                                                            <div className="map-info-body">
                                                                <div style={{ width: '100%' }}>
                                                                    <p><b>{device.device_name} </b>{device.last_update}</p>
                                                                    <p>{showLocationAsset(device)}</p>
                                                                    {
                                                                        secondaryData.map((data: any, index: any) => {
                                                                            if (index % 2 === 1) {
                                                                                return <></>;
                                                                            }
                                                                            return (
                                                                                <div className="map-info-icons">
                                                                                    {secondaryData[index]}
                                                                                    {(secondaryData.length > index + 1) && secondaryData[index + 1]}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                                : <></>
                                        }
                                        <div className="card-footer">
                                            {/* <span>({asset.properties.latitude.substring(0, 6) + ', ' + asset.properties.longitude.substring(0, 7)})</span> */}
                                            <Tooltip placement="top" title={'Find on Map'}>
                                                <Button className="btn-blue" style={{ margin: '0px 2px' }} onClick={() => setCenterMap(asset)}><i className="mdi mdi-map-marker-radius" ></i></Button>
                                            </Tooltip>
                                            <Tooltip placement="top" title={'View History'}>
                                                <Button className="btn-yellow" onClick={() => {
                                                    onShowHistory(lastPingDevice.asset_id)
                                                    //setAssetSelected(lastPingDevice.asset_id)
                                                }
                                                }
                                                ><i className="mdi mdi-history"></i></Button>
                                            </Tooltip>
                                            <Tooltip placement="top" title={!asset.show_details ? 'More Details' : 'Less Details'}>
                                                <Button className="btn-blue" style={{ margin: '0px 2px' }} onClick={() => { showDetailsAsset(asset, index) }}><i className={!asset.show_details ? "mdi mdi-plus-circle-outline" : "mdi mdi-minus-circle-outline"} ></i></Button>
                                            </Tooltip>
                                        </div>

                                        {/* <h5>Last Updated: <b>{asset.properties.last_update}</b></h5> */}
                                    </div>
                                    <hr />
                                </>
                            )
                        })}

                    </InfiniteScroll>
                </div>
            </div>
        </>
    )
}