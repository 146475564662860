import { useDispatch, useSelector } from 'react-redux';

import { loadCustomers, searchCustomers, customerSelected } from '../store/actions';
import { RootState } from '../store/reducers';

export const useCustomerState = () => {
  return useSelector((state: RootState) => state.customer);
  
};

export const useCustomerDispatch = () => {
  const dispatch = useDispatch();
  return {
    listCustomer: () => dispatch(loadCustomers()),
    searchCustomers: (data: any) => dispatch(searchCustomers(data)),
    customerSelected: (customer: any) => dispatch(customerSelected(customer))
  };
};

