import {
  CampusState, SEARCH_CAMPUS, SAVE_CAMPUS, UPDATE_CAMPUS, DELETE_CAMPUS, CampusType, VALIDATE_NAME
} from '../types';

const initialState = {
  listCampus: []
}

export const campusReducer = (state: CampusState = initialState, action: CampusType) => {
  switch (action.type) {
    case SEARCH_CAMPUS:
      return Object.assign({}, state, { listCampus: action.data });
    case SAVE_CAMPUS: 
      return Object.assign({}, state, { objectCampus: action.data });
    case UPDATE_CAMPUS:
      return Object.assign({}, state, { objectCampus: action.data });
    case DELETE_CAMPUS:
      return Object.assign({}, state, { objectCampus: action.data });
    case VALIDATE_NAME:
      return Object.assign({}, state, { objectCampus: action.data });
    default:
      return state;
  }
}