import { useEffect, useRef, useState } from "react";
import { useCampusState } from "../../hooks/campus";
import MapOpenLayers, { ChangeMarkersMap, getBuildingLayer, getlistLayersAsset, getMap, getSatelliteLayer, showFloorsImages, upfloor } from "./MapOpenLayers";
import { Popup } from "./Popup";
import { RadiusSettingOutlined } from '@ant-design/icons';
import * as datasets from "../../config/datasets";
import { SERVER } from "../../config/serverConfig";
import { getRequest } from "../../config/httpClient";
import Static from 'ol/source/ImageStatic';
import { transformExtent } from 'ol/proj';
import { Button, Collapse, Dropdown, Menu, Select, Tooltip } from "antd";
import {
    Circle as CircleStyle, Fill, Icon, Stroke, Style, Text
} from 'ol/style';
import { useAssetTypeState } from "../../hooks/assetType";
const { Option } = Select;
const { Panel } = Collapse;

const extentPlane = transformExtent([-86.7489616045132, 35.9761444970117, -86.7486794153319, 35.9759278932055], 'EPSG:4326', 'EPSG:3857');
export const MapAssets = ({
    markerPopup, showLocationAsset, onSelectMarker, retrieveData, setShowCampusMenu, setBuildingMenu,
    setNFloor, nfloor, setShowBuildingMenu, showBuildingMenu, setFloorMenu }: any) => {
    const [floorImages, setFloorImages] = useState<any>([]);
    const [messageMap] = useState(new Date(Date.now()).toLocaleString("en-US", { timeZone: "America/New_York" }))
    const mapRef = useRef();
    const [showAsset] = useState(-1);
    const campuslist = useCampusState();
    const [campusSelected, setCampusSelected] = useState('');
    const [showFloors, setShowFloors] = useState(false);
    const [showFloorsMenu, setShowFloorsMenu] = useState(false);
    const assetTypeList = useAssetTypeState();

    const onSelectTypeMarker = (type: number) => {
        const listLayersAsset = getlistLayersAsset()
        console.log('typemarker', type)
        switch (type) {
            case 0:
                listLayersAsset[0].setVisible(true)
                console.log('to list features with different green markers')
                listLayersAsset[0].setStyle(function (feature: any) {
                    var size = feature.get('features').length;

                    if (size === 1) {
                        return (new Style({
                            image: new Icon({
                                anchor: [0.5, 1],
                                src: 'Images/map/02.svg',
                            }),
                        }))
                    } else {
                        return (new Style({
                            image: new CircleStyle({
                                radius: 15,
                                stroke: new Stroke({
                                    color: '#fff',
                                }),
                                fill: new Fill({
                                    color: '#f8bb12',
                                }),
                            }),
                            text: new Text({
                                text: size.toString(),
                                fill: new Fill({
                                    color: '#fff',
                                }),
                            }),
                        }))
                    }
                })
                break;
            case 1:
                listLayersAsset[0].setVisible(true)

                console.log('to list features with different icon')
                listLayersAsset[0].setStyle(function (feature: any) {
                    var size = feature.get('features').length;
                    // console.log('rubick 3', feature, feature.getProperties().features[0].getProperties().icon)
                    let newIcon = feature.getProperties().features[0].getProperties().devices[0].icon
                    console.log('new Icon', newIcon.substring(0, newIcon.length - 4), showAsset)
                    if (size === 1) {
                        return (new Style({
                            image: new Icon({
                                anchor: [0.5, 0.5],
                                src: newIcon ? 'Images/assetpng/' + newIcon.substring(0, newIcon.length - 4) + '.png' : 'Images/map/02.svg',
                                scale: 0.2
                            }),
                        }))
                    } else {
                        return (new Style({
                            image: new CircleStyle({
                                radius: 15,
                                stroke: new Stroke({
                                    color: '#fff',
                                }),
                                fill: new Fill({
                                    color: '#f8bb12',
                                }),
                            }),
                            text: new Text({
                                text: size.toString(),
                                fill: new Fill({
                                    color: '#fff',
                                }),
                            }),
                        }))
                    }
                })
                break;
            case 2:
                console.log('to HIDE features ')
                listLayersAsset.forEach(element => {
                    element.setVisible(false)
                });

                break;
            default:
                break;
        }
    };
    const getImageDatabase = async () => {
        const buildingId = campuslist.listCampus[0].buildings[0]._id
        const token: string = datasets.getToken()!;
        await getRequest(SERVER.GET_FLOORPLAN_IMAGE_LOCAL(buildingId), token).then(async (result: any[]) => {
            const arrayImages = result
            setFloorImages([...arrayImages])
        });
    };

    const getCampusMenu = async (id: any) => {
        datasets.getData(SERVER.GET_COUNT_ASSET_BY_CAMPUS_ID(id), datasets.getToken()).then(async res => {
            console.log('Resp menu campus', res)
            setBuildingMenu(res)
        })
    };

    const showSatelliteLayer = () => {
        const satLayer = getSatelliteLayer()
        satLayer.setVisible(!satLayer.getVisible())
    };

    const getBuildingImage = async () => {
        const buildingId = campuslist.listCampus[0].buildings[0]._id
        const buildingLayer = getBuildingLayer()
        if (buildingId !== undefined) {
            datasets.getData(SERVER.GET_BUILDING_BY_ID(buildingId), datasets.getToken()).then(data => {
                if (data.image) {
                    buildingLayer.setSource(new Static({
                        url: 'data:image/png;base64,' + data.image,
                        imageExtent: extentPlane,
                    }))
                    //setImageBuilding('data:image/png;base64,' + data.image);
                } else {
                }
            })
        }
    };

    const getBuildingMenu = async () => {
        const buildingId = campuslist.listCampus[0].buildings[0]._id
        datasets.getData(SERVER.GET_BUILDING_BY_ID_ASSET(buildingId), datasets.getToken()).then(async res => {
            console.log('Resp menu building', res)
            setFloorMenu(res)
        })
    };

    const clearFloors = () => {
        showFloorsImages(showFloors)
        // setShowFloors(!showFloors)
        setShowFloors(showFloors)
    };
    const zoomIn = () => {
        var map = getMap()
        var view = map.getView()
        var zoom = view.getZoom()
        view.setZoom(zoom + 0.5)
    };
    const zoomOut = () => {
        var map = getMap()
        var view = map.getView()
        var zoom = view.getZoom()
        view.setZoom(zoom - 0.5)
    };


    const menuFloor = (
        <Menu>
            <Menu.Item onClick={(e) => { setNFloor('A'); upfloor(e.key); ChangeMarkersMap(e.key); setShowBuildingMenu(!showBuildingMenu); getBuildingMenu() }} key="0" >
                <b>Building</b>
            </Menu.Item>
            {floorImages.map((floor: any, index: number) => {
                //console.log('floor data', floor)
                return (
                    <Menu.Item onClick={(e) => { setNFloor((index + 1).toString()); upfloor(e.key); ChangeMarkersMap(e.key) }} key={index + 1} icon={<RadiusSettingOutlined />}>
                        {floor.floor_name}
                    </Menu.Item>
                )
            })}
            <Menu.Item onClick={(e) => { setNFloor('N'); clearFloors(); onSelectTypeMarker(2) }} key="A">
                <b>None</b>
            </Menu.Item>
        </Menu>
    );

    const menuMarkers = (
        <Menu>
            <Menu.Item onClick={(e) => { onSelectTypeMarker(0) }} key="0">
                Green Markers
            </Menu.Item>
            <Menu.Item onClick={(e) => { onSelectTypeMarker(1) }} key="1">
                Asset Icons
            </Menu.Item>
            <Menu.Item onClick={(e) => { onSelectTypeMarker(2) }} key="2">
                Hide Markers
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        if (campuslist.listCampus.length) {
            getBuildingImage();
            getImageDatabase();
            setCampusSelected(campuslist.listCampus[0]._id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campuslist.listCampus])

    return (
        <div className="map card-white">
            <Popup markerPopup={markerPopup} showLocationAsset={showLocationAsset} />
            <MapOpenLayers ref={mapRef} onSelectMarker={onSelectMarker} floorImages={floorImages} retrieveData={retrieveData}></MapOpenLayers>
            {/*Aqui va el mapitash*/}
            <div className="head-map">

                <Select
                    dropdownMatchSelectWidth={true}
                    style={{ width: 200 }}
                    // dataSource={campuslist.listCampus}
                    optionFilterProp='label'
                    // labelInValue={true}
                    // options={options}
                    onSelect={(value: any) => { console.log('select build or campus', value); setShowCampusMenu(true); getCampusMenu(value); setCampusSelected(value) }}
                    value={campusSelected}
                >
                    {campuslist.listCampus.map((campus: any) =>
                    //
                    {
                        return (<Option value={campus._id} key={campus._id}>{campus.name}</Option>)
                    }

                    )}
                </Select>

            </div>
            <div className="head-map" style={{ left: 'auto', right: '20px' }}>{getSatelliteLayer().getVisible() ? 'Map was updated at: ' + messageMap : ''}</div>
            <div className="footer-map">
                {/* <Tooltip placement="left" title={'Show/Hide Floors'}>
                      <Button onClick={() => clearFloors()}>C</Button>
                      </Tooltip> */}
                <Tooltip placement="left" title={'Change Floor'}>
                    <Dropdown overlay={menuFloor} placement="topLeft" arrow trigger={['click']}>
                        {/* <Button>{nfloor === '' ? <img src="/Images/map/05.svg" alt="" width="15px" /> : nfloor}</Button> */}
                        <Button onClick={() => setShowFloorsMenu(!showFloorsMenu)}>{nfloor === '' ? 'A' : nfloor}</Button>

                    </Dropdown>
                </Tooltip>
                <Tooltip placement="left" title={'Marker Type'}>
                    <Dropdown overlay={menuMarkers} placement="topLeft" arrow trigger={['click']}>
                        <Button onClick={() => {

                        }} ><img src="/Images/map/02.svg" alt="" width="15px" /></Button>
                    </Dropdown>
                </Tooltip>
                <Tooltip placement="left" title={'Map / Satellite View'}>
                    <Button onClick={() => showSatelliteLayer()}><i className="mdi mdi-satellite-variant" style={{ fontSize: '18px', color: '#1768AC' }}></i></Button>
                </Tooltip>
                <Tooltip placement="left" title={'Zoom In'}>
                    <Button onClick={() => zoomIn()}><img src="/Images/map/03.svg" alt="" width="15px" /></Button>
                </Tooltip>
                <Tooltip placement="left" title={'Zoom Out'}>
                    <Button onClick={() => zoomOut()}><img src="/Images/map/04.svg" alt="" width="15px" /></Button>
                </Tooltip>
            </div>
            <div className="legend">
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="LEGEND" key="1">
                        {assetTypeList.listAssetTypesMap.map((asset: any, index: any) => <span className="filter-span">
                            <img src={"/Images/assetIcons/" + asset.asset_type_icon} alt="" width="20px" style={{ margin: '-5px 5px 0px 5px' }} />
                            {asset.asset_type_name}
                        </span>)}
                    </Panel>
                </Collapse>
            </div>
        </div>
    )
}