import { SET_SIDEBAR } from '../types'

const initialState = {
  statesideBar: true
}
export const sharedReducer = (state = initialState, action: any) => {
  //console.log(action, "****")
  switch (action.type) {
    case SET_SIDEBAR:
      return { ...state, statesideBar: action.statesideBar }
    default:
      return state
  }
}
