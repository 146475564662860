import { useDispatch, useSelector } from 'react-redux';

import { loadUsers, searchUsers } from '../store/actions';
import { RootState } from '../store/reducers';

export const useUserState = () => {
  return useSelector((state: RootState) => state.user);
};

export const useUserDispatch = () => {
  const dispatch = useDispatch();
  return {
    listUsers: () => dispatch(loadUsers()),
    searchUsers: (data: any) => dispatch(searchUsers(data))
  };
};
