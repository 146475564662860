import React, { useEffect, useState, useRef } from 'react';
import { message, Layout, Modal, Menu, Row, Col, Input, Dropdown, Button, Table, Checkbox, Tooltip, Drawer, Form, Select, Alert, Upload, Pagination } from 'antd';
import * as datasets from "../../config/datasets";
import { Link } from 'react-router-dom';
import { SideBar } from '../Shared/SideBar'
import { NavBar } from '../Shared/NavBar'
import { DESCEND, ASCEND } from '../../config/constants';
import { AudioOutlined, DownOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { useUserDispatch, useUserState } from '../../hooks/user';
import { useCPartnerDispatch, useCPartnerState } from '../../hooks/channelpartner';
import { useLinkDispath, useLinkState } from '../../hooks/link';
import { SERVER } from '../../config/serverConfig';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Header, Content, Sider } = Layout;
const { Search } = Input;
const { Option } = Select;
const dataSource = [
  {
    check: <Checkbox></Checkbox>,
    uid: '2618',
    name: 'JohnDoe@propel.gps',
    contact: 'John Doe',
    phone: 'Empty',
    page_url: '',
    type: 'Support',
    web: 'www.propelgps.com',
    key: '1',
    actions: <>
      <Tooltip title="Edit"><Button className="btn-edit"><i className="mdi mdi-pencil"></i></Button></Tooltip>
      <Tooltip title="Delete"><Button className="btn-delete"><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
      <Tooltip title="Change Password"><Button className="btn-password"><i className="mdi mdi-lock-question"></i></Button></Tooltip>
    </>,
  },
];



export const ChannelPartner = () => {
  const [visible, setVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [userUpdate, setUserUpdate] = useState({});
  //const [links, setLinks] = useState([]);
  // const [users, setUsers] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState([]);
  // const inputSearch = useRef(null)

  const columns = [
    {
      title: 'Partner Name',
      dataIndex: 'channel_partner_name',
      key: 'channel_partner_name',
      defaultSortOrder: ASCEND,
      filteredValue: filteredInfo,
      sorter: (a: any, b: any) => {
        // console.log('sorter', a, b);
        return a.channel_partner_name.toUpperCase().charCodeAt(0) - b.channel_partner_name.toUpperCase().charCodeAt(0)
      },
      onFilter: (value: any, record: any) => {
        console.log('onFilter', value, record);
        let valorIn = value.toLowerCase()
        //console.log(record.devicetype.toString().toLowerCase().includes(valorIn), valorIn, record.devicetype.toString().toLowerCase())
        return record.channel_partner_name.toString().toLowerCase().includes(valorIn) || record.channel_partner_name.toString().toLowerCase().includes(valorIn)
      },
    },
    {
      title: 'Page URL',
      dataIndex: 'page_url',
      key: 'page_url',
      sorter: (a: any, b: any) => {
        // console.log('sorter', a, b);
        return a.page_url.toUpperCase().charCodeAt(0) - b.page_url.toUpperCase().charCodeAt(0)
      },
    },
    /*   {
        title: 'Primary Contact',
        dataIndex: 'primary_contact',
        key: 'primary_contact',
        sorter: (a: any, b: any) => {
          // console.log('sorter', a, b);
          return a.primary_contact.toUpperCase().charCodeAt(0) - b.primary_contact.toUpperCase().charCodeAt(0)
        },
      }, */
    {
      title: 'User Guide',
      dataIndex: 'user_guide_name',
      key: 'user_guide_name',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
    },
  ];

  const showDrawer = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  const clearAll = () => {
    setFilteredInfo([])
    // setDropTitle([])
    console.log(filteredInfo)
  }
  /* const searchFilter = (element: any) => {
    clearAll()
    console.log(element)
    let textSearch = ''
    if (element.current.state.value !== undefined) {
      textSearch = element.current.state.value
    }
    const arrayFilter: any = []
    arrayFilter.push(textSearch)
    setFilteredInfo(arrayFilter)
  } */

  const fillForm = (values: any) => {
    setVisible(true);
    setIsUpdate(true)
    console.log('fill', values)
    const userFill = Object.assign({}, values)
    userFill.users = values.users.map((tag: any) => tag._id)
    form.setFieldsValue(userFill)
    setUserUpdate(values)
  }
  const onClose = () => {
    setVisible(false);
    clearForm();
  };
  const { listUsers } = useUserDispatch();
  const { listCPartners, searchPartners } = useCPartnerDispatch();
  const { listLink } = useLinkDispath();
  const channelpartners = useCPartnerState()
  const [data, setData] = useState(dataSource)
  const [params, setParams] = useState({});
  const users = useUserState();
  const links = useLinkState();
  const [partnerId, setPartnerId] = useState('');
  const [param, setParam] = useState({});
  const inputSearch = useRef(null);

  const clearForm = () => {
    form.resetFields();
  }
  useEffect(() => {
    searchPartners(params);
    listLink();
    listUsers();
  }, [])

  const refFormUser = useRef(null)
  const [form] = Form.useForm();

  const formPageURL = (e: any) => {
    //console.log(form.getFieldsValue().channel_partner_name)
    const newPageURL = form.getFieldsValue().channel_partner_name.toLowerCase().trim().replaceAll(' ', '')
    console.log(newPageURL)
    form.setFieldsValue({ page_url: newPageURL.replace(/[.*"+\-?^${}()|[\]\\]/g, '') })
  }

  const registerUserDatabase = (values: any) => {
    console.log('Success:', values);
    const dataForm: FormData = new FormData();

    if (!isUpdate) {
      const channelpartner = {
        channel_partner_name: values.channel_partner_name,
        page_url: values.page_url,
        users: values.users,
        alias_email: values.alias_email,
        user_guide_id: values.user_guide_id
      }
      dataForm.append('request', JSON.stringify(channelpartner));
      if (values.logo !== undefined)
        dataForm.append('file', values.logo.file.originFileObj);
      datasets.postDataFiles(SERVER.REGISTER_CHANNEL_PARTNERFILE(), dataForm, datasets.getToken()).then(res => {
        //listCPartners();
        message.success('Partner registered successfully', 4);
        searchPartners(param);
        onClose();
      })
    } else {
      updatePartnerDatabase(values, userUpdate)
    }
  }

  const updatePartnerDatabase = (dataUpdate: any, dataPartner: any) => {
    console.log('Success Update Partner:', dataUpdate, dataPartner);
    dataUpdate._id = dataPartner._id
    datasets.putData(SERVER.UPDATE_PARTNER(), dataUpdate, datasets.getToken()).then(res => {
      // listCPartners();
      message.success('Partner Updated successfully', 4);
      searchPartners(param);
      onClose();
      setIsUpdate(false)
    })
  }

  const deleteCPartnerDatabase = (id: any) => {
    console.log('delete cpartner', id)
    datasets.deleteData(SERVER.DELETE_CPARTNER(id), datasets.getToken()).then(res => {
      //listCPartners();
      console.log('respuesta del', res)
      res.correct ? message.success(res.message, 4) : message.error(res.message, 4);
      searchPartners(param);
      //hideConfirmModal();
    })
  }

  const showConfirmModal = (id: any) => {
    //setPartnerId(id);
    Modal.confirm({
      title: "Remove Partner",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to remove Partner?',
      onOk() {
        deleteCPartnerDatabase(id);
      }
    });
  }

  const searchFilter = (element: any) => {
    console.log(element)
    let textSearch = '';
    if (element.current.state.value !== undefined) {
      textSearch = element.current.state.value
    }
    const data = {
      name: textSearch
    }
    setParam(data);
    searchPartners(data);
  }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const setNewPartner = () => {
    setIsUpdate(false);
    showDrawer();
  }

  const handleChangePage = (pageNumber: any) => {
    searchPartners({ ...param, page: pageNumber });
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <SideBar></SideBar>
      <Layout>
        <NavBar></NavBar>
        <Layout className="site-layout">
          <Content className="user table-general">
            <Row>
              <Col xs={24} lg={24}>
                <div className="card-white">
                  <div className="head-t">
                    <Row>
                      <Col xs={24} lg={16}>
                        <Input
                          allowClear
                          onChange={
                            (e: any) => {
                              if (e.type === 'click' && e.target.value === '') {
                                searchPartners({ ...param, name: '' });
                              }
                            }
                          }
                          onPressEnter={() => searchFilter(inputSearch)} ref={inputSearch} placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 150 }} />
                        {/* <Checkbox>Archive</Checkbox> */}
                        <Button className="btn-blue" onClick={() => searchFilter(inputSearch)}>Search</Button>
                      </Col>
                      <Col xs={24} lg={8} style={{ textAlign: 'right' }}>
                        {/*<Input placeholder="Search..." prefix={<SearchOutlined />} style={{ width: 150 }} />*/}
                        <Button className="btn-green" onClick={setNewPartner}>New Partner</Button>
                        <Drawer
                          className="drawer-form"
                          title={isUpdate ? 'Edit Partner' : 'New Partner'}
                          placement="right"
                          closable={false}
                          onClose={onClose}
                          visible={visible}
                          width={500}
                          footer={
                            <div>
                              <div>
                                *Required Fields
                              </div>
                              <div
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                <Button style={{ marginRight: 8 }} className="btn-border-red" onClick={onClose}>
                                  Cancel
                                </Button>
                                <Button className="btn-green" onClick={() => form.submit()}>
                                  Save
                                </Button>
                              </div>
                            </div>
                          }
                        >
                          <Form form={form} onFinish={registerUserDatabase} layout="vertical" hideRequiredMark>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name='channel_partner_name' label="*Partner Name:">
                                  <Input placeholder="Please enter Partner Name" onChange={formPageURL} />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name='page_url' label="*Page URL:" >
                                  <Input placeholder="Please enter Page URL" type="page_url" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={24}>
                              <Col span={12}>
                                <Form.Item name='user_guide_id' label="User Guide:" >
                                  <Select
                                    placeholder="Select a option"
                                    allowClear
                                  >
                                    {links.listLinks.map((link: any) => <Option value={link._id} key={link._id}>{link.user_guide_name}</Option>)}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name='logo' label="Logo:">
                                  <Upload showUploadList>
                                    <Button className="btn-gray" icon={<UploadOutlined />}>Click to Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item name='users' label="User Associated :">
                                  {/* <Input placeholder="Please select Primary Contact" /> */}
                                  <Select
                                    placeholder="Please select Primary Contact"
                                    mode={'multiple'}
                                    //onChange={(e) => {console.log(e)}}
                                    optionFilterProp='label'
                                  //labelInValue={true}
                                  //searchValue='hola'
                                  >
                                    {users.list.map((user: any) => <Option key={user._id} value={user._id} label={user.full_name}>{user.full_name}</Option>)}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        </Drawer>
                      </Col>
                    </Row>
                  </div>
                  <Table dataSource={channelpartners.listPartners.map((cpartner: any) => {
                    //console.log('CPARTNER',cpartner)
                    return ({
                      check: <Checkbox></Checkbox>,
                      key: cpartner._id,
                      cpid: cpartner.channel_partner_id,
                      channel_partner_name: cpartner.channel_partner_name,
                      page_url: cpartner.page_url,
                      primary_contact: cpartner.primary_contact,
                      user_guide_name: cpartner.user_guide_name,
                      actions: <>
                        <Tooltip title="Edit"><Button className="btn-edit" onClick={() => fillForm(cpartner)}><i className="mdi mdi-pencil"></i></Button></Tooltip>
                        <Tooltip title="Delete"><Button className="btn-delete" onClick={() => showConfirmModal(cpartner._id) /* deleteCPartnerDatabase(cpartner._id) */}><i className="mdi mdi-delete-forever"></i></Button></Tooltip>
                        {/* <Tooltip title="Change Password"><Button className="btn-password"><i className="mdi mdi-lock-question"></i></Button></Tooltip> */}
                      </>,
                    })
                  })} columns={columns} pagination={false} scroll={{ y: 'calc(100vh - 267px)' }} />
                  {

                    (channelpartners !== undefined &&
                      channelpartners.pagination !== undefined &&
                      channelpartners.pagination.total !== undefined &&
                      channelpartners.pagination.total > 0) &&
                    <Pagination
                      style={{
                        float: 'right',
                        marginTop: '10px',
                        marginBottom: '10px',
                        marginRight: '1.8%',
                      }}
                      current={channelpartners.pagination.page}
                      total={channelpartners.pagination.total}
                      pageSize={10}
                      showSizeChanger={false}
                      onChange={handleChangePage}
                    />
                  }
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}