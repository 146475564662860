//const URL_POKEMON = process.env.REACT_APP_API_POKEMON_URI;
const URL_PROPEL = process.env.REACT_APP_PROPEL_URI;
const URL_SOCKET = process.env.REACT_APP_PROPEL_URI_SOCKET;

//console.log('server jose', window.location)
export const SERVER = (function () {

  const URL_BASE = {
    BASE: '/',
    //URL_POKEMON,
    URL_PROPEL
  }
  const API = 'api/v2';
  const USER = 'user'
  const CHANNEL_PARTNER = 'channel-partner'
  const CUSTOMER = 'customer'
  const DEVICE = 'device'
  const ASSET = 'asset'
  const DEVICE_TYPE = 'device-type'
  const ASSET_TYPE = 'asset-type'
  const ASSET_ICON = 'asset-icon'
  const WIREPAS = 'wirepas'
  const FLOOR_PLAN = 'floor-plan'
  const LOGIN = 'auth/login';
  const UPDATE = 'update';
  const SEARCH = 'search';
  const LIST = 'list';
  const DELETE = 'delete';
  const ATTACH = 'attach';
  const ADD_FILE = 'add-file';
  const ADD_WITH_FILE = 'add-with-file'
  const LIST_USERS = 'admin/list';
  const DELETE_USER = 'admin/';
  const REGISTER_USER = 'admin/user/add';
  const UPDATE_USER = 'admin/user/update';
  const UPDATE_USER_PROFILE = 'user/update';
  const REGISTER_CHANNEL_PARTNER = 'channel-partner/add';
  const DELETE_CPARTNER = 'channel-partner/delete'
  const LIST_DEVICES = 'device/list';
  const REGISTER_DEVICE = 'device/add';
  const REGISTER_DEVICE_FILE = 'device/add-file';
  const REGISTER_DEVTYPE = 'device-type/add'
  const REGISTER_ASSETTYPE = 'asset-type/add'
  const LIST_CPARTNERS = 'channel-partner/list';
  const LIST_DEVICETYPES = 'device-type/list';
  const LIST_CUSTOMERS = 'customer/list';
  const REGISTER_CUSTOMER = 'customer/add';
  const DELETE_CUSTOMER = 'customer/delete';
  const REGISTER_CHANNEL_PARTNERFILE = 'channel-partner/add-file';
  const REGISTER_CUSTOMER_FILE = 'customer/add-file';
  const GET_LINKS = 'link/list';
  const VALIDATE_PAGEURL_CUSTOMER = 'customer/validate-pageurl';
  const DELETE_DEVICE = 'device/delete';
  const DELETE_DEVICETYPE = 'device-type/delete';
  const DEVICE_ATTACH = 'device/attach';
  const RECOVERY_PASSWORD = 'user/recovery-password';
  const RESET_PASSWORD = 'user/reset-password'
  const UPDATE_DEVICE = 'device/update';
  const UPDATE_PARTNER = 'channel-partner/update';
  const UPDATE_CUSTOMER = 'customer/update-file';
  const VALIDATE_NAME_DEV = 'device/validatename?model_name=';
  const VALIDATE_EMAIL = 'user/validation-email';
  const ME = 'user/me';
  const SEARCH_CUSTOMERS = 'customer/search';
  const SEARCH_PARTNERS = 'channel-partner/search';
  const ARCHIVE_USER = 'admin/user/archive-user';
  const USERS = 'admin/user';
  const LIST_ASSETTYPES = 'asset-type/list';
  const SEARCH_ASSETTYPES = 'asset-type/search';
  const SEARCH_ASSETS = 'asset/search';
  const LIST_ASSETS = 'asset/list';
  const SAVE_ASSET = 'asset/add';
  const UPDATE_ASSET = 'asset/update';
  const DELETE_ASSET = 'asset/delete';
  const ASSET_BY_ID = 'asset';
  const GET_LOGO_CUSTOMER = 'asset';
  const DATA_MODAL = 'get-data-modal'
  const GET_FLOORPLAN_IMAGE = 'get-floorplan-image/0fcf63a5-392c-add2-a58d-b179f97e330d';
  const GET_FLOORPLAN_IMAGE_PARAM = 'get-floorplan-image';
  const GET_FLOOR_PLAN_IMAGE = 'get-floor-plan-image';
  const GET_FLOORPLANS = 'get-floors-building/66339013-d061-e624-6ae2-369102c8c367';
  const GET_DATA_MAP = 'device/get-data-modal';
  const GET_ASSETS_MAP = 'asset/get-data-modal-params';
  const GET_ASSETS_MAP_ARRAY = 'asset/get-data-array-param';
  const GET_COLLECTION = 'get-data-collections';
  const GET_COLLECTION_v2 = 'get-data-collections-v2';
  const GET_ASSET_TYPE_MAP = 'get-asset-type-map';
  const VALIDATE_FIELD_DEVICE = 'device/validate-field';
  const GET_HISTORY_LOCATION = 'get-history';
  const GET_DEVICE_BY_ASSET = 'get-detail-by_assetid';
  const SEARCH_CAMPUS = 'campus/search';
  const ADD_CAMPUS = 'campus/add';
  const UPDATE_CAMPUS = 'campus/update';
  const UPDATE_CAMPUS_IMAGE = 'campus/update-with-file';
  const DELETE_CAMPUS = 'campus/delete';
  const CAMPUS_BY_ID = 'campus';
  const SEARCH_BUILDING = 'building/search';
  const ADD_BUILDING = 'building/add';
  const UPDATE_BUILDING = 'building/update';
  const ADD_BUILDING_IMAGE = 'building/add-with-file';
  const UPDATE_BUILDING_IMAGE = 'building/update-with-file';
  const DELETE_BUILDING = 'building/delete';
  const GET_BUILDING_BY_ID = 'building';
  const SEARCH_FLOOR_PLAN = 'floor-plan/search';
  const ADD_FLOOR_PLAN = 'floor-plan/add';
  const UPDATE_FLOOR_PLAN = 'floor-plan/update';
  const DELETE_FLOOR_PLAN = 'floor-plan/delete';
  const FLOOR_PLAN_BY_ID = 'floor-plan';
  const VALIDATE_NAME_CAMPUS = 'campus/validate-name';
  const VALIDATE_NAME_BUILDING = 'building/validate-name';
  const VALIDATE_NAME_FLOOR_PLAN = 'floor-plan/validate-name';
  const GET_FLOOR_DATA_FROM_WIREPAS = 'floor-plan/get-data-floor-wirepas';
  const EXPORT_HISTORY_CSV_MAP = 'export-history-csv-map';
  const EXPORT_HISTORY_CSV = 'export-history-csv';
  const EXPORT_HISTORY_PDF = 'export-history-pdf';
  const SEARCH_ROW_MESSAGES = 'search-row-message';
  const LIST_EVENTS = 'events/list';
  const DOWNLOAD_FILE = 'download-file?fileKey=devicerawmessages.csv';
  const GEO_ADDRESS = 'geo-address';
  const GET_ADDRESS_BY_ID = 'get-address-by-id';
  const GET_ADDRESSES_BY_IDS = 'get-addresses-by-ids';
  
  return {
    //GET_POKEMON: (pokemonName: string) => `${URL_BASE.URL_POKEMON}/${API}/${POKEMON}/${pokemonName}`,
    SOCKET: () => `${URL_SOCKET}`,
    POST_LOGIN: () => `${URL_BASE.URL_PROPEL}/${LOGIN}`,
    GET_USERS: () => `${URL_BASE.URL_PROPEL}/${LIST_USERS}`,
    DELETE_USER: (id: string) => `${URL_BASE.URL_PROPEL}/${DELETE_USER}/${id}`,
    REGISTER_USER: () => `${URL_BASE.URL_PROPEL}/${REGISTER_USER}`,
    UPDATE_USER: () => `${URL_BASE.URL_PROPEL}/${UPDATE_USER}`,
    UPDATE_USER_PROFILE: () => `${URL_BASE.URL_PROPEL}/${UPDATE_USER_PROFILE}`,
    GET_CPARTNERS: () => `${URL_BASE.URL_PROPEL}/${LIST_CPARTNERS}`,
    REGISTER_CHANNEL_PARTNERFILE: () => `${URL_BASE.URL_PROPEL}/${REGISTER_CHANNEL_PARTNERFILE}`,
    REGISTER_CHANNEL_PARTNER: () => `${URL_BASE.URL_PROPEL}/${REGISTER_CHANNEL_PARTNER}`,
    GET_DEVICES: () => `${URL_BASE.URL_PROPEL}/${LIST_DEVICES}`,
    REGISTER_DEVICE: () => `${URL_BASE.URL_PROPEL}/${REGISTER_DEVICE}`,
    REGISTER_DEVICE_FILE: () => `${URL_BASE.URL_PROPEL}/${DEVICE}/${ADD_FILE}`,
    GET_DEVICETYPES: () => `${URL_BASE.URL_PROPEL}/${LIST_DEVICETYPES}`,
    SEARCH_ASSETTYPES: () => `${URL_BASE.URL_PROPEL}/${ASSET_TYPE}/${SEARCH}${ASSET_TYPE}`,
    GET_CUSTOMERS: () => `${URL_BASE.URL_PROPEL}/${LIST_CUSTOMERS}`,
    REGISTER_DEVTYPE: () => `${URL_BASE.URL_PROPEL}/${REGISTER_DEVTYPE}`,
    REGISTER_DEVTYPE_FILE: () => `${URL_BASE.URL_PROPEL}/${DEVICE_TYPE}/${ADD_FILE}`,
    REGISTER_ASSETTYPE: () => `${URL_BASE.URL_PROPEL}/${REGISTER_ASSETTYPE}`,
    DELETE_CPARTNER: (id: string) => `${URL_BASE.URL_PROPEL}/${DELETE_CPARTNER}/${id}`,
    REGISTER_CUSTOMER: () => `${URL_BASE.URL_PROPEL}/${REGISTER_CUSTOMER}`,
    DELETE_CUSTOMER: (id: string) => `${URL_BASE.URL_PROPEL}/${DELETE_CUSTOMER}/${id}`,
    REGISTER_CUSTOMER_FILE: () => `${URL_BASE.URL_PROPEL}/${REGISTER_CUSTOMER_FILE}`,
    GET_LINKS: () => `${URL_BASE.URL_PROPEL}/${GET_LINKS}`,
    VALIDATE_PAGEURL_CUSTOMER: () => `${URL_BASE.URL_PROPEL}/${VALIDATE_PAGEURL_CUSTOMER}`,
    DELETE_DEVICE: (id: string) => `${URL_BASE.URL_PROPEL}/${DELETE_DEVICE}/${id}`,
    DELETE_DEVICETYPE: (id: string) => `${URL_BASE.URL_PROPEL}/${DELETE_DEVICETYPE}/${id}`,
    DELETE_ASSETTYPE: (id: string) => `${URL_BASE.URL_PROPEL}/${ASSET_TYPE}/${DELETE}/${id}`,
    UPDATE_DEVICETYPE: () => `${URL_BASE.URL_PROPEL}/${DEVICE_TYPE}/${UPDATE}`,
    DEVICE_ATTACH: () => `${URL_BASE.URL_PROPEL}/${DEVICE_ATTACH}/`,
    RECOVERY_PASSWORD: () => `${URL_BASE.URL_PROPEL}/${RECOVERY_PASSWORD}`,
    RESET_PASSWORD: () => `${URL_BASE.URL_PROPEL}/${RESET_PASSWORD}`,
    UPDATE_DEVICE: () => `${URL_BASE.URL_PROPEL}/${UPDATE_DEVICE}`,
    UPDATE_ASSETTYPE: () => `${URL_BASE.URL_PROPEL}/${ASSET_TYPE}/${UPDATE}`,
    GET_ASSET_TYPES_MAP: () => `${URL_BASE.URL_PROPEL}/${ASSET}/${GET_ASSET_TYPE_MAP}`,
    UPDATE_PARTNER: () => `${URL_BASE.URL_PROPEL}/${UPDATE_PARTNER}`,
    UPDATE_CUSTOMER: () => `${URL_BASE.URL_PROPEL}/${UPDATE_CUSTOMER}`,
    VALIDATE_NAME_DEV: (devName: string) => `${URL_BASE.URL_PROPEL}/${VALIDATE_NAME_DEV}${devName}`,
    VALIDATE_EMAIL: () => `${URL_BASE.URL_PROPEL}/${VALIDATE_EMAIL}`,
    ME: () => `${URL_BASE.URL_PROPEL}/${ME}`,
    SEARCH_CUSTOMERS: () => `${URL_BASE.URL_PROPEL}/${CUSTOMER}/${SEARCH}${CUSTOMER}`,
    SEARCH_PARTNERS: () => `${URL_BASE.URL_PROPEL}/${SEARCH_PARTNERS}`,
    ARCHIVE_USER: () => `${URL_BASE.URL_PROPEL}/${ARCHIVE_USER}`,
    SEARCH_USERS: () => `${URL_BASE.URL_PROPEL}/${USERS}/${SEARCH}`,
    LIST_ASSETS: () => `${URL_BASE.URL_PROPEL}/${LIST_ASSETS}`,
    SEARCH_ASSETS: () => `${URL_BASE.URL_PROPEL}/${ASSET}/${SEARCH}${ASSET}`,
    SEARCH_DEVICES: () => `${URL_BASE.URL_PROPEL}/${DEVICE}/${SEARCH}${DEVICE}`,
    SAVE_ASSET: () => `${URL_BASE.URL_PROPEL}/${SAVE_ASSET}`,
    LIST_ASSETTYPES: () => `${URL_BASE.URL_PROPEL}/${LIST_ASSETTYPES}`,
    UPDATE_ASSET: () => `${URL_BASE.URL_PROPEL}/${UPDATE_ASSET}`,
    DELETE_ASSET: (id: string) => `${URL_BASE.URL_PROPEL}/${DELETE_ASSET}/${id}`,
    ASSET_BY_ID: (id: string) => `${URL_BASE.URL_PROPEL}/${ASSET_BY_ID}/${id}`,
    GET_LOGO_CUSTOMER: (nameLogo: string) => `${URL_BASE.URL_PROPEL}/${GET_LOGO_CUSTOMER}${nameLogo}`,
    GET_FLOORPLAN_IMAGE: () => `${URL_BASE.URL_PROPEL}/${WIREPAS}/${GET_FLOORPLAN_IMAGE}`,
    GET_FLOORPLAN_IMAGE_LOCAL: (id: string) => `${URL_BASE.URL_PROPEL}/${FLOOR_PLAN}/${GET_FLOOR_PLAN_IMAGE}/${id}`,
    GET_FLOORPLAN_IMAGE_PARAM: (image_id: string) => `${URL_BASE.URL_PROPEL}/${WIREPAS}/${GET_FLOORPLAN_IMAGE_PARAM}/${image_id}`,
    GET_FLOORPLANS: () => `${URL_BASE.URL_PROPEL}/${WIREPAS}/${GET_FLOORPLANS}`,
    GET_DATA_MAP: () => `${URL_BASE.URL_PROPEL}/${GET_DATA_MAP}`,
    GET_ASSETS_MAP: () => `${URL_BASE.URL_PROPEL}/${GET_ASSETS_MAP}`,
    GET_ASSETS_MAP_ARRAY: () => `${URL_BASE.URL_PROPEL}/${GET_ASSETS_MAP_ARRAY}`,
    GET_ASSETS_MAP_COLLECTION: () => `${URL_BASE.URL_PROPEL}/${ASSET}/${GET_COLLECTION}`,
    GET_ASSETS_MAP_COLLECTION_v2: () => `${URL_BASE.URL_PROPEL}/${ASSET}/${GET_COLLECTION_v2}`,
    GET_FILE_DEVICE: (name: string) => `${URL_BASE.URL_PROPEL}/${DEVICE}/${ATTACH}/${name}`,
    VALIDATE_FIELD_DEVICE: () => `${URL_BASE.URL_PROPEL}/${VALIDATE_FIELD_DEVICE}`,
    URL_DEVICE_FILE: (name: string) => `${URL_BASE.URL_PROPEL}/${DEVICE}/${ATTACH}/${name}`,
    GET_ASSET_ICONS: () => `${URL_BASE.URL_PROPEL}/${ASSET_ICON}/${LIST}`,
    GET_HISTORY_DEVICE: () => `${URL_BASE.URL_PROPEL}/${DEVICE}/${GET_HISTORY_LOCATION}`,
    GET_HISTORY_ASSET: () => `${URL_BASE.URL_PROPEL}/${ASSET}/${GET_HISTORY_LOCATION}`,
    GET_DEVICE_BY_ASSET: (id: string) => `${URL_BASE.URL_PROPEL}/${ASSET}/${GET_DEVICE_BY_ASSET}/${id}`,
    SEARCH_CAMPUS: () => `${URL_BASE.URL_PROPEL}/${SEARCH_CAMPUS}`,
    ADD_CAMPUS: () => `${URL_BASE.URL_PROPEL}/${ADD_CAMPUS}`,
    UPDATE_CAMPUS: () => `${URL_BASE.URL_PROPEL}/${UPDATE_CAMPUS}`,
    UPDATE_CAMPUS_IMAGE: () => `${URL_BASE.URL_PROPEL}/${UPDATE_CAMPUS_IMAGE}`,
    DELETE_CAMPUS: (id: string) => `${URL_BASE.URL_PROPEL}/${DELETE_CAMPUS}/${id}`,
    CAMPUS_BY_ID: (id: string) => `${URL_BASE.URL_PROPEL}/${CAMPUS_BY_ID}/${id}`,
    VALIDATE_NAME_CAMPUS: () => `${URL_BASE.URL_PROPEL}/${VALIDATE_NAME_CAMPUS}`,
    SEARCH_BUILDING: () => `${URL_BASE.URL_PROPEL}/${SEARCH_BUILDING}`,
    ADD_BUILDING: () => `${URL_BASE.URL_PROPEL}/${ADD_BUILDING}`,
    UPDATE_BUILDING: () => `${URL_BASE.URL_PROPEL}/${UPDATE_BUILDING}`,
    ADD_BUILDING_IMAGE: () => `${URL_BASE.URL_PROPEL}/${ADD_BUILDING_IMAGE}`,
    UPDATE_BUILDING_IMAGE: () => `${URL_BASE.URL_PROPEL}/${UPDATE_BUILDING_IMAGE}`,
    DELETE_BUILDING: (id: string) => `${URL_BASE.URL_PROPEL}/${DELETE_BUILDING}/${id}`,
    GET_BUILDING_BY_ID: (id: string) => `${URL_BASE.URL_PROPEL}/${GET_BUILDING_BY_ID}/${id}`,
    VALIDATE_NAME_BUILDING: () => `${URL_BASE.URL_PROPEL}/${VALIDATE_NAME_BUILDING}`,
    SEARCH_FLOOR_PLAN: () => `${URL_BASE.URL_PROPEL}/${SEARCH_FLOOR_PLAN}`,
    ADD_FLOOR_PLAN_FILE: () => `${URL_BASE.URL_PROPEL}/${FLOOR_PLAN}/${ADD_WITH_FILE}`,
    UPDATE_FLOOR_PLAN: () => `${URL_BASE.URL_PROPEL}/${UPDATE_FLOOR_PLAN}`,
    DELETE_FLOOR_PLAN: (id: string) => `${URL_BASE.URL_PROPEL}/${DELETE_FLOOR_PLAN}/${id}`,
    FLOOR_PLAN_BY_ID: (id: string) => `${URL_BASE.URL_PROPEL}/${FLOOR_PLAN_BY_ID}/${id}`,
    VALIDATE_NAME_FLOOR_PLAN: () => `${URL_BASE.URL_PROPEL}/${VALIDATE_NAME_FLOOR_PLAN}`,
    GET_FLOOR_DATA_FROM_WIREPAS: () => `${URL_BASE.URL_PROPEL}/${GET_FLOOR_DATA_FROM_WIREPAS}`,
    EXPORT_CSV_ASSET: () => `${URL_BASE.URL_PROPEL}/${ASSET}/${EXPORT_HISTORY_CSV}`,
    EXPORT_PDF_ASSET: () => `${URL_BASE.URL_PROPEL}/${ASSET}/${EXPORT_HISTORY_PDF}`,
    EXPORT_CSV_ASSET_MAP: () => `${URL_BASE.URL_PROPEL}/${ASSET}/${EXPORT_HISTORY_CSV_MAP}`,
    EXPORT_CSV_RAW_MESSAGES: () => `${URL_BASE.URL_PROPEL}/${DEVICE}/${EXPORT_HISTORY_CSV}`,
    EXPORT_PDF_RAW_MESSAGES: () => `${URL_BASE.URL_PROPEL}/${DEVICE}/${EXPORT_HISTORY_PDF}`,
    GET_BUILDING_BY_ID_ASSET: (id: string) => `${URL_BASE.URL_PROPEL}/${ASSET}/count-assets-by-buildingid/${id}`,
    GET_COUNT_ASSET_BY_CAMPUS_ID: (id: string) => `${URL_BASE.URL_PROPEL}/${ASSET}/count-assets-by-campusid/${id}`,
    GET_MESSAGES_REPORT_DEVICE: () => `${URL_BASE.URL_PROPEL}/${DEVICE}/${SEARCH_ROW_MESSAGES}`,
    GET_EVENTS: () => `${URL_BASE.URL_PROPEL}/${LIST_EVENTS}`,
    DOWNLOAD_FILE: (name: string) => `${URL_BASE.URL_PROPEL}/${DEVICE}/${DOWNLOAD_FILE}`,
    //GET_COUNT_BUILDING_MENU: () => `${URL_BASE.URL_PROPEL}/${ASSET}/${GET_HISTORY_LOCATION}`,
    GET_ADDRESS_BY_ID: () => `${URL_BASE.URL_PROPEL}/${GEO_ADDRESS}/${GET_ADDRESS_BY_ID}`,
    GET_ADDRESSES_BY_IDS: () => `${URL_BASE.URL_PROPEL}/${GEO_ADDRESS}/${GET_ADDRESSES_BY_IDS}`,
  }
})();