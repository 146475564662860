export const SEARCH_FLOOR_PLAN = 'SEARCH_FLOOR_PLAN';
export const SAVE_FLOOR_PLAN = 'SAVE_FLOOR_PLAN';
export const UPDATE_FLOOR_PLAN = 'UPDATE_FLOOR_PLAN';
export const DELETE_FLOOR_PLAN = 'DELETE_FLOOR_PLAN';
export const GET_DATA_FLOOR_WIREPAS = 'GET_DATA_FLOOR_WIREPAS';
export const FLOOR_PLAN_BY_ID = 'FLOOR_PLAN_BY_ID';

export interface FloorPlanState {
  listFloorPlan: any[],
  objectFloorPlan: any
};
export interface FloorPlanObjectState {
  floorPlan: {}
}

interface ReplaceSearchFloorPlan {
  type: typeof SEARCH_FLOOR_PLAN,
  data: FloorPlanState
}
interface ReplaceFloorPlanId {
  type: typeof FLOOR_PLAN_BY_ID,
  data: FloorPlanObjectState
}
interface ReplaceSaveFloorPlan {
  type: typeof SAVE_FLOOR_PLAN,
  data: FloorPlanObjectState
}
interface ReplaceUpdateFloorPlan {
  type: typeof UPDATE_FLOOR_PLAN,
  data: FloorPlanObjectState
}
interface ReplaceDeleteFloorPlan {
  type: typeof DELETE_FLOOR_PLAN,
  data: FloorPlanObjectState
}

interface ReplaceGetDataWirepas {
  type: typeof GET_DATA_FLOOR_WIREPAS,
  data: FloorPlanObjectState
}

export type FloorPlanType = ReplaceSearchFloorPlan |
        ReplaceSaveFloorPlan |
        ReplaceUpdateFloorPlan |
        ReplaceDeleteFloorPlan |
        ReplaceGetDataWirepas |
        ReplaceFloorPlanId