import {
  BuildingState, SEARCH_BUILDING, SAVE_BUILDING, UPDATE_BUILDING, DELETE_BUILDING,
  BuildingType, SAVE_BUILDING_WITH_FILE, UPDATE_BUILDING_WITH_FILE, BUILDING_BY_ID
} from '../types';

const initialState = {
  listBuilding: [],
  objectBuilding: {}
}

export const buildingReducer = (state: BuildingState = initialState, action: BuildingType) => {
  switch (action.type) {
    case SEARCH_BUILDING:
      return Object.assign({}, state, { listBuilding: action.data });
    case SAVE_BUILDING:
      return Object.assign({}, state, { objectBuilding: action.data });
    case UPDATE_BUILDING:
      return Object.assign({}, state, { objectBuilding: action.data });
    case SAVE_BUILDING_WITH_FILE:
      return Object.assign({}, state, { objectBuilding: action.data });
    case UPDATE_BUILDING_WITH_FILE:
      return Object.assign({}, state, { objectBuilding: action.data });
    case DELETE_BUILDING:
      return Object.assign({}, state);
    case BUILDING_BY_ID:
      return Object.assign({}, state, { objectBuilding: action.data });
    default:
      return state;
  }
}