import { useState, useEffect } from 'react'
import { Redirect, useLocation, Link } from "react-router-dom";
import { Select, Layout, Menu, Breadcrumb, Dropdown, Button } from 'antd';

import { DownOutlined } from '@ant-design/icons';
import { ROUTERS_SIDEBAR } from '../../config/constants';
import * as datasets from '../../config/datasets'
import { useLoginDispatch, useLoginState } from '../../hooks/login';
import { useCustomerState, useCustomerDispatch } from '../../hooks/customer';
import { customerReducer } from '../../store/reducers/customer';

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;
export const NavBar = () => {

  const [redirect, setRedirect] = useState(false);
  const location = useLocation();
  const [dropTitle, setDropTitle] = useState([]);
  //console.log(data.userProfile)
  const { UserLogged, setUserInf } = useLoginDispatch();
  const data = useLoginState();
  const userProfile = data.userProfile
  const { searchCustomers, customerSelected } = useCustomerDispatch();
  const customers = useCustomerState();
  useEffect(() => {
    searchCustomers({})
  }, [])

  const logout = () => {
    datasets.logout();
    setRedirect(true);
    customerSelected({});
    setUserInf({}, '')
  }

  const onSelectCustomer = (id: any) => {
    console.log('On Select Customer', id, localStorage.getItem('customerSelected'))
    const idCustomer = id

    localStorage.setItem('customerSelected', idCustomer)
    let customer: any = {}
    if (userProfile.is_admin) {
      customer = customers.listCustomers.find((customer: any) => customer._id === idCustomer);
    } else {
      customer = userProfile.customers.find((customer: any) => customer._id === idCustomer);
    }
    console.log(customer)
    if (customer) {
      customerSelected(customer)
    } else {
      customerSelected({})
    }
  }

  useEffect(() => {
    if (!userProfile.is_admin) {
      //console.log('userProfile', userProfile, customers.customerSelected, typeof localStorage.getItem('customerSelected'))
      if (!customers.customerSelected.customer_name) {
        // console.log('is empty ')
        /*if (localStorage.getItem('customerSelected')) {
          let custom: any = {}
          custom = userProfile.customers.find((customer: any) => customer._id === localStorage.getItem('customerSelected'));
          console.log('recupero cliente', custom)
          customerSelected(custom)
        } else {
          userProfile.customers[0] ? customerSelected(userProfile.customers[0]): console.log('nothing')
        } */
        if (userProfile.customers.length) {
          if (localStorage.getItem('customerSelected')) {
            let custom: any = {}
            custom = userProfile.customers.find((customer: any) => customer._id === localStorage.getItem('customerSelected'));
            console.log('recupero cliente', custom)
            customerSelected(custom)
          } else {
            customerSelected(userProfile.customers[0])
          }

        } else {
          //console.log('nothing')
        }
      }
      //onSelectCustomer(userProfile.customers[0]._id)

    }
  }, [userProfile])

  if (redirect) {
    return <Redirect to="/login" />
  }

  const menu = (
    <Menu>
      <Menu.Item key='0'>
        <Link to={'/profile'}>Profile</Link>
      </Menu.Item>
      {/* <Menu.Item>
        {userProfile.role + ' User'}
      </Menu.Item> */}
      <Menu.Item key='1' onClick={logout}>
        <Link to={'/login'}>Log Out</Link>
      </Menu.Item>
    </Menu>
  );

  /* const menuCustomers = (
    <Menu>
      {userProfile.customers.map((customer: any) => <Menu.Item key={customer._id} >
        {customer.customer_name}
      </Menu.Item>
      )}
    </Menu>
  ); */

  const indexOf = "" + ROUTERS_SIDEBAR.indexOf(location.pathname);
  //console.log('donde ', location.pathname)
  let title = ''
  switch (indexOf) {
    case '0':
      title = 'Smack and Track'
      break;
    case '1':
      title = 'MAP'
      break;
    case '2':
      title = 'USERS'
      break;
    case '3':
      title = 'Partners'
      break;
    case '4':
      title = 'Customers'
      break;
    case '5':
      title = 'USERS'
      break;
    case '6':
      title = 'PARTNERS'
      break;
    case '7':
      title = 'DEVICE TYPE'
      break;
    case '8':
      title = 'DEVICES'
      break;
    // case '9':
    //   title = 'DEVICE TYPE'
    //   break;
    case '9':
      title = 'CAMPUS'
      break;
    case '10':
      title = 'CAMPUS'
      break;
    case '11':
      title = 'ASSET TYPES'
      break;
    case '12':
      title = 'ASSETS'
      break;
    case '13':
      title = 'REPORTS'
      break;
    case '14':
      title = 'RAW MESSAGES'
      break;
    case '15':
      title = 'CAMPUS'
      break;
    default:
      title = 'SMACK AND TRACK'
      break;
  }

  return <Header className="header">
    <h6>{title.toUpperCase()}</h6>

    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
      {/* {title.toUpperCase() === 'MAP VIEW' ? <Dropdown overlay={menuCustomers} trigger={['click']}>
        <Button className="btn-border-light-gray">
          {dropTitle.length === 0 ? 'Choose a Customer' : dropTitle[0]} <DownOutlined />
        </Button>
      </Dropdown> : <></>} */}
      {/* <Menu.Item> */}
      {(userProfile.is_admin) ? <Select placeholder={customers.customerSelected.customer_name ? customers.customerSelected.customer_name : 'Customer Associated'} allowClear onChange={onSelectCustomer} >
        {customers.listCustomers.map((customer: any) => <Option key={customer._id} value={customer._id} >
          {customer.customer_name}
        </Option>
        )}
        <Option key={'888888'} value={'Clear'} >
          All Customers
        </Option>
      </Select> : customers.customerSelected._id ? <Select style={{ width: 120 }} placeholder={customers.customerSelected.customer_name ? customers.customerSelected.customer_name : 'Customer Associated'} onChange={onSelectCustomer} defaultValue={customers.customerSelected._id} >
        {userProfile.customers.map((customer: any) => <Option key={customer._id} value={customer._id} >
          {customer.customer_name}
        </Option>
        )}
      </Select> : <Select style={{ width: 120 }} placeholder={customers.customerSelected.customer_name ? customers.customerSelected.customer_name : 'Customer Associated'} onChange={onSelectCustomer} >
        {userProfile.customers.map((customer: any) => <Option key={customer._id} value={customer._id} >
          {customer.customer_name}
        </Option>
        )}
      </Select>}
      {/* </Menu.Item> */}
      <Dropdown overlay={menu}>
        <Button className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          <img src="/Images/account.svg" alt="" width="18px" />
          {userProfile.username} <DownOutlined />
        </Button>
      </Dropdown>
    </Menu>
  </Header>
}
