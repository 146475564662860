import { Action } from 'redux'
;
import { ThunkAction } from 'redux-thunk';

import { TestActionTypes, SHOW_ASSETTYPES, SEARCH_ASSETTYPES, AssettypType, GET_ASSET_TYPE_MAP } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from "../../config/datasets";

export const listAssetTypes = (listDevTyp: any) => {
  return {
    type: SHOW_ASSETTYPES,
    data: listDevTyp,
  }
}

export const searchATypes = (listAssetTypes: any) => {
  return {
    type: SEARCH_ASSETTYPES,
    data: listAssetTypes.elements,
    pagination: { 
      total: listAssetTypes.total,
      page: listAssetTypes.page
    } 
  }
}
export const getAssetTypeData = (listAssetTypes: any) => {
  return {
    type: GET_ASSET_TYPE_MAP,
    data: listAssetTypes,
  }
}
export const loadAssetTypes = (): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    console.log('token', token);
    const response = await getRequest(SERVER.LIST_ASSETTYPES(), token);
    console.log('Asset Types', response)
    dispatch(listAssetTypes(response));
  }
}

export const searchAssetTypes = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.SEARCH_ASSETTYPES(), data, token);
    console.log('resp', response)
    dispatch(searchATypes(response));
  }
}
export const getAssetTypesArray = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    // const response = await datasets.postData(SERVER.GET_ASSETS_MAP_ARRAY(), data, token);
    const response = await datasets.postData(SERVER.GET_ASSET_TYPES_MAP(), data, token);
    dispatch(getAssetTypeData(response));
  }
}
