import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ADD_ADDRESS, ADD_ADDRESSES } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from '../../config/datasets';


export const getAddressesByIdsData = (listAsset: any) => {
  return {
    type: ADD_ADDRESSES,
    data: listAsset,
  }
}

export const getAddressByIdData = (AssetData: any) => {
  return {
    type: ADD_ADDRESS,
    data: AssetData,
  }
}
export const getAddressesByIds = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.GET_ADDRESSES_BY_IDS(), data, token);
    //console.log('awefnoapweifjawpoiefjawef', response);
    dispatch(getAddressesByIdsData(response));
  }
}

export const getAddressesById = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.GET_ADDRESS_BY_ID(), data, token);
    dispatch(getAddressByIdData(response));
  }
}

