import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Test } from '../Test';
import { Home } from '../Home';
import { Login } from '../Login/Login';
import { Profile } from '../Profile/ProfileView';
import { MapView } from '../Map/MapView';
import { Flat } from '../Flat/Flat';
import { ChannelPartner } from '../ChannelPartner/ChannelPartnerView';
import { User } from '../User/UserView';
import { Devices } from '../Devices/DevicesView';
import { DeviceTypes } from '../DeviceType/DeviceTypeView';
//import { Assets } from '../Asset/AssetView';
import { AssetTypes } from '../AssetType/AssetTypeView';
import { Customer } from '../Customer/CustomerView';
import { getToken, isAutenticated } from '../../config/datasets'
import { ResetPassword } from '../ResetPassword/ResetPasswordView';
import { ConfirmPassword } from '../ConfirmPassword/ConfirmPasswordView';
import { Assets } from '../Asset/AssetView';
import { Reports } from '../Reports/ReportsView';
import { Campus } from '../Campus/CampusView';
import * as datasets from '../../config/datasets'
import { SERVER } from '../../config/serverConfig';
import { getRequest, postRequest } from '../../config/httpClient';
import { useLoginDispatch, useLoginState } from '../../hooks/login';
import { ReportsMessages } from '../ReportsMessages/ReportsMessagesView';
import { LocationHistoryView } from '../LocationHistory/LocationHistoryView';
//import { cpuUsage } from 'node:process';
//import { Map } from '../Map/Map.js';
// import Main from '../Main/index';




export const Routes = () => {

  const { UserLogged, setUserInf } = useLoginDispatch();
  const data = useLoginState();
  const [loading, setLoading]  = useState(false);
  const userProfile = data.userProfile

  //console.log("using", userProfile);
  

  useEffect(() => {
    //console.log('Routes me First')
    //debugger
    async function fethMe() {
      if (datasets.getToken()) {
        const token: string = datasets.getToken()!;
        //console.log('token', token);
        await datasets.getData(SERVER.ME(), token).then(async res => {
          if (res?._id) {
            setUserInf(res, token);
          }
          //console.log('en rutas', res, datasets.getToken())
          setLoading(false);
        })
      }  
    }
    fethMe()
  }, []);

  // 
  //console.log('tokenni', getToken())  
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/reset_password" component={ResetPassword} />
      <Route path="/confirm_password" component={ConfirmPassword} />
      {/* PROTECTED ROUTE */}
      {/* <Route render={ () => { 
        if(isAutenticated()) {
          console.log('IS LOGGED', isAutenticated())
          return <MapView></MapView>
        } else {
          return <Redirect to="/login" /> 
        }

      }} ></Route> */}
      {(!!userProfile.token || getToken()) && <Route path="/test" component={Test} />}
      {(!!userProfile.token || getToken()) && <Route path="/profile" component={Profile} />}
      {(!!userProfile.token || getToken()) && <Route path="/user" component={User} />}
      {(!!userProfile.token || getToken()) && <Route path="/partners" component={ChannelPartner} />}
      {(!!userProfile.token || getToken()) && <Route path="/customer" component={Customer} />}
      {(!!userProfile.token || getToken()) && <Route path="/devices" component={Devices} />}
      {(!!userProfile.token || getToken()) && <Route path="/devicetypes" component={DeviceTypes} />}
      {(!!userProfile.token || getToken()) && <Route path="/assets" component={Assets} />}
      {(!!userProfile.token || getToken()) && <Route path="/reports" component={Reports} />}
      {(!!userProfile.token || getToken()) && <Route path="/rawmessages" component={ReportsMessages} />}
      {(!!userProfile.token || getToken()) && <Route path="/assettypes" component={AssetTypes} />}
      {(!!userProfile.token || getToken()) && <Route path="/map" component={MapView} />}
      {(!!userProfile.token || getToken()) && <Route path="/flat" component={Flat} />}
      {(!!userProfile.token || getToken()) && <Route path="/asset" component={Assets} />}
      {(!!userProfile.token || getToken()) && <Route path="/campus" component={Campus} />}
      {(!!userProfile.token || getToken()) && <Route path="/locationhistory" component={LocationHistoryView} />}
      <Route path="/" component={Login} />
      {/* <Route path="/" component={Home} /> */}
    </Switch>
  );
}

