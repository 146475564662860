import { useDispatch, useSelector } from 'react-redux';
import { searchCampus, saveCampus, updateCampus, deleteCampus, validateName, updateCampusWithFile } from '../store/actions';
import { RootState } from '../store/reducers';

export const useCampusState = () => {
  return useSelector((state: RootState) => state.campus);
}
export const useCampusDispatch = () => {
  const dispatch = useDispatch();
  return {
    searchCampus: (data: any) => dispatch(searchCampus(data)),
    saveCampus: (data: any) => dispatch(saveCampus(data)),
    updateCampus: (data: any) => dispatch(updateCampus(data)),
    updateCampusWithFile: (data: any) => dispatch(updateCampusWithFile(data)),
    deleteCampus: (id: string) => dispatch(deleteCampus(id)),
    validateName: (data: any) => dispatch(validateName(data))
  };
};