export const SEARCH_BUILDING = 'SEARCH_BUILDING';
export const SAVE_BUILDING = 'SAVE_BUILDING';
export const UPDATE_BUILDING = 'UPDATE_BUILDING';
export const SAVE_BUILDING_WITH_FILE = 'SAVE_BUILDING_WITH_FILE';
export const UPDATE_BUILDING_WITH_FILE = 'UPDATE_BUILDING_WITH_FILE';
export const DELETE_BUILDING = 'DELETE_BUILDING';
export const BUILDING_BY_ID = 'BUILDING_BY_ID';

export interface BuildingState {
  listBuilding: any[],
  objectBuilding: any
}
export interface BuildingObjectState {
  building: {}
}
interface ReplaceSearchBuilding {
  type: typeof SEARCH_BUILDING,
  data: BuildingState
}
interface ReplaceBuildingById {
  type: typeof BUILDING_BY_ID,
  data: BuildingObjectState
}
interface ReplaceSaveBuilding {
  type: typeof SAVE_BUILDING,
  data: BuildingObjectState
}
interface ReplaceUpdatedBuilding {
  type: typeof UPDATE_BUILDING,
  data: BuildingObjectState
}
interface ReplaceSaveWithFileBuilding {
  type: typeof SAVE_BUILDING_WITH_FILE,
  data: BuildingObjectState
}
interface ReplaceUpdateWithFileBuilding {
  type: typeof UPDATE_BUILDING_WITH_FILE,
  data: BuildingObjectState
}
interface ReplaceDeleteBuilding {
  type: typeof DELETE_BUILDING
}

export type BuildingType = ReplaceSearchBuilding | 
        ReplaceSaveBuilding | 
        ReplaceUpdatedBuilding | 
        ReplaceDeleteBuilding | 
        ReplaceSaveWithFileBuilding |
        ReplaceUpdateWithFileBuilding |
        ReplaceBuildingById