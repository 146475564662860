import { Action } from 'redux'
  ;
import { ThunkAction } from 'redux-thunk';

import { TestActionTypes, SHOW_CHANNELPARTNERS, ChannelPartnerType, SEARCH_PARTNERS } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from "../../config/datasets";

export const listCPartners = (listCP: any) => {
  return {
    type: SHOW_CHANNELPARTNERS,
    data: listCP,
  }
}

export const searchPartner = (listPartners: any) => {
  return {
    type: SEARCH_PARTNERS,
    data: listPartners.elements,
    pagination: { 
      total: listPartners.total,
      page: listPartners.page
    } 
  }
}

export const loadCPartners = (): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token: string = datasets.getToken()!;
    const response = await getRequest(SERVER.GET_CPARTNERS(), token);
    dispatch(listCPartners(response));
  }
}

export const searchPartners = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token: string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.SEARCH_PARTNERS(), data, token);
    console.log('server', response)
    dispatch(searchPartner(response));
  }
}