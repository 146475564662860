import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SEARCH_BUILDING, BuildingType, SAVE_BUILDING, UPDATE_BUILDING, DELETE_BUILDING,
SAVE_BUILDING_WITH_FILE, UPDATE_BUILDING_WITH_FILE, BUILDING_BY_ID } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from '../../config/datasets';

export const searchObjBuilding = (listBuilding: any) => {
  return {
    type: SEARCH_BUILDING,
    data: listBuilding
  }
}
export const saveObjectBuilding = (building: any) => {
  return {
    type: SAVE_BUILDING,
    data: building
  }
}
export const updateObjectBuilding = (building: any) => {
  return {
    type: UPDATE_BUILDING,
    data: building
  }
}
export const saveFileObjectBuilding = (building: any) => {
  return {
    type: SAVE_BUILDING_WITH_FILE,
    data: building
  }
}
export const updateFileObjectBuilding = (building: any) => {
  return {
    type: UPDATE_BUILDING_WITH_FILE,
    data: building
  }
}
export const getById = (building: any) => {
  return {
    type: BUILDING_BY_ID,
    data: building
  }
}
export const deleteObjectBuilding = () => {
  return {
    type: DELETE_BUILDING
  }
}
export const searchBuilding = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.SEARCH_BUILDING(), data, token);
    dispatch(searchObjBuilding(response));
  }
}
export const saveBuilding = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.ADD_BUILDING(), data, token);
    dispatch(saveObjectBuilding(response));
  }
}
export const updateBuilding = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.putData(SERVER.UPDATE_BUILDING(), data, token);
    dispatch(updateObjectBuilding(response));
  }
}
export const saveBuildingWithFile = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postDataFiles(SERVER.ADD_BUILDING_IMAGE(), data, token);
    console.log('was a gae',response)
    dispatch(saveObjectBuilding(response));
  }
}
export const updateBuildingWithFile = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.putDataFiles(SERVER.UPDATE_BUILDING_IMAGE(), data, token);
    dispatch(updateObjectBuilding(response));
  }
}
export const getBuildingById = (id: string) : ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.getData(SERVER.GET_BUILDING_BY_ID(id), token);
    dispatch(getById(response));
  }
}
export const deleteBuilding = (id: string): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.deleteData(SERVER.DELETE_BUILDING(id), token);
    dispatch(deleteObjectBuilding());
  }
}