import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

import { testReducer} from './test';
import { loginReducer } from './login';
import { userReducer } from './user';
import { cPartnerReducer } from './channelpartner';
import { deviceReducer } from './device';
import { devicetypReducer } from './deviceType';
import { customerReducer } from './customer';
import { linkReducer } from './link';
import { assettypeReducer } from './assetType';
import { campusReducer } from './campus';
import { buildingReducer } from './building';
import { floorPlanReducer } from './floorPlan';
import { sharedReducer } from './shared';
import { addressReducer } from './address';

import { assetReducer } from './asset';
import { rawMessageReducer } from './rawMessage';

export const history = createBrowserHistory();

export const rootReducer = combineReducers({
  router: connectRouter(history),
  test: testReducer,
  login: loginReducer,
  user: userReducer,
  channelpartner: cPartnerReducer,
  device: deviceReducer,
  deviceTypes: devicetypReducer,
  customer: customerReducer,
  link: linkReducer,
  assetTypes: assettypeReducer,
  asset: assetReducer,
  campus: campusReducer,
  building: buildingReducer,
  floorPlan: floorPlanReducer,
  shared: sharedReducer,
  rawMessage: rawMessageReducer,
  address: addressReducer,
})

export type RootState = ReturnType<typeof rootReducer>;
