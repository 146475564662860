import { useDispatch, useSelector } from 'react-redux';
import { searchFloorPlan, saveFloorPlan, updateFloorPlan, deleteFloorPlan, getDataFloorWirepas,getFloorPlanById } from '../store/actions';
import { RootState } from '../store/reducers';

export const useFloorPlanState = () => {
  return useSelector((state: RootState) => state.floorPlan);
}

export const useFloorPlanDispatch = () => {
  const dispatch = useDispatch();
  return {
    searchFloorPlan: (data: any) => dispatch(searchFloorPlan(data)),
    saveFloorPlan: (data: any) => dispatch(saveFloorPlan(data)),
    updateFloorPlan: (data: any) => dispatch(updateFloorPlan(data)),
    deleteFloorPlan: (id: string) => dispatch(deleteFloorPlan(id)),
    getDataFloorWirepas: (data: any) => dispatch(getDataFloorWirepas(data)),
    getFloorPlanById: (id: string) => dispatch(getFloorPlanById(id))
  };
};