export const SEARCH_ASSETS = 'SEARCH_ASSETS';
export const SHOW_ASSETS = 'SHOW_ASSETS';
export const SAVE_ASSET = 'SAVE_ASSET';
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const DELETE_ASSET = 'DELETE_ASSET';
export const LIST_ICONS = 'LIST_ICONS';
export const GET_ASSET_MAP = 'GET_ASSET_MAP';
export const UPDATE_ASSET_FROM_LIST = 'UPDATE_ASSET_FROM_LIST';

export interface AssetState {
  listAssets: any[],
  listIcons: any[],
  listAssetMap: {
    type: String,
    features: any[]
  },
  pagination: {
    total: any,
    page: any
  }
};

export interface IconsState {
  listIcons: any[]
};
export interface AssetObjectState {
  asset: {}
}

interface ReplaceSearchAssets {
  type: typeof SEARCH_ASSETS,
  data: AssetState,
  pagination: {
    total: any,
    page: any
  }
}

interface ReplaceAssetsMap {
  type: typeof GET_ASSET_MAP,
  data: AssetState
}
interface ReplaceSearchIcons {
  type: typeof LIST_ICONS,
  data: AssetState,
  
}
interface ReplaceAssetAction {
  type: typeof SHOW_ASSETS,
  data: AssetState
}

interface ReplaceSaveAsset {
  type: typeof SAVE_ASSET,
  data: AssetObjectState
}

interface ReplaceUpdateAsset {
  type: typeof UPDATE_ASSET,
  data: AssetObjectState
}

interface ReplaceDeleteAsset {
  type: typeof DELETE_ASSET
}

interface UpdateAssetFromList {
  type: typeof UPDATE_ASSET_FROM_LIST,
  data: any
}

export type AssetType = ReplaceSearchAssets |
      ReplaceAssetAction | 
      ReplaceSaveAsset |
      ReplaceUpdateAsset |
      ReplaceDeleteAsset | 
      ReplaceSearchIcons | 
      ReplaceAssetsMap |
      UpdateAssetFromList;
