import { Collapse } from "antd"
import { useState } from "react";
import { useAssetState } from "../../../hooks/asset";
import { AssetsPanel } from "./AssetsPanel"
import { SearchAssetPanel } from "./SearchAssetPanel"
const { Panel } = Collapse;

export const ListAssets = ({ retrieveData, showLocationAsset, setCenterMap, onShowHistory }: any) => {
    const [filters, setFilters] = useState([]);
    const assets = useAssetState();
    return (
        <Collapse defaultActiveKey={['1', '2']} expandIconPosition="right">
            <Panel header="FILTERS" key="1">
                <SearchAssetPanel
                    retrieveData={retrieveData}
                    setFilters={setFilters}
                    key="1">
                </SearchAssetPanel>
            </Panel>
            <Panel header={"ASSETS (" + assets.listAssetMap.features.length + ")"} key="2">
                <AssetsPanel
                    showLocationAsset={showLocationAsset}
                    setCenterMap={setCenterMap}
                    filters={filters}
                    onShowHistory={onShowHistory}
                ></AssetsPanel>
            </Panel>
        </Collapse>
    );
};