export const SHOW_CUSTOMERS = 'SHOW_CUSTOMERS';
export const SEARCH_CUSTOMERS = 'SEARCH_CUSTOMERS';
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';


export interface CustomerState {
  listCustomers: any[],
  customerSelected: any,
  pagination: any
}

export interface CustomerSelectState {
  customer: {}
}
interface ReplaceCustomerAction {
  type: typeof SHOW_CUSTOMERS,
  data: CustomerState,
}

interface ReplaceSearchCustomers {
  type: typeof SEARCH_CUSTOMERS,
  data: CustomerState,
  pagination: {
    total: any,
    page: any
  }
}

interface ReplaceSelectCustomer {
  type: typeof SELECT_CUSTOMER,
  data: CustomerState
}

export type CustomerType = ReplaceCustomerAction | 
  ReplaceSearchCustomers | ReplaceSelectCustomer