import { Action } from 'redux'
  ;
import { ThunkAction } from 'redux-thunk';

import { TestActionTypes, SHOW_DEVICES, DeviceType, SEARCH_DEVICES, GET_DATA_MAP, SET_LIST_DEVICES } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from "../../config/datasets";

export const listDevices = (listDev: any) => {
  return {
    type: SHOW_DEVICES,
    data: listDev,
  }
}

export const searchDevice = (listDevice: any) => {
  return {
    type: SEARCH_DEVICES,
    data: listDevice.elements,
    pagination: {
      total: listDevice.total,
      page: listDevice.page
    }
  }
}
export const getDataMap = (listDev: any) => {
  return {
    type: GET_DATA_MAP,
    data: listDev,
  }
}

export const loadDevices = (): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token: string = datasets.getToken()!;
    console.log('token', token);
    const response = await getRequest(SERVER.GET_DEVICES(), token);
    console.log('devices', response)
    dispatch(listDevices(response));
  }
}

export const getDataMaps = (): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token: string = datasets.getToken()!;
    const response = await getRequest(SERVER.GET_DATA_MAP(), token);
    dispatch(getDataMap(response));
  }
}

export const searchDevices = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token: string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.SEARCH_DEVICES(), data, token);
    dispatch(searchDevice(response));
  }
}

export const setListDevices = (listDev: any) => {
  return {
    type: SET_LIST_DEVICES,
    data: listDev,
  }
}