import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SEARCH_RAW_MESSAGE, SHOW_ASSETS, AssetType, SAVE_ASSET, UPDATE_ASSET,
        DELETE_ASSET, LIST_ICONS, GET_ASSET_MAP } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from '../../config/datasets';

export const searchRawMessageList = (listRawMessage: any) => {
  return {
    type: SEARCH_RAW_MESSAGE,
    data: listRawMessage.elements,
    pagination: { 
      total: listRawMessage.total,
      page: listRawMessage.page
    } 
  }
}


export const searchRawMessage = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;

    const response = await datasets.postData(SERVER.GET_MESSAGES_REPORT_DEVICE(), data, token).then((res: any) => {
      console.log('Raw Messages QWE', res)
      return res;
    });
    dispatch(searchRawMessageList(response));
  }
}


/* export const getBuildingById = (id: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.deleteData(SERVER.GET_BUILDING_BY_ID(id), token);
    dispatch(buildingById(response));
  }
} */