import { useDispatch, useSelector } from 'react-redux';

import { getDataMaps, loadDevices, searchDevices, setListDevices } from '../store/actions';
import { RootState } from '../store/reducers';

export const useDeviceState = () => {
  return useSelector((state: RootState) => state.device);
  
};

export const useDeviceDispatch = () => {
  const dispatch = useDispatch();
  return {
    listDevices: () => dispatch(loadDevices()),
    searchDevices: (data: any) => dispatch(searchDevices(data)),
    setListDevices: (data: any) => dispatch(setListDevices(data)),
    getDataMap: () => dispatch(getDataMaps()),
    //messagesDevices: () => dispatch(messagesDevices()) 
    
  };
};