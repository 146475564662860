import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { TestActionTypes, SHOW_CUSTOMERS, CustomerType, SEARCH_CUSTOMERS, SELECT_CUSTOMER } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from "../../config/datasets";



export const listCustomers = (listCustomer: any) => {
  return {
    type: SHOW_CUSTOMERS,
    data: listCustomer,
  }
}

export const searchCustomer = (listCustomer: any) => {
  return {
    type: SEARCH_CUSTOMERS,
    data: listCustomer.elements,
    pagination: { 
      total: listCustomer.total,
      page: listCustomer.page
    } 
  }
}

export const loadCustomers = (): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    console.log('token', token);
    const response = await getRequest(SERVER.GET_CUSTOMERS(), token);
    console.log('resp', response)
    dispatch(listCustomers(response));
  }
}

export const searchCustomers = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.SEARCH_CUSTOMERS(), data, token);
    //console.log('resp fetch Customer', response);
    if (response.error) {
      // console.log('')
      datasets.logout()
    } else {
      dispatch(searchCustomer(response));
    }
    
  }
}

export const customerSelected = (customer: any) => {
  
  console.log('customer hook', customer)
  return {
    type: SELECT_CUSTOMER,
    data: customer
  } 
}