export const ADD_ADDRESSES = 'ADD_ADDRESSES';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';

export interface AddressState {
    listAddress: any[]
};

export interface AddressObjectState {
    address: {}
}

interface AddAddresses {
    type: typeof ADD_ADDRESSES,
    data: any,
}

interface AddAddress {
    type: typeof ADD_ADDRESS,
    data: any
}

interface UpdateAddress {
    type: typeof UPDATE_ADDRESS,
    data: any
}

export type AddressType =
    AddAddresses |
    AddAddress |
    UpdateAddress;
