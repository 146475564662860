import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { message, Button, Form, Input, Checkbox, Row } from "antd";
import { useLoginDispatch, useLoginState } from '../../hooks/login';
import { getRequest, postRequest } from '../../config/httpClient';
import { Redirect } from 'react-router';
import { SERVER } from '../../config/serverConfig';
import * as datasets from "../../config/datasets";
// import jmap from 'jmap.js';

import 'antd/dist/antd.css';

export const ConfirmPassword = () => {

    const [user, setUser] = useState(null)
    const [message1, setMessage] = useState({ message1: '', color: '#28C499' });
    const [redirect, setRedirect] = useState(false);
    const { UserLogged } = useLoginDispatch();
    const data = useLoginState();


    useEffect(() => {
        localStorage.clear()
        setRedirect(false)
        setUser(null)
    }, [])

    const location = useLocation().pathname;

    const onFinish = (values: any) => {

        
        const url = location.split('/');
        console.log('onfin', values, url)
        const userPass = {
            id: url[2],
            password: values.password,
            passwordConfirm: values.confirm
        }
        /* const auxMessage = { ...message1 };
        auxMessage.message1 = 'The request was sent to reset your email password, this may take a few minutes';
        auxMessage.color = '#28C499';
        setMessage(auxMessage); */
        postRequest(SERVER.RESET_PASSWORD(), userPass).then(async res => {
            if (!res.error) {
                message.success('Password changed successfully', 4);
                setRedirect(true);
            } else {
                message.error('Password has not changed', 4);
                /* const auxMessage = { ...message1 };
                auxMessage.message1 = res.error;
                auxMessage.color = 'red';
                setMessage(auxMessage); */
            }
        })

        console.log('Success:', values);

        // showUsers(userRequired);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    if (redirect) {
        return <Redirect to="/map" />
    }

    return (
        <div className="login">
            <div className="login-form">
                <img src="/Images/Logo.svg" alt="" width="180px" />
                <h1>Reset your password</h1>
                <Row className="resetText">
                    <p>Enter a new password to reset the password for your account</p>
                </Row>
                <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                    <Form.Item className="form-label"><label className="title-input">Password</label></Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item className="form-label"><label className="title-input">Confirm Password</label></Form.Item>
                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <Button className="btn-green" htmlType="submit">SET NEW PASSWORD</Button>
                        <br />
                    </Form.Item>
                </Form>

            </div>
        </div>
    );
}
