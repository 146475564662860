export const SHOW_DEVICES = 'SHOW_DEVICES';
export const GET_DATA_MAP = 'GET_DATA_MAP';
export const SEARCH_DEVICES = 'SEARCH_DEVICES';
export const SET_LIST_DEVICES = 'SET_LIST_DEVICES';

export interface DeviceState {
  listDevices: any[],
  listDeviceMap: any[]
  pagination: any
}

interface ReplaceDeviceAction {
  type: typeof SHOW_DEVICES,
  data: DeviceState,
}
interface ReplaceSearchDevices {
  type: typeof SEARCH_DEVICES,
  data: DeviceState,
  pagination: {
    total: any,
    page: any
  }
}
interface ReplaceSetListDevices {
  type: typeof SET_LIST_DEVICES,
  data: DeviceState
}

interface ReplaceDataMap {
  type: typeof GET_DATA_MAP,
  data: DeviceState
}

export type DeviceType = ReplaceDeviceAction |
        ReplaceDataMap | ReplaceSearchDevices | ReplaceSetListDevices