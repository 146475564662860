import { useDispatch, useSelector } from 'react-redux';

import { setSideBar } from '../store/actions';
import { RootState } from '../store/reducers';

export const useShared = () => {
  return useSelector((state: RootState) => state.shared);
};


export const useSharedDispatch = () => {
  const dispatch = useDispatch();
  return {
    setSideBar: (data: any) => dispatch(setSideBar(data)),
  };
};
