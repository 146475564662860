import React, {useEffect} from 'react';

import { Routes } from './components/Routes';
import * as datasets from './config/datasets'
import { useLoginDispatch, useLoginState } from './hooks/login';
import { SERVER } from './config/serverConfig';
import './index.scss';


function App() {
  const { UserLogged, setUserInf } = useLoginDispatch();

  // useEffect(() => {
    
  //   console.log('cual se eejecuta primero')
  //   async function fethMe() {
  //     if (datasets.getToken()) {
  //       const token: string = datasets.getToken()!;
  //       console.log('token', token);
  //       debugger
  //       await datasets.getData(SERVER.ME(), token).then(async res => {
  //         if (res?._id) {
  //           setUserInf(res, token);
  //         }
  //         console.log('en rutas', res, datasets.getToken())
  //         //setLoading(false);
  //       })
  //     }  
  //   }
  //   fethMe()
  // }, [])
  return <Routes />
}

export default App;
