import {
    RawMessageState, RawMessageType, SEARCH_RAW_MESSAGE 
 } from '../types';
 
 const initialState = {
    listRawMessage: [],
    listIcons: [],
    listAssetMap: { type: '', features: []},
    pagination: { total: 0, page: 0 }
 }
 export const rawMessageReducer = (state: RawMessageState = initialState, action: RawMessageType) => {
    switch (action.type) {
       case SEARCH_RAW_MESSAGE:
          return Object.assign({}, state, { listRawMessage: action.data, pagination: action.pagination  });
       default:
          return state;
    }
 }