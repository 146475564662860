import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { TestActionTypes, LIST_LINKS, LinkType } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from '../../config/datasets';

export const listLinks = (listLink: any) => {
   return {
      type: LIST_LINKS,
      data: listLink
   }
}

export const loadLinks = (): ThunkAction<void, RootState, unknown, Action<string>> => {
   return async (dispatch) => {
      const token: string = datasets.getToken()!;
      console.log('token', token);
      const response = await getRequest(SERVER.GET_LINKS(), token);
      console.log('resp', response);
      dispatch(listLinks(response));
   }
}