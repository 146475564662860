import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SEARCH_CAMPUS, CampusType, SAVE_CAMPUS, UPDATE_CAMPUS, DELETE_CAMPUS, VALIDATE_NAME } from '../types';
import { getRequest } from '../../config/httpClient';
import { SERVER } from '../../config/serverConfig';
import { RootState } from '../reducers';
import * as datasets from '../../config/datasets';

export const validateNameAsset = (params: any) => {
  return {
    type: VALIDATE_NAME,
    data: params
  }
}

export const searchCampusAct = (listCampus: any) => {
  return {
    type: SEARCH_CAMPUS,
    data: listCampus
  }
}
export const saveObjectCampus = (campus: any) => {
  return {
    type: SAVE_CAMPUS,
    data: campus
  }
}
export const updateObjectCampus = (campus: any) => {
  return {
    type: UPDATE_CAMPUS,
    data: campus
  }
}
export const deleteObjectCampus = (campus: any) => {
  return {
    type: DELETE_CAMPUS,
    data: campus
  }
}

export const searchCampus = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.SEARCH_CAMPUS(), data, token);
    dispatch(searchCampusAct(response));
  }
}
export const saveCampus = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.ADD_CAMPUS(), data, token);
    dispatch(saveObjectCampus(response));
  }
}
export const validateName = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token: string = datasets.getToken()!;
    const response = await datasets.postData(SERVER.VALIDATE_NAME_CAMPUS(), data, token);
  }
}
export const updateCampus = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.putData(SERVER.UPDATE_CAMPUS(), data, token);
    dispatch(updateObjectCampus(response));
  }
}
export const deleteCampus = (id: any) : ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.deleteData(SERVER.DELETE_CAMPUS(id), token);
    dispatch(deleteObjectCampus(response));
  }
}
export const updateCampusWithFile = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch) => {
    const token:string = datasets.getToken()!;
    const response = await datasets.putDataFiles(SERVER.UPDATE_CAMPUS_IMAGE(), data, token);
    dispatch(updateObjectCampus(response));
  }
}