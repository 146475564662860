import { AddressState, AddressType, ADD_ADDRESS, ADD_ADDRESSES } from '../types';

const initialState = {
    listAddress: [],
}
/*
    listAddress: [
        {asset_id: xxxxx, device_esn: xxxxx, device_imei: xxxxxxx, geoAddress: xxxxx xxxxxxx x xxxxx},
    ]
*/

export const addressReducer = (state: AddressState = initialState, action: AddressType) => {
    switch (action.type) {
        case ADD_ADDRESSES:
            // action.data = [{...}, {...}, ...] same objects as listAddress
            // for each new address find if exists in the list
            console.log('elrtmaieopfraiwoef',action.data);
            action.data.forEach((address: any) => {
                const index = state.listAddress.findIndex((item: any) =>
                (item.asset_id === address.asset_id &&
                    ((item.device_esn && item.device_esn === address.device_esn) || (item.device_imei && item.device_imei === address.device_imei))));

                if (index === -1) {
                    state.listAddress.push(address);
                } else {
                    state.listAddress[index] = address;
                }
            })
            return Object.assign({}, state);
        case ADD_ADDRESS:
            const address = action.data;
            const index = state.listAddress.findIndex((item: any) =>
                (item.asset_id === address.asset_id &&
                    ((item.device_esn && item.device_esn === address.device_esn) || (item.device_imei && item.device_imei === address.device_imei))));
            
            if (index === -1) {
                state.listAddress.push(address);
            } else {
                state.listAddress[index] = address;
            }
            return Object.assign({}, state);
        default:
            return state;
    }
}