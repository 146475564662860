import { useDispatch, useSelector } from 'react-redux';
import { loadLinks } from '../store/actions';
import { RootState } from '../store/reducers';

export const useLinkState = () => {
  return useSelector((state: RootState) => state.link);
};

export const useLinkDispath = () => {
  const dispatch = useDispatch();
  return {
    listLink: () => dispatch(loadLinks())
  }
}