import { useDispatch, useSelector } from 'react-redux';
import { getAddressesByIds, getAddressesById} from '../store/actions';
import { RootState } from '../store/reducers';

export const useAddressState = () => {
  return useSelector((state: RootState) => state.address);
};

export const useAddressDispatch = () => {
  const dispatch = useDispatch();
  return {
    //send array of asset ids
    loadAddressesByIds: (data: any) => dispatch(getAddressesByIds(data)),
    //send device_id and asset_id
    updateAddressById: (data: any) => dispatch(getAddressesById(data)),
  };
};
