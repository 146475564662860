import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Checkbox, Row } from "antd";
import { useLoginDispatch, useLoginState } from '../../hooks/login';
import { getRequest, postRequest } from '../../config/httpClient';
import { Redirect } from 'react-router';
import { SERVER } from '../../config/serverConfig';
import * as datasets from "../../config/datasets";
// import jmap from 'jmap.js';

import 'antd/dist/antd.css';

export const ResetPassword = () => {

  const [user, setUser] = useState(null)
  const [message, setMessage] = useState({ message: '', color: '#28C499' });
  const [redirect, setRedirect] = useState(false);
  const { UserLogged } = useLoginDispatch();
  const data = useLoginState();


  useEffect(() => {
    localStorage.clear()
    setRedirect(false)
    setUser(null)
  }, [])



  const onFinish = (values: any) => {
    console.log('onfin', values)
    const auxMessage = { ...message };
    auxMessage.message = 'The request was sent to reset your email password, this may take a few minutes';
    auxMessage.color = '#28C499';
    setMessage(auxMessage);
    postRequest(SERVER.RECOVERY_PASSWORD(), values).then(async res => {
      if (!res.error) {
        // setRedirect(true);
      } else {
        const auxMessage = { ...message };
        auxMessage.message = res.error;
        auxMessage.color = 'red';
        setMessage(auxMessage);
      }
    })

    console.log('Success:', values);

    // showUsers(userRequired);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (redirect) {
    return <Redirect to="/map" />
  }

  return (
    <div className="login">
      <div className="login-form">
        <img src="/Images/Logo.svg" alt="" width="180px" />
        <h1>
          Reset your password
        </h1>
        <Row className="resetText">
          <p>Enter your email address below and we’ll send you a link to reset your password.</p>
        </Row>
        <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item className="form-label"><label className="title-input">Email Address</label></Form.Item>
          <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
            <Input className="input-height" placeholder="johnwhesley@mail.com" />
          </Form.Item>
          <div style={{ color: message.color }}>
            {message.message}
          </div>

          <Form.Item>
            <Button className="btn-green" htmlType="submit">
              RESET PASSWORD
            </Button>
            <br />
            {/*<Link to="/test" className="test-button">
              <Button className="btn-border-gray">
                <img src="/Images/google.svg" alt="" height="15px" />
                GOOGLE
              </Button>
            </Link>*/}
          </Form.Item>

        </Form>
        {/*<div>
        {data? 'Login Successful' : 'User Not Found'}
        </div>*/}

      </div>
    </div>
  );
}
